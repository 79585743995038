import React from "react";
import {Button, DatePicker, Divider, Empty, Form, Input, Modal, Pagination, Radio, Select, Space, Table, Tabs, Row, Col,message} from "antd";
import Request from "../../../utils/Request";
import { Link } from "react-router-dom";
import { AuditModal } from "./AuditModal";
import axios from "axios";
import "./index.css";

const { RangePicker } = DatePicker;
const formRef: any = React.createRef();
const userTypeArr: any = [
  {
    label: "顾客提现",
    value: 1,
  },
  {
    label: "异业商家提现",
    value: 2,
  },
];
const userTypeAll = [
  {
    label: "达人提现",
    value: 3,
  },
  {
    label: "商家提现",
    value: 4,
  },
  {
    label: "推广员提现",
    value: 5,
  },
  {
    label: "运营商提现",
    value: 6,
  },
];

export default class WithdrawApproval extends React.Component<{ match?: any; }, {
    data: any;
    total: number;
    selectedRowKeys: any;
    searchForm: any;
    visible: boolean;
    visible1: boolean;
    visible2: boolean;
    checkedForm: any;
    isSame: boolean;
    // userType:any
  }
  > {
    pageSize = 15;
    statusArr = [
      {
        label: "所有",
        value: "",
      },
      {
        label: "待审核",
        value: "1",
      },
      {
        label: "已审核待打款",
        value: "2",
      },
      {
        label: "已驳回",
        value: "3",
      },
      {
        label: "已完成",
        value: "4",
      },
      {
        label: "打款失败",
        value: "5",
      },
    ];

  activeTabKey = "1";

  constructor(props) {
    super(props);
    let obj = {};
    if (props.location.search) {
      let str = props.location.search;
      str = str.substr(1, str.length);
      str = str.split("&");
      str.map((e) => {
        let temp = e.split("=");
        obj[temp[0]] = temp[1];
      });
      obj["withdrawStatus"] = obj["activeTabKey"];
      this.activeTabKey = obj["activeTabKey"];
    }
    this.state = {
      data: "",
      total: 0,
      selectedRowKeys: [],
      isSame: false,
      searchForm: Object.assign(
        {
          flowId: "",
          userId: "",
          phone: "",
          withdrawStatus: "1",
          withdrawUserType: undefined,
          applyTimeFrom: "",
          applyTimeTo: "",
          pageNum: 1,
          pageSize: this.pageSize,
        },
        obj
      ),
      visible: false,
      visible1: false,
      visible2: false,
      checkedForm: {
        flowIds: [],
        whetherPass: false,
        rejectReason: "",
        withdrawUserType:undefined
      },
      // userType:[]
    };
  }

  componentDidMount() {
    this.init();
  }


  async init() {
    let info = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/getSellerData", {});
    this.setState(
      {
        isSame: info.data?.id == info.data?.platformSellerId,
        searchForm: {
          ...this.state.searchForm,
          withdrawUserType: this.props["location"].search ? this.state.searchForm.withdrawUserType / 1 : (info.data?.id == info.data?.platformSellerId ? 3 : 1),
        },
      },
      () => {
        formRef.current?.setFieldsValue({
          withdrawUserType: this.state.searchForm.withdrawUserType,
          // info.data?.id == info.data?.platformSellerId ? 3 : 1,
        });
        this.getList(
          this.state.searchForm.pageNum,
          info.data?.id == info.data?.platformSellerId ? 3 : 1
        );
      }
    );
  }

  async getSellerData() {
    let info = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/getSellerData", {});
    this.setState({
      isSame: info.data?.id == info.data?.platformSellerId,
    });
  }
  async getList(currentPage?: number, type?: number) {
    let formData = {
      ...this.state.searchForm,
      withdrawUserType: type ? type : this.state.searchForm.withdrawUserType,
    };
    if (currentPage) {
      formData.pageNum = currentPage;
    }

    let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/balance/withdraw/listWithDrawInCondition", formData);
    // if (data.code !== 0) {
    //     Modal.error({content: data.message});
    //     return;
    // }
    this.setState({
      data: data?.data?.records,
      total: data?.data?.total,
      searchForm: { ...this.state.searchForm, pageNum: currentPage },
    });
  }

  changePage = (currentPage) => {
    this.getList(currentPage);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  submit = async (values) => {
    console.log(values);
    this.setState(
      { searchForm: { ...this.state.searchForm, ...values } },
      () => {
        this.getList(1);
      }
    );
  };

  onDateChange = (dates, dateArr) => {
    let searchForm = this.state.searchForm;

    searchForm.applyTimeFrom = dateArr[0];
    searchForm.applyTimeTo = dateArr[1];

    this.setState({ searchForm: searchForm });
  };

  onChangeTab = (key) => {
    this.activeTabKey = key;
    let searchForm = this.state.searchForm;
    searchForm.withdrawStatus = this.activeTabKey;
    formRef.current.setFieldsValue(searchForm);
    this.setState({ searchForm: searchForm });
    this.getList(1);
  };

  openModal = (flowId,withdrawUserType) => {
    if (this.activeTabKey === "1" || this.activeTabKey === "3") {
      this.setState({
        visible: true,
        checkedForm: {
          flowIds: [flowId],
          whetherPass: false,
          withdrawUserType
        },
      });
    } else if (this.activeTabKey === "2") {
      this.setState({
        visible1: true,
        checkedForm: {
          flowIds: [flowId],
          whetherPass: false,
          withdrawUserType
        },
      });
    } else if (this.activeTabKey === "4") {
      this.setState({
        visible2: true,
        checkedForm: {
          flowIds: [flowId],
          whetherPass: false,
          withdrawUserType
        },
      });
    }
  };

  closeModal = () => {
    if (this.activeTabKey === "1" || this.activeTabKey === "3") {
      this.setState({ visible: false });
    } else if (this.activeTabKey === "2") {
      this.setState({ visible1: false });
    } else if (this.activeTabKey === "4") {
      this.setState({ visible2: false });
    }
  };

  onCheckChange = (e) => {
    let checkedForm = this.state.checkedForm;
    checkedForm.whetherPass = e.target.value;
    this.setState({ checkedForm: checkedForm });
  };

  //批量审核通过
   doBatchPass = async() => {
    let url = process.env.REACT_APP_REMOTE_URL + "/seller/balance/withdraw/checkWithdrawApply";
    let params={
      flowIds:this.state.selectedRowKeys,
      withdrawUserType:this.state.searchForm.withdrawUserType,
      whetherPass:true
    }
    let res = await Request.jsonPost(url, params);
    if(res.code==0){
      message.success('批量审核通过');
      this.getList(1);
    }

    // let checkedForm = this.state.checkedForm;
    // if (checkedForm.whetherPass) {
    //   delete checkedForm.rejectReason ;
    // }
    // checkedForm.flowIds = this.state.selectedRowKeys;
    // checkedForm.withdrawUserType = this.state.searchForm.withdrawUserType;
    // this.doChangeStatus(url, checkedForm);
  };

  doBatchPay = () => {
    let url = process.env.REACT_APP_REMOTE_URL + "/seller/balance/withdraw/confirmWithdrawPay";
    let checkedForm = this.state.checkedForm;
    if (checkedForm.whetherPass) {
      checkedForm.rejectReason = null;
    }
    checkedForm = {
      flowIds: this.state.selectedRowKeys,
      whetherSuccess: checkedForm.whetherPass,
      failedReason: checkedForm.rejectReason,
    };
    this.doChangeStatus(url, checkedForm);
  };

  doPass = () => {
    let checkedForm = this.state.checkedForm;
    if (checkedForm.whetherPass) {
      checkedForm.rejectReason = null;
    }

    let url = "";
    if (this.activeTabKey === "1" || this.activeTabKey === "3") {
      url =
        process.env.REACT_APP_REMOTE_URL +
        "/seller/balance/withdraw/checkWithdrawApply";
    } else if (this.activeTabKey === "2") {
      url =
        process.env.REACT_APP_REMOTE_URL +
        "/seller/balance/withdraw/confirmWithdrawPay";
      checkedForm = {
        flowIds: checkedForm.flowIds,
        whetherSuccess: checkedForm.whetherPass,
        failedReason: checkedForm.rejectReason,
      };
    } else if (this.activeTabKey === "4") {
      // 接口待确认
      url =
        process.env.REACT_APP_REMOTE_URL +
        "/seller/balance/withdraw/checkWithdrawApply";
      checkedForm = {
        flowIds: checkedForm.flowIds,
        whetherSuccess: checkedForm.whetherPass,
      };
    }

    this.doChangeStatus(url, checkedForm);
  };

  doChangeStatus = async (url, checkedForm) => {
    let data = await Request.jsonPost(url, checkedForm);
    if (data.code !== 0) {
      Modal.error({ content: data.message });
      return;
    }
    const _that = this;

    Modal.success({
      content: "操作成功",
      onOk: () => {
        _that.closeModal();
        _that.getList(1);
      },
    });
  };

  onChangeRejectReason = (e) => {
    let checkedForm = this.state.checkedForm;
    checkedForm.rejectReason = e.target.value;
    this.setState({ checkedForm: checkedForm });
  };
  onWidthdrawChange = (e) => {
    console.log(e, "kkjkjkj");
    this.onChangeTab(e + "");
    // this.setState({
    //   searchForm: { ...this.state.searchForm,c},
    // });
  };

  //提现记录下载
  download = async () => {
    let res = await Request.postBlob(
      process.env.REACT_APP_REMOTE_URL +
        `/seller/balance/withdraw/exportWithDrawlist`,
      {
        withdrawStatus: this.activeTabKey,
        applierType: this.state.searchForm?.withdrawUserType,
      }
    );
    console.log(res);
    let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // 获取请求返回的response对象中的blob 设置文件类型excel
    let downloadElement: any = document.createElement("a");
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = "提现记录.xlsx"; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
  };

  render() {
    let smallWidth = { style: { width: "150px" } };
    // formRef.current?.setFieldsValue({
    //     withdrawUserType : this.state.isSame?3:1,
    // });

    return (
      <div>
        <h1>提现审核</h1>

        <Divider />
        <Form
          //   labelCol={{ span: 10 }}
          initialValues={this.state.searchForm}
          onFinish={this.submit}
          ref={formRef}
          onValuesChange={(current,all)=>{
            if(all.withdrawUserType){
             this.setState({
               searchForm:{...this.state.searchForm,withdrawUserType:all.withdrawUserType}
             })
            }
          }}>
          {/*<Space>*/}
          <Row gutter={24}>
            <Col lg={8} xl={8} xxl={6}>
              <Form.Item label="提现流水号" name="flowId">
                <Input {...smallWidth} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col lg={8} xl={8} xxl={6}>
              <Form.Item label="用户ID" name="userId">
                <Input {...smallWidth} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col lg={8} xl={8} xxl={6}>
              <Form.Item label="手机号码" name="phone">
                <Input {...smallWidth} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col lg={8} xl={8} xxl={6}>
              <Form.Item label="提现状态" name="withdrawStatus">
                <Select
                  style={{ width: "100%" }}
                  options={this.statusArr}
                  {...smallWidth}
                  onChange={this.onWidthdrawChange}
                />
              </Form.Item>
            </Col>
            <Col lg={8} xl={8} xxl={6}>
              <Form.Item label="提现人类型" name="withdrawUserType">
                <Select
                  style={{ width: "100%" }}
                  options={this.state.isSame ? userTypeAll : userTypeArr}
                  {...smallWidth}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col lg={8} xl={8} xxl={6}>
              <Form.Item label="申请时间" style={{ width: "260px" }}>
                <RangePicker
                  onChange={this.onDateChange}
                  style={{ width: "260px" }}
                  showTime
                />
              </Form.Item>
            </Col>
            {this.state.searchForm?.withdrawUserType * 1 > 2 && (
              <Form.Item label="" style={{ width: "60px" }}>
                <Button type="primary" onClick={() => this.download()}>
                  下载
                </Button>
              </Form.Item>
            )}

            <Form.Item style={{ paddingLeft: "110px" }}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Row>

          {/*</Space>*/}
        </Form>
        <div>
          <Tabs activeKey={this.activeTabKey} onTabClick={this.onChangeTab}>
            <Tabs.TabPane tab="待审核" key="1">
              <div style={{ marginBottom: "15px", textAlign: "right" }}>
                {/*{this.state.searchForm?.withdrawUserType>2&& <Button type="primary" onClick={()=>this.download()} style={{marginRight:"8px"}}>下载</Button>}*/}
                <Button
                  type="primary"
                  onClick={this.doBatchPass}
                  disabled={!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0}
                  style={{ marginRight: "8px" }}>
                  批量审核通过
                </Button>
              </div>
              <TableList
                data={this.state.data}
                selectedRowKeys={this.state.selectedRowKeys}
                onChange={this.onSelectChange}
                activeTabKey={this.activeTabKey}
                doCheck={this.openModal}
                searchForm={this.state.searchForm}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="已审核待打款" key="2">
              <div style={{ marginBottom: "15px", textAlign: "right" }}>
                {/*{this.state.searchForm?.withdrawUserType>2&& <Button type="primary" onClick={()=>this.download()}  style={{marginRight:"8px"}}>下载</Button>}*/}
                <Button
                  type="primary"
                  onClick={this.doBatchPay}
                  disabled={
                    !this.state.selectedRowKeys ||
                    this.state.selectedRowKeys.length === 0
                  }
                >
                  批量打款完成
                </Button>
              </div>
              <TableList
                data={this.state.data}
                selectedRowKeys={this.state.selectedRowKeys}
                onChange={this.onSelectChange}
                activeTabKey={this.activeTabKey}
                doCheck={this.openModal}
                searchForm={this.state.searchForm}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="审核未通过" key="3">
              {/*<div style={{marginBottom: "15px", textAlign: 'right'}}>*/}
              {/*    {this.state.searchForm?.withdrawUserType>2&& <Button type="primary" onClick={()=>this.download()}>下载</Button>}*/}
              {/*</div>*/}
              <TableList
                data={this.state.data}
                selectedRowKeys={this.state.selectedRowKeys}
                onChange={this.onSelectChange}
                activeTabKey={this.activeTabKey}
                doCheck={this.openModal}
                searchForm={this.state.searchForm}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="已完成" key="4">
              <TableList
                data={this.state.data}
                selectedRowKeys={this.state.selectedRowKeys}
                onChange={this.onSelectChange}
                activeTabKey={this.activeTabKey}
                doCheck={this.openModal}
                searchForm={this.state.searchForm}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="打款失败" key="5">
              <TableList
                  data={this.state.data}
                  selectedRowKeys={this.state.selectedRowKeys}
                  onChange={this.onSelectChange}
                  activeTabKey={this.activeTabKey}
                  doCheck={this.openModal}
                  searchForm={this.state.searchForm}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
        <br />
        {/* <TableList
          data={this.state.data}
          selectedRowKeys={this.state.selectedRowKeys}
          onChange={this.onSelectChange}
          activeTabKey={this.activeTabKey}
          doCheck={this.openModal}
          searchForm={this.state.searchForm}
        /> */}
        {this.state.total > 0 && (
          <div style={{ textAlign: "right" }}>
            <Pagination
              simple
              pageSize={this.pageSize}
              onChange={this.changePage}
              defaultCurrent={this.state.searchForm.pageNum}
              total={this.state.total}
            />
          </div>
        )}

        {/*onRefresh={this.init()}*/}

        <Modal
          title="提现审核"
          centered
          visible={this.state.visible}
          width={400}
          onCancel={this.closeModal}
          onOk={this.doPass}
          okText="保存"
          cancelText="返回"
        >
          <Space>
            <Form>
              <Form.Item>
                <Radio.Group onChange={this.onCheckChange} value={this.state.checkedForm.whetherPass}>
                  <Radio value={true}>审核通过</Radio>
                  <Radio value={false}>审核拒绝</Radio>
                </Radio.Group>
              </Form.Item>
              {this.state.checkedForm.whetherPass==false&&<Form.Item>
                <Input
                  placeholder="请填写拒绝原因"
                  value={this.state.checkedForm.rejectReason}
                  onChange={this.onChangeRejectReason}
                ></Input>
              </Form.Item>}
            </Form>
          </Space>
        </Modal>

        <Modal
          title="打款确认"
          centered
          visible={this.state.visible1}
          width={400}
          onCancel={this.closeModal}
          onOk={this.doPass}
          okText="保存"
          cancelText="返回"
        >
          <Space>
            <Form>
              <Form.Item>
                <Radio.Group
                  onChange={this.onCheckChange}
                  value={this.state.checkedForm.whetherPass}
                >
                  <Radio value={true}>已确认打款</Radio>
                  <Radio value={false}>打款失败</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Input
                  placeholder="请填写失败原因"
                  disabled={this.state.checkedForm.whetherPass}
                  value={this.state.checkedForm.rejectReason}
                  onChange={this.onChangeRejectReason}
                ></Input>
              </Form.Item>
            </Form>
          </Space>
        </Modal>

        <Modal
          title="提现状态回退"
          centered
          visible={this.state.visible2}
          width={400}
          onCancel={this.closeModal}
          onOk={this.doPass}
          okText="保存"
          cancelText="返回"
        >
          <Space>
            <Form>
              <Form.Item>
                <Radio.Group
                  onChange={this.onCheckChange}
                  value={this.state.checkedForm.whetherPass}
                >
                  <Radio value={true}>提现待审核</Radio>
                  <Radio value={false}>已审核待打款</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Space>
        </Modal>
      </div>
    );
  }
}

function TableList(props: {
  data: any;
  selectedRowKeys: any;
  onChange: any;
  activeTabKey: string;
  doCheck: any;
  searchForm: any;
}) {
  const activeTabKey = props.activeTabKey;
  const searchForm = props.searchForm;
  const rowSelection = {
    selectedRowKeys: props.selectedRowKeys,
    onChange: props.onChange,
  };
  const typeMap={
    1:"消费者",
    2:"异业商家",
    3:"达人提现",
    4:"商家提现",
    5:"推广员提现",
    6:"运营商提现"
  };

  if (props?.data?.length === 0) {
    return <Empty />;
  }

  return (
    <Table
      size="small"
      dataSource={props.data}
      pagination={false}
      rowSelection={rowSelection}
      rowKey="flowId"
      columns={[
        {
          title: "流水号",
          dataIndex: "flowId",
        },
        {
          title: "提现人",
          dataIndex: "userNick",
        },
        {
          title: "提现人类型",
          render: (record) => (
            <div>
              {typeMap[record.withdrawUserType]}
            </div>
          ),
        },
        {
          title: "申请日期",
          dataIndex: "createdTime",
        },
        {
          title: "提现金额（元）",
          dataIndex: "amount",
        },
        {
          title: "手续费（元）/费率",
          render: (record) => (
            <span>
              {(record.amount * record.withdrawConf.serviceFee4customer) / 100}{" "}
              ({record.withdrawConf.serviceFee4customer}%)
            </span>
          ),
        },
        {
          title: "到账金额",
          dataIndex: "arriveAmount",
        },
        {
          title: "提现方式",
          render: () => <span>银行卡</span>,
        },
        {
          title: "更新时间",
          dataIndex: "modifiedTime",
        },
        {
          title: "操作",
          key: "action",
          // width: activeTabKey === '2' ? 215 : 150,
          render: (text, record) => (
            <Space size="middle">
              <Link
                to={
                  "/seller/finance/view/" +
                  record.flowId +
                  "?activeTabKey=" +
                  activeTabKey +
                  "&pageNum=" +
                  searchForm.pageNum +
                  "&withdrawUserType=" +
                  searchForm.withdrawUserType
                }
              >
                查看
              </Link>
              {(activeTabKey=='1'||activeTabKey=='2')&&<>|</>}
              {/*<AuditModal visible={} flowIds={record.flowId} onRefresh={()=>{this.in}} />*/}
              {activeTabKey === "1" && (
                <Button
                  onClick={() => {
                    props.doCheck(record.flowId,record.withdrawUserType);
                  }}
                  type="link"
                  style={{ padding: "4px 0px" }}
                >
                  审核
                </Button>
              )}
              {activeTabKey === "2" && (
                <Button
                  onClick={() => {
                    props.doCheck(record.flowId,record.withdrawUserType);
                  }}
                  type="link"
                  style={{ padding: "4px 0px" }}
                >
                  打款确认
                </Button>
              )}
              {/*{activeTabKey === '2' &&
                                  <Button onClick={() => {
                                  }} type="link" style={{padding: "4px 0px"}}>打款完成</Button>
                                  }
                                  {activeTabKey === '2' &&
                                  <Button onClick={() => {
                                  }} type="link" style={{padding: "4px 0px"}}>打款失败</Button>
                                  }*/}
              {/*{activeTabKey === '3' && <Button onClick={() => {props.doCheck(record.flowId)}} type="link" style={{padding: "4px 0px"}}>重新审核</Button>}*/}
              {/*{activeTabKey === '4' && <Button onClick={() => {}} type="link" style={{padding: "4px 0px"}}>状态回退</Button>}*/}
            </Space>
          ),
        },
      ]}
    />
  );
}
