 import {Form, InputNumber, Modal, Space} from "antd";
import React, {useEffect, useState} from "react";
import Request from "../../../../utils/Request";
interface IProps{
    detail:any,
    visible?:boolean,
    onCancel?:()=>void,
    onReload?:()=>void
}

export const ConfirmCardModal = (props:IProps)=>{
    const { detail,visible,onCancel,onReload } = props;
    const [cardNum,setCardNum] = useState<any>();
    const [cardList,setCardList]=useState<any>([]);

    const doSetExtendCard = async () => {
        if (!cardNum) {
            Modal.error({content: '发放卡不能为空'});
            return;
        }

        let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + `/seller/shareholder/configCarNumToShareholder`, {
            cardNum: cardNum,
            shareholderId: detail.id
        });
        // if (data.code !== 0) {
        //     Modal.error({content: data.message});
        //     return;
        // }
        Modal.success({
            content: data.data, onOk: () => {
                onCancel();
                onReload();
            }
        });
    };

    //获取推广卡列表
    const getDefaultEquityCards= async()=> {
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/equity/listEquityCardModules`, {});
        console.log(res);
        if(res.code==0){
            setCardList(res.data[0])
        }
    };

    useEffect(()=>{
        getDefaultEquityCards();
    },[]);

    useEffect(()=>{
        if(visible){
            setCardNum(undefined)
        }
    },[visible]);


    return  (
        <Modal
            title="推广卡数量配置"
            centered
            visible={visible}
            destroyOnClose={true}
            width={800}
            onCancel={() => onCancel()}
            onOk={() => { doSetExtendCard(); }}
            okText="确定">
            <Space>
                <Form>
                    <Form.Item label="用户ID">
                        {detail.userId}
                    </Form.Item>
                    <Form.Item label="注册手机号码">
                        {detail.shareholderPhone}
                    </Form.Item>
                    <Form.Item label="股东姓名">
                        {detail.shareholderName}
                    </Form.Item>
                    <Form.Item label="用户身份">
                        {detail.distriSaleRoleLevelConf?.roleName}
                    </Form.Item>
                    <Form.Item label="推广卡模板">
                        {cardList?.cardModuleName}（{cardList?.discountRatio / 10}折）
                    </Form.Item>
                    <Form.Item label="推广卡剩余数量">
                        {detail.sendCardConf&&detail.cardSendCondition?(detail.sendCardConf?.totalNumToSend*1-detail.cardSendCondition?.sendNum*1):'-'}（已发放：{detail.cardSendCondition?detail.cardSendCondition?.sendNum:'-'}/已使用：{detail.cardSendCondition?detail.cardSendCondition.usedNum:'-'}）
                    </Form.Item>
                    <Form.Item>
                        <InputNumber
                            style={{ width: 300 }}
                            value={cardNum}
                            onChange={(e:any) => setCardNum(e)}
                            placeholder="输入推广卡数量"
                            addonAfter="张"/>
                    </Form.Item>
                    <Form.Item>
                        如：增加100张，则输入框写“100“即可；若减少20张，则需写“—20“，扣减数量最大不能超过剩余推广卡数量。
                    </Form.Item>
                </Form>
            </Space>
    </Modal>
    )
}