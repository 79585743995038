import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, message, Row } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import Request from "../../../utils/Request";
import { PhoneNo } from "../../../utils/util";
import "./ViewOrder.css";

export default class ViewOrder extends React.Component<
  { match: any },
  { data: any }
> {
  constructor(props) {
    super(props);
    this.state = { data: "" };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let id = this.props.match.params.id;
    let data = await Request.get(
      process.env.REACT_APP_REMOTE_URL +
        "/seller/order/getOrderDetailByOrderId?orderId=" +
        id
    );
    if (!data.error) {
      this.setState({ data: data.data });
    }
    //     else {
    //     message.info("获取数据失败! 错误信息:" + data.message);
    // }
  }

  render() {
    console.log(this.state.data);
    let data = this.state.data;

    if (!data) {
      return <LoadingOutlined />;
    }

    return (
      <div>
        <h1>
          订单详情
          <Button type="link">
            <Link to="/seller/order/list">返回</Link>
          </Button>
        </h1>
        <div className="right">
          <span className="orderinfo-type">{OrderStatus[data.bizStatus]}</span>
        </div>
        <div>
          订单ID: {this.props.match.params.id}{" "}
          <span style={{ marginLeft: "40px" }}>
            订单来源:{" "}
            {data.isFromAlliance === 1
              ? `共享商圈(${data.allianceName})`
              : "本店"}
          </span>
        </div>
        <div className="top-area">
          <Row align={"middle"}>
            <Col span={2}>
              <span className="orderinfo-type">商家</span>
            </Col>
            <Col span={5}>
              <label>商家名称: </label>
              {data.sellerName}
            </Col>
            <Col span={7}>
              <label>商家id: </label> {data.sellerId}
            </Col>
            <Col span={5}>
              <label>商家类型: </label>
              {["套餐", "代金券", "门店付款", "扫码付款"][data.itemType]}
            </Col>
          </Row>

          <Row align={"middle"}>
            <Col span={2}>
              <span className="orderinfo-type">买家</span>
            </Col>
            <Col span={5}>
              <label>买家昵称: </label>
              {data.user.userNick}
            </Col>
            <Col span={7}>
              <label>买家用户id: </label>
              {data.user.userId}
            </Col>
            <Col span={5}>
              <label>手机号码: </label>
              {data.user.userPhoneNo && (
                <PhoneNo value={data.user.userPhoneNo} withButton={true} />
              )}
            </Col>
            <Col span={5}>
              <label>买家身份：</label>-
            </Col>
          </Row>

          <Row align={"middle"}>
            <Col span={2}>
              <span className="orderinfo-type">关联者</span>
            </Col>
            <Col span={5}>
              <label>分享者：</label>{" "}
              {data?.recommendUser ? data?.recommendUser?.userNick : "-"}
            </Col>
            <Col span={7}>
              <label>关联股东: </label>
              {data?.associateShareholder?.shareholderName
                ? data?.associateShareholder?.shareholderName
                : "-"}
            </Col>
            <Col span={5}>
              <label>异店商家: </label>-
            </Col>
            <Col span={5}>
              <label>联盟盟主: </label>
              {data?.associateAllianceMaster?.name
                ? data?.associateAllianceMaster?.name
                : "-"}
            </Col>
          </Row>
        </div>

        <h2>订单信息</h2>
        <table className="full-table">
          <tr>
            <td>
              <label>下单时间：</label>
              {data.createdTime}
            </td>
            <td>
              <label>支付时间: </label>
              {data.kmtPayOrder.payTime}
            </td>
            <td>
              <label>支付方式: </label>
              {data.kmtPayOrder.payWay}
            </td>
            <td>
              <label>支付订单号: </label>
              {data.kmtPayOrder.payOrderId}
            </td>
          </tr>
          <tr>
            <td>
              <label>订单总金额: </label>
              {data.orderFee}
            </td>
            <td>
              <label>折扣卡优惠: </label>
              {data.discountProportion}
            </td>
            <td>
              <label>分红: </label>
              {data?.bonusItems.map((item) => {
                return (
                  <div>
                    {item?.bonusName}--{item?.bonusAmount}(
                    {item?.bonusProportion * 100}%)
                  </div>
                );
              })}
            </td>
            <td>
              <label>分享扣点: </label>
              {data.commissionExpand}
            </td>
          </tr>
          <tr>
            <td>
              <label>异店推广扣点: </label>-
            </td>
            <td>
              <label>异业盟主分红: </label>-
            </td>
            <td>
              <label>商家实收: </label>
              {data.expectedIncome}
            </td>
            <td></td>
          </tr>
        </table>

        <h2>商品信息</h2>
        {data.itemType !== 3 && (
          <table className="full-table border-table">
            <tr>
              <th>商品图片</th>
              <th>商品名称</th>
              <th>商品单价</th>
              <th>数量</th>
              <th>订单总金额</th>
              <th>核销码信息</th>
              <th>使用有效期</th>
              <th>核销情况（核销数/总数）</th>
              <th>核销人</th>
              <th>核销时间</th>
            </tr>
            <tr>
              <td>
                <img src={data.item.itemPic.split(";")[0]} alt="商品详情图片" />
              </td>
              <td>
                <Link to={"/seller/item/view/" + data.item.itemId}>
                  {data.item.itemName}
                </Link>
              </td>
              <td>{data.item.presentPrice}</td>
              <td>{data.amount}</td>
              <td>{data.orderFee}</td>
              <td>
                {data?.verifyRecords?.map((item) => (
                  <div>
                    {item.verifyCode}(
                    {item.verifyStatus === 1
                      ? "待核销"
                      : item.verifyStatus === 2
                      ? "已核销"
                      : "已过期"}
                    )
                  </div>
                ))}
              </td>
              {data?.item?.validateType == 2 && (
                <td>购买后{data.item.validityDate}天内有效</td>
              )}
              {data?.item?.validateType == 1 && (
                <td>
                  {data.item?.validityFrom}～{data.item?.validityTo}
                </td>
              )}
              <td>
                {data.verifyCondition?.verified}/{data.verifyCondition.total}
              </td>
              <td>~</td>
              <td>
                {" "}
                {data?.verifyRecords?.map((item) => (
                  <div>{item.verifyTime}</div>
                ))}
              </td>
            </tr>
          </table>
        )}
        {data.itemType === 3 && <div>门店扫码付款</div>}

        <h2>售后信息</h2>
        <div>
          <table className="full-table">
            <tr>
              <td>
                <label>售后订单号: </label>-
              </td>
              <td>
                <label>售后申请时间: </label>-
              </td>
              <td>
                <label>售后完成时间: </label>-
              </td>
            </tr>
            <tr>
              <td>
                <label>退款金额: </label>-
              </td>
              <td>
                <label>退款操作人: </label>-
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export const OrderStatus = {
  1: "待付款",
  2: "待使用",
  3: "已完成",
  4: "退款",
  5: "已取消",
};
