import { Button, Form, Input, message } from 'antd';
import * as React from 'react';
import Request from '../utils/Request';
import './Login.css';
//登录页面

export default class Login extends React.Component<{ form: any, history: any }> {
    state = {
        loadings: false,
    }

    componentDidMount() {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("sellerName");
    }

    private async onFinish(values: any) {
        if (values.userName === undefined || values.userName === '') {
            message.warning('用户名不能为空！！！')
        } else if (values.password === undefined || values.password === '') {
            message.warning('密码不能为空！！！')
        } else {
            this.setState({ loadings: true })
            let url = `${process.env.REACT_APP_REMOTE_URL}/seller/login`;
            let data = {
                phone: values.userName,
                password: values.password,
                type:'douyin'
            }
            let ret = await Request.jsonPost(url, data);
            if (!ret.error) {
                sessionStorage.setItem("token", ret.data?.token);
                sessionStorage.setItem("sellerName", ret.data?.sellerName);
                this.props.history.push("seller/");
            } else {
                this.setState({ loadings: false })
            }
        }
    }

    public render() {
        const { loadings } = this.state;
        return (
            <div className='loginContent'>
                <div className="logo-form">
                    <div className="logo-tit">
                        <div className="login-logo"></div>
                        <span>商家端</span>
                    </div>
                    <Form onFinish={this.onFinish.bind(this)} initialValues={{ remember: true }} className='formContent'>
                        <Form.Item className="logo-item" label="用户名" name="userName">
                            <Input placeholder="请输入用户名" />
                        </Form.Item>
                        <Form.Item className="logo-item" label="密&nbsp;&nbsp;&nbsp;码" name="password">
                            <Input placeholder="请输入密码" type='password' />
                        </Form.Item>
                        <div className="logo-btn">
                            <Button type="primary" htmlType="submit" className="formButton" loading={loadings}>登录</Button>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}