import React from "react";
import { Divider, Form, Input, Space, Button, Modal, Upload, Select,Row } from "antd";
import settings from "../../../settings";
import Request from '../../../utils/Request';
import { Link } from "react-router-dom";
import { LoadingOutlined, UploadOutlined, StarOutlined } from '@ant-design/icons';

interface page {
    data: any,
    id: number,
    file: any,
    uploadFile:any
}

// let uploadFile;

export default class RetailDetail extends React.Component<{ match: { params: any }, history: any }, page> {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            id: null,
            file: {},
            uploadFile:{}
        };
    }

    componentDidMount() {
        this.fetchSettingData();
    }

    async fetchSettingData() {
        let res:any = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/pay/weixinpayinquire" );
        console.log(res);
        // if (res.code !== 0) {
        //     Modal.error({content: "获取支付参数异常"});
        //     return;
        // }
        this.setState({
            data: res?.data
        });
        console.log(res.data)
    }

    submit = async (values) => {
        console.log(values)
        const {wxPayMchId,wxPayMchKey}=values;
        let formData  = new FormData();
        formData.set('wxpaymchid', wxPayMchId);
        formData.set('wxpaymchkey', wxPayMchKey);
        formData.set('file', this.state.file);
        let result = await Request.post(process.env.REACT_APP_REMOTE_URL + "/seller/pay/weixinpayconfig", formData);

        if (result.code === 0) {
            Modal.info({
                content: result.data, onOk: () => {
                    this.props.history.push('/seller/system/settingPay');
                }
            });
        }
        // else {
        //     if (!result.success) {
        //         Modal.error({content: result.message});
        //         return;
        //     }
        // }
    };


    deleteExistingPic = () => {
        this.setState({file: []});
    };


    render() {
        let smallWidth = {style: {width: '300px'}};
        let styleDesc = {style: {paddingLeft: '10px', color: '#AAAAAA'}};
        let data = this.state?.data;

        if (Object.keys(data).length === 0) {
            return <div><LoadingOutlined/></div>;
        }

        const props:any = {
            name:"file",
            maxCount:1,
            // defaultFileList: [
            //     {
            //         status: 'done',
            //         url:this.state.data.wxPayKeyPath,
            //     }
            // ],
            beforeUpload:(file)=>{
                // uploadFile=file;
                this.setState({
                    file:file?file:''
                })
            },
        };

        return <div>
            <h1>微信支付配置</h1>

            <Form {...settings.FormLayout} initialValues={this.state.data} onFinish={this.submit} >
                <h2>支付配置:</h2>

                <Form.Item label="微信支付商户号" name="wxPayMchId" rules={[{ required: true, message: '请输入微信支付商户号' }]}>
                    <Space direction="vertical">
                        <Input {...smallWidth} defaultValue={data.wxPayMchId} />
                        <Row>微信商户平台(pay.weixin.qq.com)--&gt;账户中心--&gt;商户信息&gt;账户信息&gt;基本账户信息</Row>
                    </Space>
                </Form.Item>

                <Form.Item label="微信支付API密钥" name="wxPayMchKey" rules={[{ required: true, message: '请输入微信支付API密钥' }]}>
                    <Space direction="vertical">
                        <Input {...smallWidth} defaultValue={data.wxPayMchKey} />
                        <Row> 微信商户平台(pay.weixin.qq.com)--&gt;账户中心--&gt;商户信息&gt;账户信息&gt;基本账户信息</Row>
                        <a rel="stylesheet" href="https://kf.qq.com/faq/180830UVRZR7180830Ij6ZZz.html">
                            帮助：如何获得支付API密钥 &gt;&gt;
                        </a>
                    </Space>
                </Form.Item>

                <h2>退款配置:</h2>

                <Form.Item label="API证书" name="wxPayKeyPath" rules={[{ required: true, message: '请上传API证书' }]}>
                    <Space direction="vertical">
                        <Upload {...props} >
                            <Button icon={<UploadOutlined />}>替换已上传文件</Button>
                        </Upload>
                        <Row>微信商户平台(pay.weixin.qq.com)--&gt;账户中心--&gt;账户设置--&gt;API安全--&gt;申请AIP证书下载。</Row>
                        <a rel="stylesheet" href="https://kf.qq.com/faq/180830UVRZR7180830Ij6ZZz.html">
                            帮助：如何获得API证书 &gt;&gt;
                        </a>
                    </Space>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 4}}>
                    <Space>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Form.Item>
                        <Form.Item>
                            <Link to="/seller/system/settingPay"><Button>取消</Button></Link>
                        </Form.Item>
                    </Space>
                </Form.Item>
            </Form>
        </div>;
    }
}
