import { Button, Form, Input, Modal, Select, Space, Table } from "antd"
import React, {useRef} from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Request from "../../../../utils/Request";
import { BalanceChargeModal } from "./BalanceChargeModal";
import { ConfirmCardModal } from "./ConfirmCardModal";
import {log} from "util";


interface IProps{
    detail?:any,
    roleLevelArr?:any
}

export const ConfirmTable = (props:IProps)=>{
    let smallWidth = {style: {width: '150px'}};
    const confirmColumn:any=[
        {
            title: '用户ID',
            dataIndex: 'userId',
            align:"center",
        },
        {
            title: '用户昵称',
            render: (record:any) => (
                <span>{record.userInfo?.userNick}</span>
            ),
            align:"center"
        },
        {
            title: '员工姓名',
            dataIndex: 'employeeName',
            align:"center"
        },
        {
            title: '手机号码',
            dataIndex: 'employeePhone',
            align:"center"
        },
        {
            title: '用户身份',
            render: (record:any) => (
                <span>{record.distriSaleRoleLevelConf?.roleName}</span>
            ),
            align:"center",

        },
        {
            title: '剩余余额(元)',
            render: (record:any) => (
                <span>{record.balanceAccount?record.balanceAccount?.total:'-'}</span>
            ),
            align:"center"
        },
        {
            title: '剩余推广卡(张)',
            render: (record:any) => (
                <span>{record.sendCardConf&&record.cardSendCondition?(record.sendCardConf?.totalNumToSend*1-record.cardSendCondition?.sendNum*1):'-'}</span>
            ),
            align:"center"
        },
        {
            title: '状态',
            render: (record:any) => (
                <div>
                    {record.isEnabled==0&&<span style={{color:"#ff4433"}}>停用</span>}
                    {record.isEnabled==1&&<span style={{color:"#26BF4D"}}>启用</span>}
                </div>
            ),
            align:"center"
        },
        {
            title: '开通时间',
            dataIndex: 'createdTime',
            align:"center"
        },
        {
            title: '操作',
            key: 'action',
            align:"center",
            width: 300,
            render: (text:any, record:any) => (
                <Space size="middle">
                  <Link to={"/seller/employee/editEmployee/" + record.id}>编辑</Link>
                  <Button onClick={() => { setBalanceVisible(true);setCurrentTableItem(record)  }} type="link" style={{padding: "4px 0px"}}>余额充值</Button>
                  <Button onClick={() => { setConfirmCardVisible(true);setCurrentTableItem(record) }} type="link" style={{padding: "4px 0px"}}>推广卡配置</Button>
                  <Button onClick={() => { doCheck(record.id, record.isEnabled)}} type="link" style={{padding: "4px 0px"}}>{record.isEnabled === 1 ? '停用' : '启用'}</Button>
                </Space>
            )
        },
    ];

    const doCheck = async (id?:any,isEnabled?:any)=>{
        if (!id) {
            Modal.error({content: '此为垃圾数据，无法启停'});
            return;
        }
        let param = {id:id};
        if (isEnabled === 0) {
            param['enabled'] = 1; // 启用
        } else {
            param['enabled'] = 0; // 停用
        }

        let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/employee/enableEmployeeIdentity", param);
        // if (data.code !== 0) {
        //     Modal.error({content: data.message});
        //     return;
        // }
        Modal.success({
            content: data.data, onOk: () => {
                getTableData(1)
            }
        });
   }


    // 获取table数据
    const getTableData = async (currentPage:number)=>{
        searchForm.current.pageNum = currentPage
        let formData = searchForm.current;
        if (currentPage) {
            formData.pageNum = currentPage;
        }

        let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/employee/listEmployeeInCondition",formData);
        // if (data.code !== 0) {
        //     Modal.error({content: data.message});
        //     return;
        // };
        setDataSource(data.data.records)
        setTotal(data.data.total)
    }

    useEffect(()=>{
        getTableData(1);
    },[])

    const searchForm = useRef({
        userId: '',
        employeePhone: '',
        employeeDistriStatus: 2,
        roleLevel: '',
        pageNum: 1,
        pageSize: 10,
    });

    const [balanceVisible,setBalanceVisible] = useState(false);
    const [confirmCardVisible,setConfirmCardVisible] = useState(false);
    const [dataSource,setDataSource] = useState([]);
    const [total,setTotal] = useState(0);
    const [currentTableItem,setCurrentTableItem]  =   useState();

    const submit = (values?:any)=>{
        console.log(values)
        searchForm.current = {...searchForm.current, ...values}
        getTableData(1)
    }

     return (
        <>
            <Form onFinish={(e:any)=>{ submit(e) }}>
                <Space>
                    <Form.Item label="用户ID" name="userId">
                        <Input {...smallWidth}/>
                    </Form.Item>
                    <Form.Item label="手机号码" name="employeePhone">
                        <Input {...smallWidth}/>
                    </Form.Item>
                    <Form.Item label="用户身份" name="roleLevel">
                        <Select options={props.roleLevelArr} {...smallWidth}/>
                    </Form.Item>
                    <Form.Item style={{paddingLeft: '110px'}}>
                        <Button type="primary" htmlType="submit">查询</Button>
                        <Link to="/seller/employee/addEmployee" style={{marginLeft: '10px'}}>
                            <Button type="primary">手动添加员工</Button>
                        </Link>
                    </Form.Item>
                </Space>
             </Form>
            <Table
                    size="small"
                    dataSource={dataSource}
                    pagination={ {onChange:(page:number)=>{ getTableData(page)   },pageSize:searchForm.current.pageSize,total:total } }
                    rowKey="id"
                    columns={ confirmColumn } />
                    {currentTableItem&&<BalanceChargeModal
                                        visible={balanceVisible}
                                        onReload={()=>{ getTableData(1)}}
                                        detail={currentTableItem}
                                        onCancel={()=>{ setBalanceVisible(false) }}/>}
                    {currentTableItem&&<ConfirmCardModal
                        visible={confirmCardVisible}
                        detail={currentTableItem}
                        onReload={()=>{ getTableData(1)}}
                        onCancel={()=>{ setConfirmCardVisible(false) }}/>}
           </>)


}