import React from "react";
import {
    Image,
    Divider,
    Empty,
    Modal,
    Table,
} from "antd";
import Request from "../../../utils/Request";
import {Link} from "react-router-dom";

interface page {
    data: any,
}

export default class RetailList extends React.Component<{}, page> {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.getList();
    }

    async getList() {
        let data = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/distribution/listEmployeeDistributionIdentity");
        if (data.code !== 0) {
            Modal.error({ content: data.message });
            return;
        }
        this.setState({ data: data.data });
    }

    render() {
        return <div>
            <h1>分销身份配置</h1>
            <Divider/>
            <div>
                <TableList data={this.state.data}/>
            </div>
            <br/>
        </div>;
    }
}

function TableList(props: { data: any }) {

    if (props.data?.length === 0) {
        return <Empty/>
    }

    let composeTxt = (record) => {
        let conf = record.cardModuleConf;
        if(conf) {
            return `${conf.cardModuleName}（${conf.discountRatio % 10 === 0 ? conf.discountRatio / 10 : conf.discountRatio}）折`
        } else {
            return '';
        }
    };

    return <Table size="small"
                  dataSource={props.data}
                  pagination={false}
                  rowKey="id"
                  columns={[
                      {
                          title: '身份等级',
                          dataIndex: 'roleLevel',
                          align:"center"
                      },
                      {
                          title: '身份名称',
                          dataIndex: 'roleName',
                          align:"center"
                      },
                      {
                          title: '身份图标',
                          align:"center",
                          render: (record) => (
                              <Image src={record.prefix + record.rolePictPath} width={64} height={64}>
                              </Image>
                          )
                      },
                      {
                          title: '关联权益卡',
                          render: (record) => (
                              <span>{composeTxt(record)}</span>
                          ),
                          align:"center"
                      },
                      {
                          title: '分销奖励配置（%）',
                          render: (record) => (
                              <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>实付现金 <div style={{width:"30px",height:"30px",lineHeight:"30px",textAlign:"center",border:"1px solid black",margin:"0 8px"}}>{record.bonusRatio}</div> %</div>
                          ),
                          align:"center"
                      },
                      {
                          title: '更新时间',
                          dataIndex: 'modifiedTime',
                          align:"center"
                      },
                      {
                          title: '操作',
                          key: 'action',
                          render: (text, record) => (
                              <Link to={"/seller/employee/editEmployeeLevel/" + record.id}>编辑</Link>
                          ),
                          align:"center"
                      },
                  ]}/>
}
