import React from "react";

import {Divider, Form, Input, Space, Button, Modal, Select,Row} from "antd";
import settings from "../../../settings";
import Request from '../../../utils/Request';
import {LoadingOutlined} from "@ant-design/icons";
import {filterForm} from "../../../utils/util";

interface page {
    data: any,
    cardList: any,
    originCardList: any,
    normalCard: any,
    userList: any,
}

export default class SharedEdit extends React.Component<{ match: { params: any }, history: any }, page> {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            cardList: [],
            originCardList: [],
            userList: [],
            normalCard: {},
        };
    }

    componentDidMount() {
        this.getDetail();
        this.getCardList();
    }

    async getDetail() {
        let {employeeId} = this.props.match.params;
        console.log(this.props.match)
        console.log(employeeId)

        let data = await Request.get(`${process.env.REACT_APP_REMOTE_URL}/seller/employee/getEmployeeDetail?employeeId=${employeeId}`);
        console.log(data);
        if(data?.code === 0){
            this.setState({data: data?.data});
        }
    }

    async getCardList() {
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/distribution/listEmployeeDistributionIdentity");
        if (res.code !== 0) {
            Modal.error({content: "获取员工等级异常"});
            return;
        }

        let tempArr = [];

        const data = this.state.data;

        res?.data?.forEach(t => {
            if (t?.roleLevel === data?.roleLevel) {
                this.setState({normalCard: t});
            }
            tempArr.push({
                label: t.roleName,
                value: t.roleLevel,
            });
        });

        this.setState({cardList: tempArr, originCardList: res.data});
    }

    submit = async (values) => {
        let formData = values;
        formData['userId'] = this.state.data.userId;
        formData['id'] = this.state.data.id;
        let result = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/employee/confirmEmployeeApply", formData);
        if (result.code === 0) {
            Modal.info({
                content: "编辑成功", onOk: () => {
                    this.props.history.push('/seller/employee/employeeInfoList');
                }
            });
        }
    };

    onChange = (e) => {
        const normalCard = this.state.originCardList.filter(t => t.roleLevel === e);
        if (normalCard && normalCard.length === 1) {
            this.setState({normalCard: normalCard[0]});
        }
    };

    handleChange = (value) => {
        let data = this.state.data;
        data.employeePhone = value;
        this.setState({data});
    };

    handleSearch = async (value) => {
        if (value) {
            let result = await Request.post(process.env.REACT_APP_REMOTE_URL + "/seller/shareholder/searchShareholderByPhone?phone=" + value, null);
            if (result.code === 0) {
                const data = [];
                result.data.forEach(t => {
                    let text;
                    if (t.userName) {
                        text = `${t.userPhone}（昵称：${t.userName}）`;
                    } else {
                        text = `${t.userPhone}`;
                    }
                    data.push({
                        value: t.userPhone,
                        text: text,
                    });
                });

                this.setState({userList: data});
            }
        } else {
            this.setState({userList: []});
        }
    };

    render() {
        let smallWidth = {style: {width: '300px'}};
        let data = this.state?.data;
        if (data&&Object.keys(data).length === 0 || !this.state?.cardList || this.state.cardList?.length === 0) {
            return <div><LoadingOutlined/></div>;
        }
        console.log(this.state?.normalCard);
        // @ts-ignore
        const options = this.state?.userList.map(d => <Select.Option key={d.value}>{d.text}</Select.Option>);

        return <div>
            <h1>手动添加员工拓客权限</h1>
            <Divider/>
            <Form {...settings.FormLayout} initialValues={data} onFinish={this.submit}>
                <Form.Item label="员工姓名" name="employeeName"  rules={[{ required: true, message: '请输入员工姓名' }]}>
                    <Input {...smallWidth} disabled />
                </Form.Item>
                <Form.Item label="手机号码" name="employeePhone" rules={[{ required: true, message: '请输入手机号码' }]}>
                    <Input {...smallWidth} disabled />
                </Form.Item>
                <Form.Item label="入股充值金额" name="rechargeAmount">
                    <Input {...smallWidth} suffix="元"/>
                </Form.Item>
                <Form.Item label="分配员工等级" rules={[{ required: true, message: '请选择员工等级' }]}>
                    <Space direction="vertical">
                        <Form.Item name="roleLevel">
                            <Select options={this.state.cardList} {...smallWidth} onChange={this.onChange}/>
                        </Form.Item>
                        {this.state.normalCard.cardModuleConf && <div>
                            <Form.Item>
                                折扣卡：{this.state.normalCard.cardModuleConf.cardModuleName}（{this.state.normalCard.cardModuleConf.discountRatio % 10 === 0 ? this.state.normalCard.cardModuleConf.discountRatio / 10 : this.state.normalCard.cardModuleConf.discountRatio}）折
                            </Form.Item>
                            < Form.Item>
                                员工分红：实付金额{this.state.normalCard.bonusRatio}%分红
                            </Form.Item>
                        </div>}
                    </Space>
                </Form.Item>
                <Form.Item label="分配推广卡数" name="cardNum">
                    <Space direction="vertical" >
                        <Input {...smallWidth} suffix="张"/>
                        {this.state.normalCard.cardModuleConf && this.state.normalCard.cardModuleConf.isForPromotion === 1 && <Row>
                            当前推广卡：{this.state.normalCard.cardModuleConf.cardModuleName}（{this.state.normalCard.cardModuleConf.discountRatio % 10 === 0 ? this.state.normalCard.cardModuleConf.discountRatio / 10 : this.state.normalCard.cardModuleConf.discountRatio}）
                        </Row>}
                    </Space>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 4}}>
                    <br/>
                    <Space>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">确认添加</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button onClick={()=>{this.props.history.push('/seller/employee/employeeInfoList')}}>取消</Button>
                        </Form.Item>
                    </Space>
                </Form.Item>
            </Form>
        </div>;
    }
}
