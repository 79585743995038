import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import * as React from 'react';

export function PhoneNo(props: { value: string, withButton?: boolean }) {
    const [display, setDisplay] = React.useState(false);

    const encrype = () => {
        let newPhoneNo = props.value.substr(0, 3) + '***' + props.value.substr(6);
        return newPhoneNo
    }

    return (<span>
                {display ? props.value : encrype()}  &nbsp;
        {props.withButton && <a onClick={() => setDisplay(!display)} href="javascript:void(0)">
            {display && <EyeInvisibleOutlined style={{fontSize: '16px'}}/>}
            {!display && <EyeOutlined style={{fontSize: '16px'}}/>}
        </a>}
        </span>);
}

export const filterForm = (form) => {
    let obj = {};
    Object.keys(form).forEach(ele => {
        if (!validateNull(form[ele])) {
            obj[ele] = form[ele]
        }
    });
    return obj;
};

/**
 * 判断是否为空
 */
export function validateNull(val) {
    if (typeof val === 'boolean') {
        return false
    }
    if (typeof val === 'number') {
        return false
    }
    if (val instanceof Array) {
        if (val.length === 0) return true
    } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true
    } else {
        return val === 'null' || val == null || val === 'undefined' || val === '';
    }
    return false
}
