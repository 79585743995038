import React from 'react';
import './App.css';
import zhCN from 'antd/es/locale/zh_CN';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd'
import Login from './pages/Login';
import MainFrame from './pages/MainFrame';

export default class App extends React.Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <ConfigProvider locale={zhCN}>
            <Route exact path="/" component={Login} />
            <Route path="/seller/" component={MainFrame} />
          </ConfigProvider>
        </Switch>
      </HashRouter>
    )
  }
}
