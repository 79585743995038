import React from "react";
import { Button, Divider, Empty, Form, Input, Modal, Pagination, Select, Space, Table, } from "antd";
import Request from "../../../utils/Request";
import {filterForm} from '../../../utils/util';

interface page {
    data: any,
    detail: any,
    total: number,
    selectedRowKeys: any,
    searchForm: any,
}

export default class EquityRecord extends React.Component<{}, page> {
    pageSize = 15;

    activeTabKey = "2";

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            detail: {},
            total: 0,
            selectedRowKeys: [],
            searchForm: {
                receiverUserId: '',
                reveiverPhone: '',
                usedStatus: '',
                senderUserId: '',
                senderPhone: '',
                pageNum: 1,
                pageSize: this.pageSize,
            },
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.getList();
    }

    async getList(currentPage?: number) {
        let formData = this.state.searchForm;
        if (currentPage) {
            formData.pageNum = currentPage;
        }

        let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/equity/cardReceiveLogList", filterForm(formData));
        // if (data.code !== 0) {
        //     Modal.error({content: data.message});
        //     return;
        // }
        this.setState({data: data.data.records, total: data.data.total});
    }

    changePage = (currentPage) => {
        this.getList(currentPage);
    };

    submit = async (values) => {
        this.setState({searchForm: {...this.state.searchForm, ...values}});
        this.getList(1);
    };

    render() {
        let smallWidth = {style: {width: '160px'}};

        const searchForm = this.state.searchForm;

        return <div>
            <h1>推广卡发放记录</h1>
            <Divider/>
            <Form labelCol={{span: 10}} initialValues={searchForm} onFinish={this.submit}>
                <Space direction="vertical">
                    <Space>
                        <Form.Item label="领取人手机号码" name="receiverUserPhone">
                            <Input {...smallWidth}/>
                        </Form.Item>
                        <Form.Item label="领取人id" name="receiverUserId">
                            <Input {...smallWidth}/>
                        </Form.Item>
                        <Form.Item label="使用情况" name="usedStatus">
                            <Select {...smallWidth}>
                                <Select.Option value={''}>所有</Select.Option>
                                <Select.Option value={1}>已使用</Select.Option>
                                <Select.Option value={0}>待使用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Space>
                    <Space>
                        <Form.Item label="发放人手机号码" name="senderUserPhone">
                            <Input {...smallWidth}/>
                        </Form.Item>
                        <Form.Item label="发放人id" name="senderUserId">
                            <Input {...smallWidth}/>
                        </Form.Item>
                        <Form.Item style={{paddingLeft: '30px'}}>
                            <Button type="primary" htmlType="submit">搜索</Button>
                        </Form.Item>
                    </Space>
                </Space>
            </Form>

            <div>
                <TableList data={this.state.data}/>
            </div>
            <br/>
            {this.state.total > 0 && <div style={{textAlign: 'right'}}>
                <Pagination simple pageSize={this.pageSize} onChange={this.changePage} defaultCurrent={1}
                            total={this.state.total}/>
            </div>}
        </div>;
    }
}

function TableList(props: { data: any }) {

    if (!props.data || props.data.length === 0) {
        return <Empty/>
    }

    return <Table size="small" dataSource={props.data} pagination={false} rowKey="id"
                  columns={[
                      {
                          title: '领取时间',
                          dataIndex: 'createdTime',
                      }, {
                          title: '领取人信息',
                          render: (record) => (
                              <div>
                                  <div>昵称：{record.receiveUser?.userNick}</div>
                                  <div>id：{record.receiveUser?.userId}</div>
                                  <div>手机：{record.receiveUser?.userPhoneNo}</div>
                              </div>
                          )
                      }, {
                          title: '推广卡名称',
                          render: (record) => (
                              <span>{record.cardModule?.cardModuleName}（{record.cardModule?.discountRatio % 10 === 0 ? record.cardModule?.discountRatio / 10 : record.cardModule?.discountRatio}）折</span>
                          )
                      }, {
                          title: '发放人类型',
                          render: (record) => (
                              <span>{record.distributerType === 1 ? '股东' : '员工'}</span>
                          )
                      }, {
                          title: '发放人信息',
                          render: (record) => (
                              <div>
                                  <div>昵称：{record.sendUser?.userNick}</div>
                                  <div>id：{record.sendUser?.userId}</div>
                                  <div>手机：{record.sendUser?.userPhoneNo}</div>
                              </div>
                          )
                      }, {
                          title: '是否使用',
                          render: (record) => (
                              <span>{record.hasUsed === 0 ? '未使用' : '已使用'}</span>
                          )
                      },
                  ]}/>
}
