import React, {useEffect, useState} from "react";
import { Divider, Form, InputNumber, Space, Button, Modal, Radio } from "antd";
import Request from '../../../utils/Request';

const formRef:any= React.createRef();
const ShareConfig=()=>{
    const [openType,setOpenType]=useState<number>(0);//开通条件 ：0为满多少元 、1为满多少单
    const [condition,setCondition]=useState<number>(0);//根据开通条件而变化元可以为小数和整数 、单只能为整数

    const getConfigDetail=async ()=>{
        let res = await Request.get(`${process.env.REACT_APP_REMOTE_URL}/seller/getShareBonusConfig`);
        if(res?.code === 0){
            formRef.current.setFieldsValue({...res.data})
            setOpenType(res?.data?.openType);
            setCondition(res?.data?.condition);
        }
    }

    useEffect(()=>{
        getConfigDetail();
    },[]);

    const onSubmit = async (values) => {
        console.log(values)
        let formData = values;
        let result = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/shareBonusConfig", formData);

        if (result.code === 0) {
            Modal.info({
                content: "编辑成功",
                onOk: () => {
                    getConfigDetail();
                }
            });
        }
    };

    return(
        <div>
            <h1>店铺管理-分享赚配置</h1>
            <Divider/>
            <h3 style={{color:"#ff1e1e",margin:"20px 0"}}>顾客默认无分享赚钱的功能，需达到一定条件即可获得分享赚的功能</h3>
            {/*<h2>开通条件</h2>*/}
            <Form
                name="basic"
                ref={formRef}
                labelCol={{ span: 2}}
                wrapperCol={{ span: 4 }}
                onFinish={onSubmit}
                autoComplete="on"
                onValuesChange={(current,all)=>{
                    console.log(all);
                }}>

                <Form.Item label="开通条件" name="openType" rules={[{ required: true, message: '请选择开通条件!' }]}>
                    <Radio.Group value={openType} onChange={(e)=>setOpenType(e.target.value)}>
                        <Space direction="vertical">
                            <Radio value={0}>按订单计算</Radio>
                            <Radio value={1}>按价格计算</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="顾客累计消费" name="condition" rules={[{ required: true, message: '请输入消费数量!' }]}>
                    <InputNumber addonBefore="满" addonAfter={ openType===0?"单":"元" } />
                </Form.Item>

                <Form.Item wrapperCol={{offset: 4}}>
                    <Space>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{margin:"60px 0"}}>保存</Button>
                        </Form.Item>
                    </Space>
                </Form.Item>

            </Form>
        </div>
    );
}
export default ShareConfig;
