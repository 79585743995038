import {Button, Col, Descriptions, Divider, Input, Modal, Pagination, Row, Space, Table} from "antd";
import React, {useState} from "react";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import settings from "../../../settings";
import Request from '../../../utils/Request';

interface Props extends RouteComponentProps {

}

export default class ViewBalance extends React.Component<Props, { data: any, userData: any, total: number }> {
    pageSize = 15;

    changeTypeList = {
        1: '余额充值',
        2: '分享商品佣金',
        3: '购物消费',
        4: '计单退款返还',
        5: '股东分红',
        6: '员工分红',
        7: '提现',
        8: '提现退回',
        9: '系统调整'
    };

    constructor(props) {
        super(props);
        this.state = {
            data: '',
            userData: '',
            total: 0
        };
    }

    componentDidMount() {
        this.fetchData(1);
        this.fetchUserData();
    }

    async fetchData(currentPage) {
        let id = this.props.match.params['id'];
        let params: any = {};
        params.pageNum = currentPage
        params.pageSize = this.pageSize + '';
        params.userId = id;
        let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/balance/getUserBalanceLog", params);
        this.setState({data: data.data?.records, total: data.data?.total});
    }

    async fetchUserData() {
        let id = this.props.match.params['id'];
        let data = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/balance/getUserBalanceCondition?userId=" + id);
        this.setState({userData: data.data});
    }

    changePage = (currentPage) => {
        this.fetchData(currentPage);
    }

    changeTypeVal = (record) => {
        let val = this.changeTypeList[record.changeType];
        if (record.changeType === 7 || record.changeType === 8) {
            if (record.flowId) {
                val += `【提现单号ID：${record.flowId}】`
            } else {
                val = '';
            }
        } else if (record.changeType === 2 || record.changeType === 3) {
            if (record?.orderId) {
                val += `【订单ID：${record.orderId}】`
            } else {
                val = '';
            }
        }
        return val;
    }

    render() {
        let userData = this.state.userData;

        return <div>
            <h1>查看余额<Button type="link"><Link to="/seller/finance/balance">返回</Link></Button></h1>
            <Divider />

            <div>
                {userData && <Descriptions>
                    <Descriptions.Item label="用户ID">{this.props.match.params['id']}</Descriptions.Item>
                    <Descriptions.Item label="注册手机号码">{userData.user.userPhoneNo}</Descriptions.Item>
                    <Descriptions.Item label="账户余额">{userData.balanceCondition.total}
                        （充值剩余：{userData.balanceCondition.recharge}；
                        佣金余额：{userData.balanceCondition.dividends}）
                    </Descriptions.Item>
                </Descriptions>}

                <div style={{textAlign: 'right'}}>
                    <Recharge id={this.props.match.params['id']} onSuccess={() => this.fetchData(1)}>
                        <Button type="primary">余额充值</Button>
                    </Recharge>
                </div>
                <br/>
            </div>
            <Table pagination={false} dataSource={this.state.data} columns={
                [{title: '变动时间', dataIndex: 'createdTime'},
                    {
                        title: '变动金额',
                        render: (record) =>
                            <span>{record.changeAmount}</span>
                    },
                    {title: '变动后账户余额', dataIndex: 'balanceAfterChange'},
                    {
                        title: '账户类型',
                        render: (record) => <span>{{1: '充值余额', 2: '佣金余额'}[record.impactAccountType]}</span>
                    },
                    {title: '变更类型', render: (record) => <span>{this.changeTypeVal(record)}</span>},  
                    {title: '变动原因', render: (record) => <span>{record.payDesc}</span>}
                        
                ]
            }/>
            <br/>
            {this.state.total > 0 && <div style={{textAlign: 'right'}}>
                <Pagination simple pageSize={this.pageSize} onChange={this.changePage} defaultCurrent={1}
                            total={this.state.total}/>
            </div>}
        </div>;
    }
}

//id, children
export function Recharge(props: any) {
    const [userData, setUserData] = useState('' as any);
    const [shown, setShown] = useState(false);
    const [amount, setAmount] = useState(0);


    const showDialog = async () => {
        let data = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/balance/getUserBalanceCondition?userId=" + props.id);
        setUserData(data.data);
        setShown(true);
    }

    const confirm = async () => {
        let params = {
            "amount": amount,
            "userId": props.id
        };
        let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/balance/recharge", params);
        if (!data.error) {
        //     Modal.error({content: data.message});
        //     return false;
        // } else {
            Modal.info({
                content: data.data, onOk: () => {
                    if (props.onSuccess) {
                        props.onSuccess();
                    }
                }
            });
            setShown(false);
            // return true;
        }
    }

    const cancel = () => {
        setShown(false);
    }

    return <>
        <span onClick={showDialog}>
        {props.children}
    </span>
        {userData && <Modal visible={shown} title="余额账户充值" onOk={confirm} onCancel={cancel}>
            <div>
                <Space direction="vertical">
                    <Row>
                        用户ID: {userData.user.userId}
                    </Row>
                    <Row>注册手机号码: {userData.user.userPhoneNo}
                    </Row>
                    <Row>
                        <Col span={12}>用户姓名: (昵称: {userData.user.userNick})</Col>
                        <Col span={12}>用户身份: </Col>
                    </Row>
                    <Row>账户余额: {userData.balanceCondition.total}
                        （充值剩余：{userData.balanceCondition.recharge}；
                        佣金余额：{userData.balanceCondition.dividends}）
                    </Row>
                    <Row>
                        <Col>
                            <Input
                                type="number"
                                value={amount > 0 ? amount : ''}
                                onChange={(e) => setAmount(parseInt(e.target.value))}
                                placeholder="输入用户充值的金额" {...settings.smallSize} />&nbsp;元
                        </Col>
                    </Row>
                </Space>
            </div>
        </Modal>}</>

}
