import React from "react";

import {Divider, Form, Input, Space, Button, Modal, Upload, Radio} from "antd";
import settings from "../../../settings";
import Request from '../../../utils/Request';

interface page {
    data: any,
    cardList: any,
    prefix: string,
    cardPictPath: string,
    imgList: any,
    discountRatio: string,
}

export default class MgrAdd extends React.Component<{ match: { params: any }, history: any }, page> {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            cardList: [],
            prefix: '',
            cardPictPath: '',
            imgList: [],
            discountRatio: '',
        };
    }

    componentDidMount() {
        this.getCardList();
    }

    async getCardList() {
        let result = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/getDefaultModulePic");
        if (result.code === 0) {
            this.setState({prefix: result.data.prefix, cardList: result.data.templeList});
        }
        // else {
        //     if (!result.success) {
        //         Modal.error({content: '获取默认卡背景失败'});
        //         return;
        //     }
        // }
    }

    submit = async (values) => {
        console.log(values);
        let formData = values;
        if (this.state.imgList && this.state.imgList.length > 0) {
            formData['cardPictPath'] = this.state.imgList[0];
        } else {
            formData['cardPictPath'] = this.state.cardPictPath;
        }

        formData['discountRatio'] = this.state.discountRatio;
        console.log(formData);

        let result = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/equity/saveOrUpdateCardModule", values);

        if (result.code === 0) {
            Modal.info({
                content: "新增成功", onOk: () => {
                    this.props.history.push('/seller/equity/mgr');
                }
            });
        }
        // else {
        //     if (!result.success) {
        //         Modal.error({content: result.message});
        //         return;
        //     }
        // }
    };

    onChange = (e) => {
        const val = e.target.value;
        if (val !== '1') {
            this.setState({imgList: []});
        }
        this.setState({cardPictPath: val});
    };

    onUploadChange = ({fileList}) => {
        this.setState({imgList: fileList});
    };

    deleteExistingPic = () => {
        this.setState({imgList: []});
    };

    updateDiscount(val) {
        this.setState({discountRatio: val});
    }

    render() {
        let smallWidth = {style: {width: '300px'}};
        let data = this.state.data;

        return <div>
            <h1>新增权益卡</h1>
            <Divider/>
            <Form {...settings.FormLayout} initialValues={data} onFinish={this.submit}>
                <Form.Item label="权益卡名称" name="cardModuleName">
                    <Input {...smallWidth}/>
                </Form.Item>
                <Form.Item label="权益卡背景" name="cardPictPath">
                    <Radio.Group onChange={this.onChange}>
                        <Space direction="vertical">
                            {this.state.cardList.map((card, index) =>
                                <Radio value={card} key={index}>
                                    <img src={this.state.prefix + card}
                                         style={{width: 300, height: 120, borderRadius: '10px'}} alt="none"></img>
                                </Radio>
                            )}
                            <Radio value="1">
                                自定义背景
                            </Radio>
                            {this.state.cardPictPath === '1' &&
                            <div><Upload headers={{Authorization: sessionStorage.getItem('token')}}
                                         data={{fullAddress: 'false'}}
                                         action={process.env.REACT_APP_REMOTE_URL + '/seller/equity/uploadCardPic'}
                                         listType="picture-card"
                                         fileList={this.state.imgList}
                                         onChange={this.onUploadChange}
                                         onRemove={this.deleteExistingPic}
                            >
                                {this.state.imgList.length < 1 && '+ Upload'}
                            </Upload>
                                <div className="tip">支持上传PNG、JPG、JPEG和GIF格式的图片，建议尺寸为686 x 252px，大小不超过1MB</div>
                            </div>
                            }
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="折扣比例">
                    <Input {...smallWidth} suffix="%" onChange={e => this.updateDiscount(e.target.value)}/>
                    <br/><br/>该身份顾客在店内直接在线付款，可直接享受的优惠比例
                </Form.Item>
                <Form.Item wrapperCol={{offset: 4}}>
                    <br/>
                    <Space>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button onClick={() => {
                                this.props.history.push('/seller/equity/mgr')
                            }}>取消</Button>
                        </Form.Item>
                    </Space>
                </Form.Item>
            </Form>
        </div>;
    }
}
