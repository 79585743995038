import React from "react";
import { Divider, Form, Input, Checkbox, Radio, Space, Button, Modal, Row, Col } from "antd";
import settings from "../../../settings";
import Request from '../../../utils/Request';
import { LoadingOutlined } from "@ant-design/icons";
import * as path from "path";
import { stringify } from "querystring";
import { useForm } from "antd/es/form/Form";
export default class WithdrawSetting extends React.Component<{}, { info: object, id: number, sellerId: number, noLimits: boolean, dayOnMonth: string, dayOnWeek: string, timeType: number, serviceFee4customer: number, serviceFee4seller: number, minServiceFee: number, minMoney: number, week: any }> {

    constructor(props) {
        super(props);

        this.state = {
            info: {},
            id: null,
            sellerId: null,
            noLimits: false,
            dayOnMonth: null,
            dayOnWeek: null,
            serviceFee4customer: null,
            serviceFee4seller: null,
            minServiceFee: null,
            minMoney: null,
            timeType: null,
            week: [
                { label: '周一', value:'0' },
                { label: '周二', value:'1' },
                { label: '周三', value:'2' },
                { label: '周四', value:'3' },
                { label: '周五', value: '4' },
                { label: '周六', value: '5' },
                { label: '周日', value: '6' }
            ]
        };
    }

    componentDidMount() {
        this.fetchSettingData();
    }

    async fetchSettingData() {
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/balance/getWithdrawConfig");
        console.log(!res.error)
        if (res.code == 0) {
            this.setState({
                info: res.data,
                id: res.data?.id,
                sellerId: res.data?.sellerId,
                noLimits: res.data.withdrawDay?.noLimits,
                dayOnMonth: res.data.withdrawDay?.dayOnMonth,
                dayOnWeek: res.data.withdrawDay?.dayOnWeek,
                minMoney: res.data?.minMoney,
                minServiceFee: res.data?.minServiceFee,
                serviceFee4customer: res.data?.serviceFee4customer,
                serviceFee4seller: res.data?.serviceFee4seller,
                timeType: res.data.withdrawDay?.dayOnMonth?1:2
            });
            this.formRef.setFieldsValue({...res.data,...res.data?.withdrawDay})
        }
    }

    onTimeTypeChange = (e) => {
        console.log(e)
        this.setState({
            timeType: e.target.value
        });
    };

    onWeekChange = (checkedValues) => {
        console.log('checked = ', checkedValues);
        this.setState({
            dayOnWeek: checkedValues
        })
    };

    submit = async (values) => {
        console.log(values)
        let formData = values;
        formData['id'] = this.state.id;
        formData['sellerId'] = this.state.sellerId;
        if (this.state.noLimits) {
            formData['withdrawDay'] = { noLimits: this.state.noLimits }
        } else {
            formData['withdrawDay'] = {
                noLimits: this.state.noLimits,
                dayOnMonth: this.state.timeType == 1 ? values.dayOnMonth : '',
                dayOnWeek: this.state.timeType == 2 ? values.dayOnWeek.toString() : ''
            }
        }

        let res = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/balance/setWithdrawConfig", formData);
        if (res.code === 0) {
            Modal.success({ content: res.data });
            this.fetchSettingData();
        }
    };

    formRef: any = React.createRef();
    render() {
        let smallWidth = { style: { width: '150px' } };
        let style = { style: { paddingLeft: '10px' } };
        let styleDesc = { style: { MarginTop: '0px', color: '#AAAAAA' } };

        // @ts-ignore
        return <div>
            <h1>提现设置</h1>
            <Divider />
            <Form
                {...settings.FormLayout}
                onFinish={this.submit}
                ref={(e) => { this.formRef = e }}
                onValuesChange={(e: any) => {
                if (e.noLimits) {
                    this.setState({ noLimits: e.noLimits})
                }
                }}>
                <Form.Item label="指定提现时间" rules={[{ required: true, message: '请设置提现时间' }]} >
                    <Radio.Group value={this.state.noLimits} onChange={(e:any)=>{  this.setState({noLimits:e.target.value}) }}>
                        <Space direction="vertical">
                            <Radio value={true}>不限</Radio>
                            <Radio value={false}>指定时间</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                {this.state.noLimits == false && <Space direction="vertical">
                        <Space  style={{margin:"10px 0 20px 300px "}}>
                            <Form.Item noStyle>
                                <Radio checked={this.state.timeType == 1} onChange={(e: any) => { this.setState({ timeType: e.target.checked ? 1 : 2 }) }}>每月</Radio>
                            </Form.Item>

                            <Form.Item noStyle name="dayOnMonth">
                                <Input  {...smallWidth} disabled={this.state.timeType !== 1} suffix="日" />
                            </Form.Item>
                            <div {...styleDesc}>如多日中间用","隔开。</div>
                        </Space>

                        <Space direction="vertical" style={{margin:"20px 0 40px 300px "}}>
                            <Radio checked={this.state.timeType == 2} onChange={(e: any) => { this.setState({ timeType: e.target.checked ? 2 : 1 }) }}>每周</Radio>
                            <Form.Item noStyle name="dayOnWeek">
                                <Checkbox.Group options={this.state.week} disabled={this.state.timeType !== 2} onChange={this.onWeekChange}/>
                            </Form.Item>
                        </Space>
                    </Space>
                }

                <Form.Item label="最低提现金额" >
                    <Space >
                        <Form.Item name='minMoney'>
                            <Input  {...smallWidth} />
                        </Form.Item>
                        <div {...styleDesc}>金额必须达到最低提现金额，才能申请提现，0为不限</div>
                    </Space>
                </Form.Item>
                <Form.Item label="顾客提现手续费">
                    <Space>
                        <Form.Item name="serviceFee4customer">
                            <Input value={this.state.serviceFee4customer} {...smallWidth} />
                        </Form.Item>
                        <Row {...styleDesc}>%（注：如填1就是 代表每笔提现，收取提现金额1%的手续费）</Row>
                    </Space>
                </Form.Item>
                <Form.Item label="商家提现手续费">
                    <Space>
                        <Form.Item name="serviceFee4seller">
                            <Input {...smallWidth} />
                        </Form.Item>
                        <Row {...styleDesc}>%（注：如填1就是 代表每笔提现，收取提现金额1%的手续费）</Row>
                    </Space>
                </Form.Item>
                <Form.Item label="最低手续费">
                    <Space>
                        <Form.Item name="minServiceFee">
                            <Input {...smallWidth} />
                        </Form.Item>
                        <Row {...styleDesc}>（注：单笔手续费计算出来小于该值时，则取该值）</Row>
                    </Space>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4 }}>
                    <br />
                    <Space>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">保存</Button>
                        </Form.Item>
                    </Space>
                </Form.Item>
            </Form>
        </div>;
    }
}
