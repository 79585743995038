import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, Table, Tabs, Row, Col, Spin, Divider, Modal, message} from 'antd';
import Request from "../../../utils/Request";
import { Recharge } from "./ViewBalance"; //余额充值

// @ts-ignore
const { TabPane } = Tabs;

interface ITabProps {
    status?: any
}

const BusinessTabItem = (props: ITabProps) => {
    const { status } = props;
    const {TextArea} = Input
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [changePrice,setChangePrice] = useState<string>('');
    const [userId,setUserId] = useState<string>(''); 
    const [remark,setRemark] = useState('');
    const [searchForm, setSearchForm] = useState({
        userId: '',
        userPhoneNo: '',
        sellerId: ''
    });
    const [pageNum, setPageNum] = useState<any>(1);
    const [pageSize, setPageSize] = useState<any>(10);
    const [total, setTotal] = useState<number>(0);

    const [form] = Form.useForm();
    //搜索
    const onFinish = (e) => {
        console.log(e);
        setSearchForm(e);
        initTable(pageNum, e)
    };
    const tab0 = [
        {
            title: '用户ID',
            dataIndex: 'userId',
            key: 'userId',
            align: 'center',
            width: 350,
            render: (text, record, index) => (
                <div>{record?.user?.userId}</div>
            )
        },
        {
            title: '手机号',
            key: 'userPhoneNo',
            dataIndex: 'userPhoneNo',
            align: 'center',
            render: (text, record, index) => (
                <div>{record?.user?.userPhoneNo}</div>
            )
        },
        {
            title: '昵称',
            key: 'userNick',
            dataIndex: 'userNick',
            align: 'center',
            render: (text, record, index) => (
                <div>{record?.user?.userNick}</div>
            )
        },
        {
            title: '当前余额总计',
            dataIndex: 'total',
            key: 'total',
            align: 'center'
        },
        {
            title: '充值余额',
            key: 'recharge',
            dataIndex: 'recharge',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index) => (
                <div>
                    <Button type='link' href={"#/seller/finance/view-balance/" + record?.user?.userId} size={'small'}>查看</Button>
                    <Recharge id={record?.user?.userId} onSuccess={() => initTable(1)}>
                        <Button type="link">余额充值</Button>
                    </Recharge>
                    <Button type='link' onClick={() => showModal(index)}>余额修改</Button>
                </div>
            ),
        }
    ]
    const tab1 = [
        {
            title: '商家ID',
            dataIndex: 'sellerId',
            key: 'sellerId',
            align: 'center'
        },
        {
            title: '商家名称',
            key: 'sellerName',
            dataIndex: 'sellerName',
            align: 'center'
        },
        {
            title: '当前余额总计',
            key: 'balance',
            dataIndex: 'balance',
            align: 'center',
            render: (text, record, index) => (
                <div>{record.balance / 100}</div>
            ),
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index) => (
                <>
                    <Button type='link' href={"#/seller/finance/ViewMerchant/" + record?.sellerId} size={'small'}>查看</Button>
                </>
            ),
        }
    ];

    useEffect(() => {
        initTable(1);
    }, []);


    const column: any = status === '0' ? tab0 : tab1;
    const [dataSource, setDataSource] = useState<any>([]);
    const [loading, setLoading] = useState(false)

    const showModal = (i:number) => {
        setIsModalVisible(true);
        setUserId(dataSource[i].id);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setChangePrice('');
        setRemark('');
        Amountpaid();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setChangePrice('');
        setRemark('');
    };

    const alter = (e:any) => {
        setChangePrice(e.target.value)
    };

    const inputRemark = (e:any) => {
        setRemark(e.target.value);
    }

    const Amountpaid = async () => {
        let params = {
            amount:changePrice,
            userId:userId,
            payDesc:remark
        };
        let isPaid = await Request.post(process.env.REACT_APP_REMOTE_URL + "/seller/balance/expend", params);
        if(isPaid.data){
            message.success(isPaid.data, 5);
            initTable(1);
        }
    }

    //获取列表信息
    const initTable = async (e: number, formInput?: any) => {
        setPageNum(e);
        setLoading(true);
        setDataSource([]);
        let params = {
            pageNum,
            pageSize,
            userPhoneNo: searchForm?.userPhoneNo || formInput?.userPhoneNo,
            userId: searchForm?.userId || formInput?.userId
        }
        if (status === '0') {
            let balanceRes = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/balance/getUserBalanceListBySeller", params);//顾客余额
            if (balanceRes.code == 0) {
                setDataSource(balanceRes?.data?.records.map(item => { return { ...item, id: item?.user?.userId } }));
                setTotal(balanceRes.data?.total);
            }
        } else {
            let res = await Request.post(process.env.REACT_APP_REMOTE_URL + `/seller/balance/listAllianceBalance`, {
                sellerId: searchForm?.sellerId || formInput?.sellerId,
                pageNum,
                pageSize,
            });
            if (res.code == 0) {
                setDataSource(res.data?.result);
                setTotal(res.data?.total);
            }
        }
        setLoading(false);
    };

    return (
        <div>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={(value) => onFinish(value)}
                    autoComplete="on"
                    style={{ padding: "16px 0" }}>
                    <Row gutter={24}>
                        <Col span={4} >
                            {status === '0' && <Form.Item
                                label="用户ID"
                                name="userId"
                                rules={[{ required: false, message: '请输入用户ID!' }]}>
                                <Input placeholder='请输入用户ID' />
                            </Form.Item>}
                            {status === '1' && <Form.Item
                                label="商家ID"
                                name="sellerId"
                                rules={[{ required: false, message: '请输入商家ID!' }]}>
                                <Input placeholder='请输入商家ID' />
                            </Form.Item>}
                        </Col>
                        <Col span={4} >
                            {status === '0' && <Form.Item
                                label="手机号"
                                name="userPhoneNo"
                                rules={[{ required: false, message: '请输入手机号!' }]}>
                                <Input placeholder='请输入手机号' />
                            </Form.Item>}
                        </Col>
                        <Col span={1} >
                            <Button onClick={() => { form.resetFields(); }}>重置</Button>
                        </Col>
                        <Col span={1} >
                            <Button type="primary" htmlType="submit">搜索</Button>
                        </Col>
                    </Row>
                </Form>
                <div className="businessSettingWrap">
                    <Table
                        size={"middle"}
                        dataSource={dataSource}
                        columns={column}
                        rowKey={'id'}
                        scroll={{ y: 1500 }}
                        pagination={{
                            pageSize: pageSize,
                            showQuickJumper: true,
                            total: total,
                            onChange: (page: number) => { initTable(page) }
                        }} />
                </div>
            </Spin>
            <Modal title={"余额修改 修改后将变更商家钱包金额，并生成对应支出流水"} 
                visible={isModalVisible} 
                centered={true} 
                maskClosable={true} 
                onCancel={handleCancel} 
                onOk={handleOk}
            
                >
                <p>支出金额:</p>
                <p><Input  placeholder='请输入支出金额' value={changePrice} onChange={alter} maxLength={7} type='number'/></p>
                <p>备注:</p>
                <TextArea showCount maxLength={50} value={remark} onChange={inputRemark} ></TextArea>
            </Modal>
        </div>

    )
}

const BalanceList = () => {
    const [status, setStatus] = useState<string>('0');//tabs
    const onTabs = (key: any) => {
        setStatus(key) //tabs
    };
    return (
        <div style={{ background: "#fff", padding: "20px" }}>
            <h3>余额管理</h3>
            <Divider />

            <Tabs type="card" defaultActiveKey={status} onChange={(e) => onTabs(e)} tabBarGutter={0} tabBarStyle={{ "color": "#ff443" }}>
                <TabPane tab="顾客余额管理" key="0">
                    {status === '0' && <BusinessTabItem status={status} key={1} />}
                </TabPane>
                <TabPane tab="共享商圈商家" key="1">
                    {status === '1' && <BusinessTabItem status={status} key={2} />}
                </TabPane>
            </Tabs>
        </div>
    );
}
export default BalanceList;