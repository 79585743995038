import React from "react";
import {  Divider,  Modal, Tabs } from "antd";
import Request from "../../../utils/Request";
import { ConfirmTable } from "./components/ConfirmTable";
import { UnConfirmTable } from "./components/UnconfirmTable";

interface page {
    data: any,
    detail: any,
    detailCard: any,
    total: number,
    selectedRowKeys: any,
    searchForm: any,
    visible1: boolean,
    visible2: boolean,
    identList: any,
    roleLevelArr: any,
    amount: any,
    cardNum: any
}

export default class SharedList extends React.Component<{}, page> {
    pageSize = 15;

    activeTabKey = "2";

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            detail: {},
            detailCard: {},
            total: 0,
            selectedRowKeys: [],
            identList: [],
            roleLevelArr: [],
            visible1: false,
            visible2: false,
            amount: '',
            cardNum: '',
            searchForm:{}
        };
    }

    componentDidMount() {
        this.getIdentList();
    }

    async getIdentList() {
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/distribution/listDistributionIdentity");
        // if (res.code !== 0) {
        //     Modal.error({content: res.message});
        //     return;
        // }

        let tempArr = [{
            label: '所有',
            value: '',
        }];

        res.data.forEach(t => {
            tempArr.push({
                label: t.roleName,
                value: t.roleLevel,
            });
        });

        this.setState({roleLevelArr: tempArr, identList: res.data});
    }


    onChangeTab = (key) => {
        this.activeTabKey = key;
        let searchForm = this.state.searchForm;
        searchForm.employeeDistriStatus = Number(this.activeTabKey);
        this.setState({searchForm: searchForm});
    };

    render() {

        return <div>
            <h1>股东列表</h1>
            <Divider/>

            <div>
                <Tabs  activeKey={this.activeTabKey} onTabClick={this.onChangeTab} type="card">
                    <Tabs.TabPane tab="已确认股东" key="2" >
                        <ConfirmTable  roleLevelArr={this.state.roleLevelArr} key={this.activeTabKey=='2'?new Date().getTime():''}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="待确认股东" key="1">
                        <UnConfirmTable   key={this.activeTabKey=='1'?new Date().getTime():''}/>
                    </Tabs.TabPane>
                </Tabs>

            </div>
        </div>;
    }
}

