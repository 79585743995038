import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import Request from "../../../utils/Request";
import { PhoneNo } from "../../../utils/util";
import "./OrderList.css";
import { OrderStatus } from "./ViewOrder";

export default class OrderList extends React.Component<
  {},
  { data: any; total: number; filterData: any }
> {
  pageSize = 10;
  constructor(props) {
    super(props);
    this.state = { data: [], total: 0, filterData: {} };
  }

  componentDidMount() {
    this.fetchData(1, {});
  }

  async fetchData(page: number, filterData: any) {
    this.setState({ filterData: filterData });
    let params = { pageNum: page, pageSize: this.pageSize, ...filterData };
    let data = await Request.jsonPost(
      process.env.REACT_APP_REMOTE_URL + "/seller/order/listOrderInCondition",
      params
    );
    if (!data.error) {
      this.setState({ data: data.data?.records, total: data.data?.total });
    }
    // else{
    //     message.info("获取数据失败! 错误信息:"+data.message);
    // }
  }

  changePage = (newPage) => {
    this.fetchData(newPage, {});
  };

  filter = (values) => {
    for (let key of [
      "createdTimeFrom",
      "createdTimeTo",
      "payTimeFrom",
      "payTimeTo",
    ]) {
      if (values[key]) {
        values[key] = values[key].format("YYYY-MM-DD HH:mm:ss");
      }
    }

    let filterData = {};

    for (let key of Object.keys(values)) {
      if (values[key]) {
        filterData[key] = values[key];
      }
    }
    this.fetchData(1, values);
  };

  getStatusOptions() {
    let options = [{ label: "请选择", value: "" }];
    for (let key of Object.keys(OrderStatus)) {
      options.push({ label: OrderStatus[key], value: key });
    }
    return options;
  }

  render() {
    console.log(this.state.data);
    let data = this.state.data;

    let showTimeFormat = { defaultValue: moment("00:00:00", "HH:mm:ss") };

    return (
      <div>
        <h1>订单列表</h1>

        <div className="filter">
          <Form onFinish={this.filter}>
            <Row>
              <Col span={8}>
                <Form.Item label="商品名称" name="itemName">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="订单号" name="orderId">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="下单人手机号" name="userPhoneNo">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={8}>
                <Form.Item label="商品类型" name="itemType">
                  <Select
                    options={[
                      { label: "请选择", value: "" },
                      { label: "套餐", value: 0 },
                      { label: "代金券", value: 1 },
                    ]}
                    defaultValue={""}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="订单来源" name="isFromAllianceMemberSeller">
                  <Select
                    options={[
                      { label: "请选择", value: "" },
                      { label: "本店", value: 0 },
                      { label: "异店", value: 1 },
                    ]}
                    defaultValue={""}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="订单状态" name="bizStatus">
                  <Select options={this.getStatusOptions()} defaultValue={""} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item label="下单时间">
                  <Space>
                    <Form.Item name="createdTimeFrom">
                      <DatePicker
                        placeholder="开始时间"
                        showTime={showTimeFormat}
                      />
                    </Form.Item>
                    <Form.Item name="createdTimeTo">
                      <DatePicker
                        placeholder="结束时间"
                        showTime={showTimeFormat}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="支付时间">
                  <Space>
                    <Form.Item name="payTimeFrom">
                      <DatePicker
                        placeholder="开始时间"
                        showTime={showTimeFormat}
                      />
                    </Form.Item>
                    <Form.Item name="payTimeTo">
                      <DatePicker
                        placeholder="结束时间"
                        showTime={showTimeFormat}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
            </Row>

            <div>
              <Space>
                <Button htmlType="submit" type="primary">
                  过滤
                </Button>
                <Button htmlType="reset" type="link">
                  重置
                </Button>
              </Space>
              <br />
              <br />
            </div>
          </Form>
        </div>

        {data?.length === 0 && <Alert message="没发现数据" type="info" />}

        {data?.length > 0 && (
          <table className="order-list">
            <tr>
              <th>商品信息</th>
              <th>订单金额</th>
              <th>结算金额</th>
              <th>商品类型</th>
              <th>所属商家</th>
              <th>用户信息</th>
              <th>订单状态</th>
              <th>核销情况</th>
              <th>操作</th>
            </tr>
            {data.map((row) => (
              <>
                <tr className="order-header">
                  <td colSpan={9}>
                    <Space>
                      <span>订单号: {row.orderId}</span>
                      <span>下单时间: {row.createdTime}</span>
                      <span>
                        订单来源:{" "}
                        {row.orderFrom === 2
                          ? `共享商圈(${row?.allianceName})`
                          : "本店"}
                      </span>
                    </Space>
                  </td>
                </tr>
                <tr>
                  <td>
                    {row.itemType === 3 ? "门店扫码付款" : ""}
                    {row.itemType !== 3 && (
                      <>
                        <div className="order-name">{row?.item?.itemName}</div>

                        <div>商品单价: {row?.item?.presentPrice}</div>

                        <div>购买数量: {row.amount}</div>
                      </>
                    )}
                  </td>
                  <td>{row.orderFee}</td>
                  <td>
                    <div>实付金额: {row.realPaidFee}</div>
                    <div>佣金支出:{row.commissionExpand}</div>
                    <div>预计收入:{row.expectedIncome}</div>
                  </td>
                  <td>
                    {row.itemType === 0 ? "套餐" : ""}
                    {row.itemType === 1 ? "代金券" : ""}
                    {row.itemType === 2 ? "门店付款" : ""}
                    {row.itemType === 3 ? "扫码付款" : ""}
                  </td>
                  <td>
                    <div>{row.seller.name}</div>
                    <div>商家id: {row.seller.id}</div>
                  </td>
                  <td>
                    <div>昵称: {row.user.userNick}</div>
                    <div>用户id: {row.user.userId}</div>
                    <div>
                      手机:{" "}
                      {row.user.userPhoneNo && (
                        <PhoneNo value={row.user.userPhoneNo} />
                      )}
                    </div>
                  </td>
                  <td>{OrderStatus[row.bizStatus]}</td>
                  <td>
                    {row.verifyCondition.verified}/{row.verifyCondition.total}
                  </td>
                  <td>
                    <Link to={"/seller/order/view/" + row.orderId}>详情</Link>
                  </td>
                </tr>
              </>
            ))}
          </table>
        )}

        {this.state.total > 0 && (
          <div style={{ textAlign: "right" }}>
            <br />
            <Pagination
              showTotal={(total) => <span>共有{total}条记录</span>}
              pageSize={this.pageSize}
              onChange={this.changePage}
              defaultCurrent={1}
              total={this.state.total}
            />
          </div>
        )}
      </div>
    );
  }
}
