import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import Request from '../../utils/Request';

export default class UserList extends React.Component<{match:any, history:any},{data:any}> {
    constructor(props){
        super(props);
        this.state = {data:''};
    }

    componentDidMount(){
        this.fetchData();
    }

    async fetchData(){
        let id = this.props.match.params.id;
        let data = await Request.get(process.env.REACT_APP_REMOTE_URL+"/seller/getUserDetail?userId="+id );
        this.setState({data:data.data});
    }
    

    render(){       
        let basicInfo = this.state.data.userBaseInfo;

        return (
            <div>
                <h1>用户信息</h1>
                <div><Link to="/seller/user/list">返回</Link></div>

                 {!this.state.data&&<LoadingOutlined />}

                {this.state.data&&<div>
                <h2>账户信息</h2>
                <Row>                    
                    <Col span={8}>注册手机: {basicInfo.userPhoneNo}</Col>
                    <Col span={8}>推荐人: </Col>
                    <Col span={8}>创建时间: {basicInfo.createdTime}</Col>
                </Row>
                <Row>
                    <Col span={8}>用户昵称: {basicInfo.userNick}</Col>
                    <Col span={8}>会员身份: </Col>
                </Row>
                </div>}

            </div>

        )
    }
}