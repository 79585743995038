import axios from 'axios';
import {Modal, Button, Space, message} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const AxiosWrap:any  = ()=>{
    const request:any = axios.create({
        baseURL: "",
        timeout: 30000,
    });
    // 请求拦截器
    request.interceptors.request.use(function (config) {
        // 在发送请求之前做些什么
        config.headers.Authorization = sessionStorage.getItem("token");
        return config;
    }, function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    });
    // 响应拦截器
    request.interceptors.response.use(function (response:any) {
        console.log(response)
        if(response.data.code===0){
            return response
        }else {
            if (response.data.code=='401'){
                confirm({
                    title: `${response.data.message}`,
                    content: ``,
                    okText: '去登录',
                    onOk() {
                        window.location.href="/"
                    },
                    onCancel() {
                        Modal.destroyAll();
                    },
                });
            }else{
                message.error(response.data.message);
            }
        }
        return response;
    }, function (error:any) {
        console.log(error)
        return Promise.reject(error);
    });

    return request;
};


export default class Request {

    public static async get(url: string,params?: any,responseType?:string) {
        try {
            let res = await AxiosWrap().get(url,params);
            return res.data;
        } catch (err) {
            return { success: false, message: err.message };
        }
    }
    public static async getBlob(url: string,params?: any) {
        try {
            let res = await AxiosWrap()({url:url, method: 'get',params:params,responseType: 'blob'});
            // let res = await AxiosWrap()({url:url, method: 'get',data:params,responseType: 'blob'});
            return res.data;
        } catch (err) {
            return { success: false, message: err.message };
        }
    }

    public static async postBlob(url: string,params?: any) {
        try {
            let res = await AxiosWrap()({url:url, method: 'post',data:params,responseType: 'blob',headers:{'Content-Type': "application/json"}});
            return res.data;
        } catch (err) {
            return { success: false, message: err.message };
        }
    }

    public static async delete(url: string) {
        try {
            let res = await AxiosWrap().delete(url);
            return res.data;
        } catch (err) {
            return { success: false, message: err.message };
        }
    }
    public static async post(url: string, params: any) {
        try {
            let res = await AxiosWrap().post(url, params, {'Content-Type': "application/x-www-form-urlencoded" });
            return res.data;
        } catch (err) {
            return { success: false, message: err.message };
        }
    }
    public static async jsonPost(url: string, params: any) {
        try {
            let res = await AxiosWrap().post(url, params, { 'Content-Type': "application/json" });
            return res.data;
        } catch (err) {
            return { success: false, message: err.message };
        }
    }
}
