import React, { useState, useEffect, useRef } from 'react';
import {Button, Form, Input, Select, Space, Table, Tabs, Row, Col, Divider, DatePicker, Modal,} from 'antd';
import Request from "../../../utils/Request";
import moment from 'moment';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const  CommissionDetail=()=>{
    const [form] = Form.useForm();
    const [amountType,setAmountType]=useState<string>('0');
    const columns:any = [
        {
            title: '记录时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            align:'center'
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            align:'center'
        },
        {
            title: '佣金类型',
            key: 'bonusType',
            align:'center',
            render: (text,record,index) => (
                 <div>{bonusTypeMap.find(item=>item.value===text.bonusType*1)?.label}</div>
            ),
        },
        {
            title: '商品名称',
            dataIndex: 'itemTitle',
            key: 'itemTitle',
            align:'center'
        },
        {
            title: '购买者信息',
            key: 'orderConsumer',
            align:'center',
            render: (text,record,index) => (
                <div>
                    <div>昵称：{text?.orderConsumer?.userNick}</div>
                    <div>ID：{text?.orderConsumer?.userId}</div>
                </div>
            ),
        },
        {
            title: '分佣者信息',
            key: 'beneficiary',
            align:'center',
            render: (text,record,index) => (
                <div>
                    <div>昵称：{text?.beneficiary?.userNick}</div>
                    <div>ID：{text?.beneficiary?.userId}</div>
                </div>
            ),
        },
        {
            title: '分佣者类型',
            dataIndex: 'beneficiaryIdentity',
            key: 'beneficiaryIdentity',
            align:'center'
        },
        // {
        //     title: '分佣者身份',
        //     dataIndex: 'beneficiary',
        //     key: 'beneficiary',
        //     align:'center'
        // },
        {
            title: '实付金额(元)',
            dataIndex: 'realPaidFee',
            key: 'realPaidFee',
            align:'center'
        },
        {
            title: '佣金金额(元)',
            dataIndex: 'bonusFee',
            key: 'bonusFee',
            align:'center'
        },
        {
            title: '佣金状态',
            key: 'bonusStatus',
            align:'center',
            render: (text,record,index) => (
                <div>{bonusStatusMap.find(item=>item.value===text.bonusStatus*1)?.label}</div>
            ),
        }
    ];
    const [dataSource,setDataSource]=useState<any>([]);
    const bonusStatusMap=[
        {
            value:0,
            label:'所有'
        },
        {
            value:1,
            label:'待分红'
        },
        {
            value:2,
            label:'已分红'
        },
        {
            value:3,
            label:'已失效'
        }
    ];
    const bonusTypeMap=[
        {
            value:0,
            label:'所有'
        },
        {
            value:1,
            label:'商品分享分佣'
        },
        {
            value:2,
            label:'股东分红'
        },
        {
            value:3,
            label:'员工拓客分红'
        },
        {
            value:4,
            label:'盟主佣金'
        },
        {
            value:5,
            label:'联盟商家佣金'
        }
    ];
    const bonusToUserTypeMap=[
        {
            value:0,
            label:'所有'
        },
        {
            value:1,
            label:'普通用户'
        },
        {
            value:2,
            label:'股东'
        },
        {
            value:3,
            label:'员工'
        },
        {
            value:4,
            label:'盟主'
        },
        {
            value:5,
            label:'联盟商家'
        }
    ];
    const [searchForm,setSearchForm]=useState({ pageNum:1,pageSize:10 });
    const [waitingVerifyBonus,setWaitingVerifyBonus]=useState<any>(0);
    const [accumulationBonus,setAccumulationBonus]=useState<any>(0);
    const [total,setTotal]=useState<number>(0);

    const getFields = () => {
        const dateFormat = 'YYYY-MM-DD HH:mm:ss';
        const children = [];
        children.push(
            <Col span={4} >
                <Form.Item
                    label="佣金状态"
                    name="bonusStatus"
                    rules={[{ required: false, message: '请选择佣金状态' }]}>
                    <Select
                        showSearch={false}
                        placeholder='请选择佣金状态'
                        defaultActiveFirstOption={false}
                        showArrow={true}
                        filterOption={false}
                        notFoundContent={null}>
                        {bonusStatusMap.map((item:any) => (
                            <Option key={item.value} value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={4} >
                <Form.Item
                    label="佣金类型"
                    name="bonusType"
                    rules={[{ required: false, message: '请选择佣金状态' }]}>
                    <Select
                        showSearch={false}
                        placeholder='请选择佣金类型'
                        defaultActiveFirstOption={false}
                        showArrow={true}
                        filterOption={false}
                        notFoundContent={null}>
                        {amountType=='1'?(bonusTypeMap.map((item:any) => (
                            <Option key={item.value} value={item.value}>{item.label}</Option>
                        ))):(bonusTypeMap.filter((item:any)=>item.value<4).map((item:any) => (
                            <Option key={item.value} value={item.value}>{item.label}</Option>
                        )))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={4} >
                <Form.Item
                    label="订单号"
                    name="orderId"
                    rules={[{ required: false, message: '请输入员工姓名!' }]}>
                    <Input />
                </Form.Item>
            </Col>,
            <Col span={4} >
                {amountType=='0'&&<Form.Item
                    label="分佣者ID"
                    name="bonusToUserId"
                    rules={[{ required: false, message: '请输入员工姓名!' }]}>
                    <Input />
                </Form.Item>}
                {amountType=='1'&&<Form.Item
                    label="佣金提供方ID"
                    name="bonusOfferId"
                    rules={[{ required: false, message: '请输入员工姓名!' }]}>
                    <Input />
                </Form.Item>}
            </Col>,
            <Col>
                <Form.Item
                    label="记录时间"
                    name="date"
                    rules={[{ required: false, message: '请选择记录时间!' }]}>
                    <RangePicker format={dateFormat} showTime />
                </Form.Item>
            </Col>,
        );
        return children;
    };

    //tabs
    const onTabs=(key:any)=>{
        setAmountType(key)
        console.log(key,amountType)
    };

    useEffect(()=>{
        initTable(1);
    },[amountType,searchForm]);

    //搜索
    const onFinish=(e)=>{
        console.log(e);
        let params={...searchForm, ...e};
        if(e.date){
            params.timeFrom=moment(e?.date[0]).format("YYYY-MM-DD HH:mm:ss");
            params.timeTo=moment(e?.date[1]).format("YYYY-MM-DD HH:mm:ss");
            delete params.date;
        }
        setSearchForm({...params});
    };
    //获取列表信息
    const initTable=async (e:number)=>{
        console.log(e)
        let res = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/bonusDetail/listBonusDetailInConditon", {...searchForm,amountType,pageNum:e});
        if(res.code==0){
            setDataSource(res.data?.pageResult?.records.length>0?res.data?.pageResult?.records:[]);
            setWaitingVerifyBonus(res.data?.bonusSituation?.waitingVerifyBonus?res.data?.bonusSituation?.waitingVerifyBonus:0);
            setAccumulationBonus(res.data?.bonusSituation?.accumulationBonus?res.data?.bonusSituation?.accumulationBonus:0);
            setTotal(res.data?.pageResult?.total);
        }
    };

    return (
        <div>
            <h1>佣金明细</h1>
            <Divider />

            <Tabs type="card" defaultActiveKey={amountType}	 onChange={(e)=>onTabs(e)} tabBarGutter={0} tabBarStyle={{"color":"#ff443"}}>
                <TabPane tab="佣金支出明细" key="0"></TabPane>
                <TabPane tab="佣金收入明细" key="1"></TabPane>
            </Tabs>

            <div>
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={(value)=>onFinish(value)}
                    autoComplete="on"
                    style={{padding:"16px 0"}}>
                    <Row gutter={24}>{getFields()}</Row>
                    <Row justify="end" gutter={16} style={{margin:"0px 0 16px"}}>
                        <Col span={1} >
                            <Button onClick={() => { form.resetFields();}}>重置</Button>
                        </Col>
                        <Col span={1} >
                            <Button type="primary" htmlType="submit">搜索</Button>
                        </Col>
                    </Row>
                </Form>

                <Table
                    size={"middle"}
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{ x: 1500 }}
                    pagination={{ current:searchForm?.pageNum,pageSize:searchForm?.pageSize,showQuickJumper:true,total:total,onChange:(page:number)=>{ initTable(page)   } }}
                    footer={() => <div>{ amountType=='0'?`待核销支出佣金: ${waitingVerifyBonus} 元 | 累计已支出佣金：${accumulationBonus} 元`:`待核销佣金收入: ${waitingVerifyBonus} 元 | 累计佣金收入：${accumulationBonus} 元`}</div>}/>
            </div>
        </div>
    );
}
export default CommissionDetail;
