import {Button, Col, Descriptions, Divider, Form, message, Modal, Popconfirm, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import Request from '../../../utils/Request';

interface Props extends RouteComponentProps {}

const CBDMerchantItemsManagement =(props:any)=>{
    const [id,setId]=useState<number>(props?.match?.params?.allianceMasterId);
    const [dataSource,setDataSource]=useState<any>([]);
    const [name,setName]=useState<any>('');
    const [phone,setPhone]=useState<any>('');
    const [address,setAddress]=useState<any>('');
    const [principal,setPrincipal]=useState<any>('');
    const itemTypeMap:any = {
        0: '团购套餐',
        1: '代金券',
        2: '门店付款'
    };
    const categoryMap:any=[
        { name: '所有', value: -1 },
        { name: '餐饮美食', value: 1 },
        { name: '休闲娱乐', value: 2 },
        { name: '美容美发', value: 3 },
        { name: '教育培训', value: 4 },
        { name: '酒店公寓', value: 5 },
        { name: '体育健身', value: 6 },
        { name: '景点乐园', value: 7 },
        { name: '洗浴足疗', value: 8 },
        { name: '生活服务', value: 9 },
        { name: '其他', value: 0 }
    ];//商户分类
    const columns:any= [
        {
            title: '商品ID',
            dataIndex: 'itemId' ,
            key:'itemId' ,
            align:'center'
        },
        {
            title: '商品名称',
            dataIndex: 'itemName' ,
            key:'itemName' ,
            align:'center'
        },
        {
            title: '销售价',
            dataIndex: 'originalPrice' ,
            key:'originalPrice' ,
            align:'center'
        },
        {
            title: '剩余库存',
            dataIndex: 'stock' ,
            key:'stock' ,
            align:'center'
        },
        {
            title: '商家分类',
            key: 'itemCategory',
            align:'center',
            render: (text,record,index) => (
                <div>{ categoryMap.find(item=>item.value===record?.itemCategory*1)?.name }</div>
            ),
        },
        {
            title: '商品类型',
            key:'itemType' ,
            align:'center',
            render: (text,record,index) => (
                <div>{ itemTypeMap[record?.itemType*1] }</div>
            ),

        },
        {
            title: '上/下架状态',
            key: 'itemStatus',
            align:'center',
            render: (text,record,index) => (
                <>
                    <div>{['下架','上架'][text?.itemStatus]}</div>
                </>
            )
        },
        {
            title: '添加时间',
            dataIndex: 'createdTime' ,
            key:'createdTime' ,
            align:'center'
        },
        {
            title: '排序',
            dataIndex: 'orderNo' ,
            key:'orderNo' ,
            align:'center'
        }
    ];

    const initTable=async()=>{
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/itemList/${id}`);
        setDataSource( res.data?.itemList);
        setName(res?.data?.name);
        setPhone(res?.data?.phone);
        setAddress(res?.data?.address);
        setPrincipal(res?.data?.principal);
    };

    useEffect(()=>{
        initTable();
    },[]);

    return(
        <div>
            <h1>共商商圈-商品管理 <Button type="link"><Link to="/seller/shareCBD/myCBD">返回</Link></Button></h1>
            <Divider />

            <Descriptions>
                <Descriptions.Item label="商家名称">{name}</Descriptions.Item>
                <Descriptions.Item label="负责人">{principal}({phone})</Descriptions.Item>
                <Descriptions.Item label="地址">{address}</Descriptions.Item>
            </Descriptions>

            <br />

            <Table size={'middle'} rowKey={'id'} columns={columns} dataSource={dataSource} pagination={false} />
        </div>
    );
};
export default CBDMerchantItemsManagement;

