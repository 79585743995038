import * as React from 'react';
import * as Icon from '@ant-design/icons';
import { Menu, Dropdown, Modal, message } from 'antd';

import './MainFrame.css';
import Request from '../utils/Request';
import { OrderedListOutlined, ShopOutlined,HomeOutlined,UserOutlined, IdcardOutlined ,WechatOutlined,BarChartOutlined,UsergroupAddOutlined,TransactionOutlined,PayCircleOutlined,SettingOutlined,UserAddOutlined,FormOutlined ,ShoppingCartOutlined,TeamOutlined,QrcodeOutlined,CreditCardOutlined,DeploymentUnitOutlined,ShareAltOutlined,ApartmentOutlined} from '@ant-design/icons';
import MainFrameRoute from '../route/MainFrameRoute';
import {AppstoreOutlined, MailOutlined} from "@ant-design/icons/lib";

interface Props {
    match: any;
    history: any;
}
interface State {
    stretchState: boolean;
    moduleName: string;
    role: number;
    userName: any;
    isData: boolean;
    queryId: string;
    isNavClick: boolean;
}
export default class MainFrame extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            stretchState: false,
            moduleName: 'AgentManagement',
            role: 0,
            userName: '',
            isData: false,
            queryId: '',
            isNavClick: true,
        };
    }

    componentDidMount(){
        let token = sessionStorage.getItem('token');
        if( !token ){
            // this.props.history.push('/');
        }
    }

    UNSAFE_componentWillMount() {
        let role = Number(sessionStorage.getItem("role"));
        this.setState({
            role: role,
            moduleName: role === 0 ? 'OperationConfiguration' : 'AgentManagement',
            userName: sessionStorage.getItem("userName")
        })
    }

    /**左侧菜单收起 */
    getStretchState() {
        if (this.state.stretchState === true) {
            this.setState({
                stretchState: false
            })
        } else {
            this.setState({
                stretchState: true
            })
        }
    }

    /**token验证 */
    verification(text) {
        if (text === '令牌验证失败,无权限用户禁止访问' || text === '请传入合法的Token') {
            this.props.history.push("/");
        }
    }


    /**菜单切换 */
    handleClick = e => {
        this.props.history.push( e.key );
        this.setState({moduleName:e.key})
    };

    /**退出登录 */
    private loginOut = async () => {
        let me = this;
        Modal.confirm({
            icon: <Icon.ExclamationCircleOutlined />,
            content: '是否确认退出系统？',
            centered: true,
            okText: '是',
            cancelText: '否',
            maskClosable: true,
            async onOk() {
                let url = `${process.env.REACT_APP_REMOTE_URL}/seller/logout`;
                let ret = await Request.post(url, '');
                if (ret.error === false) {
                    message.success('退出系统成功!');
                    sessionStorage.removeItem("token");

                    me.props.history.push("/");
                } else {
                    message.error(ret.errroMsg);
                }
            },
        });
    }

    public render() {
        const { stretchState, moduleName } = this.state;
        return (

            <div className="wrapper" >
                <div className="head">
                    <div className="head-title">
                        {sessionStorage.getItem("sellerName")}
                    </div>
                    <div className="head-stretch" onClick={this.getStretchState.bind(this)}>
                        {stretchState === true ? <Icon.MenuUnfoldOutlined /> : <Icon.MenuFoldOutlined />}
                    </div>
                    <Dropdown trigger={['click']} overlay={
                        <div className="head-user-info">
                            {/* <span onClick={() => this.changePasswordModule(true)} ><Icon.LockOutlined />修改密码</span> */}
                            <span onClick={this.loginOut}><Icon.ExportOutlined />退出系统</span>
                        </div>}>
                        <div className="head-user">
                            <div className="user-img">
                                <span></span>
                            </div>
                            <div className="user-name">
                                {/* {this.state.userInfo.name} */}
                                {this.state.userName}
                            </div>
                            <div className="icon"><Icon.DownOutlined /></div>
                        </div>
                    </Dropdown>
                </div>
                <div className={stretchState === true ? "nav active" : "nav"}>
                    <Menu mode="inline" onClick={this.handleClick} selectedKeys={[moduleName]} defaultSelectedKeys={[moduleName]}  >
                        <Menu.Item key="/seller/home/home" icon={<BarChartOutlined />} >首页概况</Menu.Item>
                        <Menu.SubMenu key="g1" title="店铺管理" icon={<ShopOutlined />}>
                            <Menu.Item icon={<FormOutlined />}>店铺资料</Menu.Item>
                            <Menu.Item key="/seller/shareConfig/shareConfig" icon={<ShoppingCartOutlined />}>分享赚配置</Menu.Item>
                            <Menu.Item key="/seller/item/list" icon={<ShoppingCartOutlined />}>商品管理</Menu.Item>
                            <Menu.Item key="/seller/order/list" icon={<OrderedListOutlined />}>订单管理</Menu.Item>
                            <Menu.Item key="/seller/commission/list" icon={<TransactionOutlined />}>佣金明细</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="g2" title="财务管理" icon={<TransactionOutlined /> }>
                            <Menu.Item key="/seller/finance/balance">余额管理</Menu.Item>
                            <Menu.Item key="/seller/finance/withdraw-approval">提现审核</Menu.Item>
                            <Menu.Item key="/seller/finance/withdraw-setting">提现设置</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="g3" title="共享股东" icon={<ShareAltOutlined />}>
                            <Menu.Item key="/seller/retail/list"  icon={<IdcardOutlined />}>股东分销身份</Menu.Item>
                            <Menu.Item key="/seller/shareholder/list"  icon={<UsergroupAddOutlined />}>股东管理</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="g4" title="员工拓客" icon={<TeamOutlined />}>
                            <Menu.Item key="/seller/employee/employeeLevel" icon={<IdcardOutlined />}>员工分销身份</Menu.Item>
                            <Menu.Item key="/seller/employee/employeeInfoList" icon={<UsergroupAddOutlined />}>员工拓客管理</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="g5" title="权益卡管理" icon={<CreditCardOutlined />}>
                            <Menu.Item key="/seller/equity/mgr">权益卡模板管理</Menu.Item>
                            <Menu.Item key="/seller/equity/record">推广卡发放记录</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="g6" title="共享商圈管理" icon={<ApartmentOutlined />}>
                            <Menu.Item key="/seller/shareCBD/myCBD" icon={<ShareAltOutlined />}>我的商圈</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="g7" title="微信管理" icon={<WechatOutlined />}>
                            <Menu.Item key="/seller/mini-program" icon={<QrcodeOutlined />}>小程序配置</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="g8" title="用户管理" icon={<UserAddOutlined />}>
                            <Menu.Item key="/seller/user/list" icon={<UserOutlined />}>用户列表</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="g9" title="系统设置" icon={<SettingOutlined />}>
                            <Menu.Item key="/seller/system/settingPay" icon={<PayCircleOutlined />}>支付参数配置</Menu.Item>
                        </Menu.SubMenu>
                    </Menu>
                </div>

                <div className={stretchState === true ? "section active" : "section"}>
                   <MainFrameRoute />
                </div>
            </div>
        );
    }
}
