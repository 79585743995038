import { Form, Input, Modal, Space } from "antd";
import React, {useEffect, useState} from "react";
import Request from "../../../../utils/Request";
interface IProps{
    detail:any,
    visible?:boolean,
    onCancel?:()=>void,
    onReload?:()=>void
}

export const BalanceChargeModal = (props:IProps)=>{
    
    const { detail,visible,onCancel,onReload } = props;


    const [amount,setAmount] = useState();


    useEffect(()=>{
        if(visible){
            setAmount(undefined)
        }
    },[visible]);

    const doRecharge = async ()=>{
        if (!amount) {
            Modal.error({content: '充值金额不能为空'});
            return;
        }

        let param = {userId: detail.userId, amount: amount};
        let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/balance/recharge", param);
        // if (data.code !== 0) {
        //     Modal.error({content: data.message});
        //     return;
        // }
        Modal.success({
            content: data.data,
            onOk: () => {
                onCancel();
                onReload();
            }
        });
    }

    return  (
        <Modal title="余额账户充值" centered visible={visible} width={800} onCancel={() => onCancel()}
                   onOk={() => {
                       doRecharge();
                   }} okText="确定充值">
                <Space>
                    <Form>
                        <Form.Item label="用户ID">
                            {detail?.userId}
                        </Form.Item>
                        <Form.Item label="注册手机号码">
                            {detail?.employeePhone}
                        </Form.Item>
                        <Form.Item label="员工姓名">
                            {detail?.employeeName}
                        </Form.Item>
                        <Form.Item label="员工身份">
                            {detail?.distriSaleRoleLevelConf?.roleName}
                        </Form.Item>
                        <Form.Item label="账户余额">
                            {detail?.balanceAccount?detail?.balanceAccount?.total:'-'}（充值剩余：{detail?.balanceAccount?detail?.balanceAccount?.recharge:'-'}/佣金余额：{detail?.balanceAccount?detail?.balanceAccount?.dividends:'-'}）
                        </Form.Item>
                        <Form.Item>
                            <Input value={amount} onChange={(e:any) => setAmount(e.target.value)} placeholder="输入用户充值的金额"></Input>
                        </Form.Item>
                        <Form.Item>
                            如：仅增加充值余额，如充值100，则输入框写“100“即可；
                        </Form.Item>
                    </Form>
                </Space>
            </Modal>
    )
}