import { Button, message, Modal, Pagination, Space, Switch, Table } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Request from '../../../utils/Request';

export default class ItemList extends React.Component<{},{data:any, total: number}> {
    pageSize = 15;

    constructor(props){
        super(props);
        this.state= {data:'', total:0};
    }

    componentDidMount(){
        this.fetchData();
    }

    async fetchData(currentPage?:number){
        this.setState({data:''});
        let formdata  = new FormData();
        formdata.set('pageNum', (currentPage?currentPage:'1')+'');
        formdata.set('pageSize', this.pageSize+'' );
        let data = await Request.post( process.env.REACT_APP_REMOTE_URL+"/seller/item/listItem", formdata );
        this.setState({data:data.data?.records, total: data.data?.total});
    }

    changePage = (currentPage) =>{
        this.fetchData(currentPage);
    }

    async changeRecommend(checked, itemId){
        let formdata = new FormData();
        formdata.append('itemId', itemId);
        formdata.append('enable', checked+'');
        let data = await Request.post( process.env.REACT_APP_REMOTE_URL+"/seller/item/changeRecommendStatusByItemId", formdata );
        if( !data.error  ){
        //     Modal.error({content:<p>错误, 请刷新</p>});
        // }else{
            message.info(data.data);
        }
    }

    async changeItemStatus(checked, itemId){
        let formdata = new FormData();
        formdata.append('itemId', itemId);
        formdata.append('enable', checked+'');
        let data = await Request.post( process.env.REACT_APP_REMOTE_URL+"/seller/item/changeItemStatusByItemId", formdata );
        if( data.error === true ){
        //     Modal.error({content:<p>错误, 请刷新</p>});
        // }else{
            message.info(data.data);
        }
    }

    async delete(itemId){
        Modal.confirm({content:'确定删除吗? ', onOk:async ()=>{
            let formdata = new FormData();
            formdata.append('itemId', itemId+'');
            let data = await Request.post( process.env.REACT_APP_REMOTE_URL+"/seller/item/deleteItemByItemId", formdata );
            if( data.error === true ){
            //     Modal.error({content:<p>错误, 请刷新</p>});
            // }else{
                message.info(data.data);
                this.fetchData();
            }
        }});
    }

    public render() {

        return (
           <div>
                <h1>商品列表</h1>

                <div style={{textAlign: 'right'}}>
                    <Space>
                        <Link to="/seller/item/add/0"><Button type="primary">新增套餐</Button></Link>
                        <Link to="/seller/item/add/1"><Button type="primary">新增代金券</Button></Link>
                    </Space>
                    <br /><br />
                </div>
                <div>
                {this.state.data&&<Table size="small" dataSource={this.state.data} pagination={false} columns={[
                    {
                        title: '商品名称',
                        render: (record)=>(
                            <Link to={"/seller/item/view/"+record.itemId}>{record.itemName}</Link>
                        )
                    },
                    {
                        title: '售价',
                        dataIndex: 'presentPrice'
                    },
                    {
                        title: '原价',
                        dataIndex: 'originalPrice'
                    },
                    {
                        title: '实际销量',
                        dataIndex: 'itemRealSaleNum'
                    },
                    {
                        title: '核销销量',
                        dataIndex: 'verifyedNum'
                    },
                    {
                        title: '剩余库存',
                        dataIndex: 'stock'
                    },
                    // {
                    //     title: '商品分类',
                    //     dataIndex: ''
                    // },
                    {
                        title: '商品类型',
                        render: (text, record)=>(
                            <span>{record.itemType===0?'套餐':'代金券'}</span>
                            )
                    },
                    {
                        title: '是否推荐',
                        render: (record)=>(
                            <Switch defaultChecked={record.joinAlliance?true:false} onChange={(v)=>this.changeRecommend(v, record.itemId)} />
                        )
                    },
                    {
                        title: '上下架状态',
                        render: (text, record)=>(
                            <Switch defaultChecked={record.itemStatus?true:false} onChange={(v)=>this.changeItemStatus(v, record.itemId)} />
                        )
                    },
                    {
                        title: '添加时间',
                        dataIndex: 'createdTime'
                    },
                    {
                        title: '排序',
                        dataIndex: 'orderNo'
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                          <Space size="middle">
                            <Link to={"/seller/item/edit/"+record.itemId}>更新</Link>
                            <Button onClick={()=>this.delete(record.itemId)} type="link">删除</Button>
                          </Space>
                        ),
                      },
                    ]} />}

                </div>
                <br />
                {this.state.total>0&&<div style={{textAlign: 'right'}}>
                    <Pagination simple pageSize={this.pageSize} onChange={this.changePage} defaultCurrent={1} total={this.state.total} />
                </div>}
           </div>
        );
    }
}
