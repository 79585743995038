import {DeleteOutlined, PlusOutlined, PlusCircleFilled} from '@ant-design/icons';
import {Button, Col, Form, Input, Space, Switch, Tooltip, Upload, Radio, DatePicker, Modal} from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import * as React from 'react';
import {Link} from 'react-router-dom';
import Request from '../../../utils/Request';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import {Editor} from '@tinymce/tinymce-react';
import 'tinymce-i18n/langs5/zh_CN';
import uploadImage from '../../../utils/TinymceUpload';
import {useState} from 'react';
import settings from '../../../settings';
import './AddItem.css';
import moment from 'moment';

export default class AddEditItem extends React.Component<{ history: any, data: any }, { picList: any, existingPicList: any, reserve: boolean, fastShelves:boolean, itemIntroduction: string, joinAlliance: boolean, validityFrom: string, validityTo: string, serviceTag: Array<boolean>, validType: number, commission: number, menulist: any }> {

    constructor(props) {
        super(props);
        this.state = {
            picList: [],
            existingPicList: [],
            reserve: false,
            fastShelves:true,
            validityFrom: '',
            validityTo: '',
            itemIntroduction: '',
            joinAlliance: false,
            validType: 1,
            serviceTag: [false, false],
            commission: 0,
            menulist: []
        };
    };

    componentDidMount() {
        let data:any = this.props.data;

        let serviceTag = [false, false];
        switch (data['serviceTag']) {
            case 0:
                serviceTag = [false, false];
                break;
            case 1:
                serviceTag = [true, false];
                break;
            case 2:
                serviceTag = [false, true];
                break;
            case 3:
                serviceTag = [true, true];
                break;
        }

        if (data.id) {
            this.setState({
                reserve: data['reserve'] ? true : false,
                fastShelves:data['fastShelves']==1?true:false,
                validityFrom: data['validityFrom'] ? data['validityFrom'] : '',
                validityTo: data['validityTo'] ? data['validityTo'] : '',
                itemIntroduction: data['itemIntroduction'],
                joinAlliance: data['joinAlliance'] ? true : false,
                validType: data['validateType'],
                serviceTag: serviceTag,
                menulist: data['menuInfo'],
                existingPicList: data['itemPic'],
            });
            this.commissionData = [data['presentPrice'], data['custCommissionRatio']];
            this.calculateCommission();
        }
    }

    onUploadChange = ({fileList}) => {
        this.setState({picList: fileList});
    }

    onServiceTagChange = (checked: boolean, index: number) => {
        let serviceTag = this.state.serviceTag;
        serviceTag[index] = checked;
        this.setState({serviceTag: serviceTag});
    }

    submit = async (values) => {
        let data = {itemType: this.props.data.itemType};
        if (this.props.data.id) {
            data['id'] = this.props.data.id;
        }
        console.log(data);
        for (let key of Object.keys(values)) {
            if (values[key] !== undefined) {
                data[key] = values[key];
            }
        }

        let itemPic = [];
        for (let pic of this.state.picList) {
            itemPic.push(pic.response.data);
        }
        itemPic = [...this.state.existingPicList, ...itemPic];
        data['itemPic'] = itemPic;
        data['validateType'] = this.state.validType;
        if (data['validateType'] === 1) {
            data['validityFrom'] = this.state.validityFrom ? this.state.validityFrom : '';
            data['validityTo'] = this.state.validityTo ? this.state.validityTo : '';
        }
        data['custLimit'] = data['custLimit'] === undefined ? 0 : data['custLimit']

        let serviceTag = 0;
        if (this.state.serviceTag[0]) {
            serviceTag = this.state.serviceTag[1] ? 3 : 1
        } else {
            serviceTag = this.state.serviceTag[1] ? 2 : 0
        }
        data['serviceTag'] = serviceTag;
        data['reserve'] = this.state.reserve ? 1 : 0;
        if (data['reserve'] === 0) {
            data['aheadTimeBeforeAppoint'] = '';
        }
        data['fastShelves'] = this.state.fastShelves==true?1:0;
        data['joinAlliance'] = data['joinAlliance'] ? 1 : 0;
        if (data['joinAlliance'] === 0) {
            data['shopCommissionRatio'] = '';
            data['shopCommissionMax'] = '';
            data['leagueLeaderCommissionRatio'] = '';
            data['leagueLeaderCommissionMax'] = '';
        }
        data['menuInfo'] = this.state.menulist;
        data['itemIntroduction'] = this.state.itemIntroduction;
        let result = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/item/addOrUpdateItem", data);

        if (result.data === true) {
            Modal.info({
                content: '提交成功, 请点击返回列表', okText: '返回', onOk: () => {
                    this.props.history.push('/seller/item/list');
                }
            })
        }
        // else {
        //     if (!result.success) {
        //         Modal.error({content: result.message});
        //         return;
        //     }
        // }
        console.log(result);
    }

    deleteExistingPic(index) {
        let picList = this.state.existingPicList;
        picList.splice(index, 1);
        this.setState({existingPicList: picList});
    }

    commissionData = [0, 0];

    updateCommission(value, type) {
        if (type === 0) {
            this.commissionData[0] = parseInt(value);
        }
        if (type === 1) {
            this.commissionData[1] = parseInt(value);
        }
        this.calculateCommission();
    }

    calculateCommission() {
        let commission = this.commissionData[0] * this.commissionData[1] / 100
        this.setState({commission: commission});
    }

    render() {
        let smallWidth = {style: {width: '150px'}};
        let type = this.props.data?.itemType;

        let required = {require: true, rules: [{required: true, message: '请输入..'}]};
        let data = this.props.data;

        return <div>
            <Link to="/seller/item/list"><Button type="link">返回</Button></Link>
            <Form {...settings.FormLayout} initialValues={data} onFinish={this.submit}>
                <Form.Item label={data.itemType==0?"商品名称":"代金券名称"} name="itemName" {...required}>
                    <Input placeholder={data.itemType==0?"商品名称":"代金券名称"}/>
                </Form.Item>

                {type === 1 && <Form.Item label="代金券金额" name="couponFee" {...required}>
                    <Input placeholder="代金券金额" type="number" {...smallWidth} />
                </Form.Item>}

                {type === 0 && <><Form.Item label="商品相册">
                        {this.state.existingPicList.map((path, index) =>
                            <Col><img src={data.prefix + path} style={{maxWidth: '200px'}} alt="none"/>
                                <Button type="link"
                                        onClick={() => this.deleteExistingPic(index)}><DeleteOutlined/></Button>
                            </Col>
                        )}
                        <Upload
                            headers={{Authorization: sessionStorage.getItem('token')}}
                            data={{fullAddress: 'false'}}
                            action={process.env.REACT_APP_REMOTE_URL + '/seller/item/itemMaterialsUpload'}
                            fileList={this.state.picList}
                            onChange={this.onUploadChange}
                            listType="picture-card">
                            <div><PlusOutlined style={{fontSize: '28px'}}/></div>
                        </Upload>
                        <div className="tip">显示在商品详情页中，第一张默认为商品列表缩略图，建议尺寸 ：750*450</div>
                    </Form.Item>

                    <MenuList
                        menulist={this.state.menulist}
                        key={this.state.menulist}
                        onChange={menulist => this.setState({menulist: menulist})}/>
                </>}
                {data.itemType==0&&<Form.Item label="商品介绍" required={true}>
                    <Editor
                        value={this.state.itemIntroduction}
                        onEditorChange={(content) => this.setState({itemIntroduction: content})}
                        init={{
                            skin: false,
                            language: 'zh_CN',
                            content_css: false,
                            plugins: 'lists table code image link fullscreen',
                            height: 300,
                            images_upload_handler: function (blobInfo, success, failure, progress) {
                                uploadImage(blobInfo, success, failure, progress, process.env.REACT_APP_REMOTE_URL + '/seller/item/itemMaterialsUpload')
                            },
                            toolbar: ['bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent removeformat | fullscreen code',
                                'fontsizeselect forecolor formatselect backcolor | table link image'],
                            menubar: false
                        }}/>
                </Form.Item>}

                {type==0&&<Form.Item label="购买须知" name="notice" {...required}>
                    <TextArea rows={8} placeholder="请填写套餐使用说明"/>
                </Form.Item>}

                <Form.Item label="销售价格" required={true}>
                    <Space>
                        <Form.Item name="presentPrice" {...required}>
                            <Input onChange={e => this.updateCommission(e.target.value, 0)} type="number"
                                   placeholder="商品销售价" {...smallWidth} />
                        </Form.Item>
                        <span className="tip">商品销售价格，如为多规格时，将会显示最低价格，前端显示为xx元起</span>
                    </Space>
                </Form.Item>
                <Form.Item label="划线价" name="originalPrice"  {...required}>
                    <Input type="number" placeholder="商品划线价" {...smallWidth} />
                </Form.Item>

                <Form.Item label={type==0?"套餐库存":"库存"} required={true}>
                    <Space>
                        <Form.Item name="stock" {...required}>
                            <Input type="number" placeholder={type==0?"套餐库存":"库存"} {...smallWidth}/>
                        </Form.Item>
                        <span className="tip">多规格时将会显示规格库存之和</span>
                    </Space>
                </Form.Item>

                <Form.Item label="每人限购">
                    <Space>
                        <Form.Item name="custLimit" noStyle>
                            <Input type="number" placeholder="每人限购次数" defaultValue="0" {...smallWidth} />
                        </Form.Item>
                        <Tooltip title=""><span className="tip">0为不限制，最大不可大于最大库存数</span></Tooltip>
                    </Space>
                </Form.Item>

                <Form.Item label="使用有效期">
                    <Radio.Group value={this.state.validType}
                                 onChange={(e) => this.setState({validType: e.target.value})}>
                        <Space direction="vertical">
                            <Radio value={1}>
                                <Space direction="vertical">
                                    固定时间
                                    <Space>
                                        <DatePicker
                                            value={this.state.validityFrom ? moment(this.state.validityFrom) : null}
                                            onSelect={(v) => this.setState({validityFrom: v.format('YYYY-MM-DD 00:00:00')})}
                                            disabled={this.state.validType !== 1} placeholder="开始时间"/>
                                        <DatePicker value={this.state.validityTo ? moment(this.state.validityTo) : null}
                                                    onSelect={(v) => this.setState({validityTo: v.format('YYYY-MM-DD 23:59:59')})}
                                                    disabled={this.state.validType !== 1} placeholder="结束时间"/>
                                    </Space>
                                </Space>
                            </Radio>
                            <Radio value={2}>
                                <Space direction="vertical">
                                    购买后
                                    <Space><Form.Item name="validityDate" noStyle>
                                        <Input disabled={this.state.validType !== 2}/>
                                    </Form.Item>天内可用</Space>
                                </Space>
                            </Radio>
                        </Space>
                    </Radio.Group> <br/><br/>
                    <Form.Item name="timeRemarks" label="有效性备注" {...required}>
                        <Input placeholder="请输入有效期备注，表明不可用时间，或者节假日通用"/>
                    </Form.Item>
                </Form.Item>

                <Form.Item label="使用须知" name="useNotice" {...required}>
                    <Input placeholder="请输入使用范围，如：全店通用，酒水饮料不含"/>
                </Form.Item>

                <Form.Item label="服务标签">
                    <Form.Item>
                        <Checkbox checked={this.state.serviceTag[0]}
                                  onChange={(checked) => this.onServiceTagChange(checked.target.checked, 0)}>随时退
                            （有效期内的订单用户可以随时发起退款申请）</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Checkbox checked={this.state.serviceTag[1]}
                                  onChange={(checked) => this.onServiceTagChange(checked.target.checked, 1)}>过期退
                            （券码过期后用户依然可以申请退款）</Checkbox>
                    </Form.Item>
                </Form.Item>

                <Form.Item label="提前预约">
                    <Form.Item>
                        <Switch checked={this.state.reserve} onChange={(v) => this.setState({reserve: v})}/> <span
                        className="tip">默认无需提前预约，前台将显示 免预约 的标签。</span>
                    </Form.Item>

                    <Space>
                        <span>需提前</span>
                        <Form.Item name="aheadTimeBeforeAppoint" noStyle {...(this.state?.reserve? required : {})}>
                            <Input defaultValue={data?.aheadTimeBeforeAppoint} disabled={!this.state?.reserve} type="number" {...smallWidth}/>
                        </Form.Item>
                        小时预约
                    </Space>
                </Form.Item>

                <Form.Item label="是否立即上架" name="fastShelves">
                    <Switch checked={this.state?.fastShelves } onClick={(e)=>{
                       this.setState({
                           fastShelves:e
                       })
                    }}/>
                </Form.Item>

                <Form.Item label="顾客分享佣金">
                    <Space>
                        <Form.Item name="custCommissionRatio" {...required}>
                            <Input onChange={e => this.updateCommission(e.target.value, 1)} type="number" max={100}
                                   min={0} {...smallWidth} />
                        </Form.Item>
                        <Form.Item><span>%</span></Form.Item>
                        <Form.Item><Space>佣金金额: <Input disabled value={this.state.commission} {...smallWidth} /></Space></Form.Item>
                    </Space>
                </Form.Item>

                <Form.Item  label="是否加入共享商圈" name="joinAlliance">
                    <Switch onChange={(e)=>this.setState({joinAlliance: e})} checked={this.state.joinAlliance} />
                </Form.Item>

                {/*
                <Form.Item label="最大佣金金额" name="custCommissionMax" {...required}>
                        <Input type="number" {...smallWidth} />
                </Form.Item>

                <Form.Item label="是否加入共享商圈">
                    <Form.Item name="joinAlliance">
                        <Switch onChange={(e)=>this.setState({joinAlliance: e})} checked={this.state.joinAlliance} />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                        <Form.Item label="异店推广佣金" name="shopCommissionRatio">
                            <Input disabled={!this.state.joinAlliance} {...smallWidth} />
                        </Form.Item>
                        <Form.Item>%</Form.Item>
                        <Form.Item label="最大佣金金额" name="shopCommissionMax">
                            <Input  disabled={!this.state.joinAlliance} {...smallWidth} />
                        </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item>
                        <Space>
                        <Form.Item label="联盟盟主佣金" name="leagueLeaderCommissionRatio">
                            <Input  disabled={!this.state.joinAlliance} {...smallWidth} />
                        </Form.Item>
                        <Form.Item>
                            %
                        </Form.Item>
                        <Form.Item label="最大佣金金额" name="leagueLeaderCommissionMax">
                            <Input  disabled={!this.state.joinAlliance} {...smallWidth} />
                        </Form.Item>
                        </Space>
                    </Form.Item>



                </Form.Item> */}

                <Form.Item label="商品排序序号" name="orderNo" {...required}>
                    <Input placeholder="数值越大排序越靠前" type="number" {...smallWidth} />
                </Form.Item>


                <Form.Item wrapperCol={{offset: 4}}>
                    <br/>
                    <Button type="primary" htmlType="submit">提交</Button>
                    <Link to="/seller/item/list"><Button type="link">返回</Button></Link>
                </Form.Item>
            </Form>
        </div>;
    }
}

function MenuList(props: { menulist: any, onChange: any }) {
    const defaultMenu = () => {
        return {categoryName: '', items: [{itemName: '', 'num': 1, 'price': ''}]};
    }

    const [menulist, setMenulist] = useState(props.menulist.length > 0 ? props.menulist : [defaultMenu()]);

    const addMenu = (e) => {
        setMenulist([...menulist, defaultMenu()]);
        props.onChange(menulist)
    }

    const addItem = (index) => {
        let menu = menulist[index];
        let items = menu.items;
        items = [...items, ...defaultMenu()['items']];
        menu.items = items;
        menulist[index] = menu;
        setMenulist(menulist);
        props.onChange(menulist)
    }

    const deleteItem = (index, index2) => {
        let menu = menulist[index];
        menu.items.splice(index2, 1);
        setMenulist(menulist);
        props.onChange(menulist)
    }

    const onMenuChange = (e, index) => {
        menulist[index]['categoryName'] = e.target.value;
        setMenulist(menulist);
        props.onChange(menulist)
    }

    const onMenuItemChange = (e, index: number, itemIndex: number, field: string) => {
        menulist[index].items[itemIndex][field] = e.target.value;
        setMenulist(menulist);
        props.onChange(menulist)
    }

    const deleteMenu = (index) => {
        menulist.splice(index, 1);
        setMenulist(menulist);
        props.onChange(menulist)
    }

    return <Form.Item label="套餐商品" required={true}>
        <Space direction="vertical">
            {menulist.map((menu, index) =>
                <div>
                    <Space direction="vertical">
                        <Input onChange={e => onMenuChange(e, index)} placeholder="套餐名称, 只有一种套餐时不显示"
                               defaultValue={menu.categoryName}/>
                        {menu.items.map((item, itemIndex) => <Space>
                            <Input onChange={e => onMenuItemChange(e, index, itemIndex, 'itemName')}
                                   defaultValue={item.itemName} placeholder="商品名称"/>
                            <Input type="number" onChange={e => onMenuItemChange(e, index, itemIndex, 'num')}
                                   defaultValue={item.num} placeholder="份数"/>
                            <Input type="number" onChange={e => onMenuItemChange(e, index, itemIndex, 'price')}
                                   defaultValue={item.price} placeholder="门市价"/>
                            <Button type="link" onClick={() => deleteItem(index, itemIndex)}><DeleteOutlined/></Button>
                            {itemIndex === menu.items.length - 1 &&
                            <Button type="link" onClick={() => addItem(index)}><PlusCircleFilled/>添加商品</Button>}
                        </Space>)}
                    </Space>
                    <div>
                        <Button type="link" onClick={() => deleteMenu(index)}><DeleteOutlined/>删除本套餐</Button>
                        <br/> <br/>
                    </div>
                </div>)}
        </Space>
        <div>
            <Button type="link" onClick={addMenu}><PlusCircleFilled/>添加套餐</Button>
        </div>
    </Form.Item>;
}
