import React from "react";
import Request from '../../../utils/Request';
import AddEditItem from "./AddEditItem";


export default class EditItem extends React.Component<{match:{params:any}, history:any},{data:any}> {
    constructor(props){
        super(props);
        this.state= {
            data:{}
        };
    }

    componentDidMount(){
        this.fetchData();
    }


    async fetchData(filter?:any){
        let data = await Request.get( process.env.REACT_APP_REMOTE_URL+"/seller/item/getItemInfoByItemId?itemId=" + this.props.match.params?.itemId );
        this.setState({data:data?.data});
    }

    render(){
        // let type=this.state.data.itemType;

        return <div>
            <h1>更新{this.state.data?.itemType===0?'套餐':''}{this.state.data?.itemType===1?'代金券':''}  </h1>
            <AddEditItem key={this.state.data?.id} history={this.props.history} data={this.state.data} />
        </div>
    }

}
