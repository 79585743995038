import React from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Modal,
  Row,
  Col,
  Table,
  Space,
  Radio,
  Input,
  message,
} from "antd";
import Request from "../../../utils/Request";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons/lib";
import settings from "../../../settings";

export default class ViewWithdraw extends React.Component<
  { match: { params: any }; location: any },
  {
    data: any;
    auditVisible: boolean;
    isAuditPass: boolean;
    whetherPayVisible: boolean;
    isWhetherPayPass: boolean;
  }
> {
  statusObj = {
    1: "待审核",
    2: "已审核待打款",
    3: "已驳回",
    4: "已完成",
    5: "打款失败",
  };
  userTypeObj = { 1: "顾客提现", 2: "异业商家提现" };

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      auditVisible: false,
      isAuditPass: false,
      isWhetherPayPass: false,
      whetherPayVisible: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init() {
    this.getDetail();
  }

  async getDetail() {
    let data = await Request.get(
      process.env.REACT_APP_REMOTE_URL +
        "/seller/balance/withdraw/getWithdrawDetailByFlowId?flowId=" +
        this.props.match.params.flowId
    );
    this.setState({ data: data.data });
  }

  submit = async (values) => {
    console.log(values);
  };

  //审核
  doPass = async (flag) => {
    let params = {
      flowIds: [this.state.data.flowId],
      whetherPass: flag,
    };
    // if(flag==false){
    //     params["rejectReason"]=this.state.failReasonIfNotProved;
    // }
    let reap = await Request.jsonPost(
      process.env.REACT_APP_REMOTE_URL + "/seller/balance/withdraw/checkWithdrawApply",
      { ...params }
    );
    console.log(reap);
    if (reap.code == 0) {
      Modal.success({
        content: "操作成功",
        onOk: () => {
          //TODO go to list
        },
      });
    }
  };

  doAuditSubmit = async (e) => {
    const url = process.env.REACT_APP_REMOTE_URL + "/seller/balance/withdraw/checkWithdrawApply";
    let data = await Request.jsonPost(url, {
      ...e,
      flowIds: [this.state.data.flowId],
      withdrawUserType:this.state.data.withdrawUserType
    });
    if (data.code == 0) {
      message.success("操作成功");
      this.init();
      this.setState({ auditVisible: false, isAuditPass: false });
      return;
    }
  };
  doWetherPayAuditSubmit = async (e) => {
    const url = process.env.REACT_APP_REMOTE_URL + "/seller/balance/withdraw/confirmWithdrawPay";
    let data = await Request.jsonPost(url, {
      ...e,
      flowIds: [this.state.data.flowId],
    });
    if (data.code == 0) {
      message.success("操作成功");
      this.init();
      this.setState({ whetherPayVisible: false, isWhetherPayPass: false });
      return;
    }
  };

  render() {
    let data = this.state.data;
    if (Object.keys(data).length === 0) {
      return (
        <div>
          <LoadingOutlined />
        </div>
      );
    }
    return (
      <div>
        <div
          style={{
            display: "flex",
            lineHeight: "30px",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h1>提现详情</h1>
          </div>
          <div>
            {/*<Button type="primary">返回</Button>*/}
            <Link
              to={
                "/seller/finance/withdraw-approval" + this.props.location.search
              }
            >
              返回
            </Link>
          </div>
        </div>
        <Divider />

        <Card type="inner" title="基本信息">
          <Form {...settings.FormLayout}>
            <Row>
              <Col span={12}>
                <Form.Item label="提现流水号">{data.flowId}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="提现状态">
                  {this.statusObj[data.withdrawStatus]}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="提现人">{data.userNick}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="提现人类型">
                  {this.userTypeObj[data.withdrawUserType]}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="提现金额">{data.amount}元</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="提现手续费率">
                  {data.withdrawConf.serviceFee4customer}%
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="提现手续费">
                  {(data.amount * data.withdrawConf.serviceFee4customer) / 100}
                  元
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="到账金额">{data.arriveAmount}元</Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>

        <Card type="inner" title="提现方式信息">
          <Form {...settings.FormLayout}>
            <Row>
              <Col span={12}>
                <Form.Item label="提现方式">银行卡</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="持卡人姓名">{data.bankUserName}</Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="所属银行">{data.bankName}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="银行卡号">
                  {data.bankNo}
                  <Button type="link">复制</Button>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="所在地区">{data.bankAddress}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="开户行">{data.bankBranchName}</Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="身份证号">{data.idNo}</Form.Item>
              </Col>
            </Row>
            <Row>
              {this.state.data.withdrawStatus === 1 && (
                <Col span={12}>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({ auditVisible: true });
                    }}
                  >
                    审核
                  </Button>
                  {/*<Button type="primary" style={{marginLeft: "15px"}} onClick={()=>{this.doPass(false)}}>审核拒绝</Button>*/}
                </Col>
              )}
              {(this.state.data.withdrawStatus === 2 ||
                this.state.data.withdrawStatus === 3) && (
                <Col span={12}>
                  <Button
                    onClick={() => {
                      this.setState({ whetherPayVisible: true });
                    }}
                    type="primary"
                  >
                    打款审核
                  </Button>
                </Col>
              )}
              <Col span={12}></Col>
            </Row>
          </Form>
        </Card>

        <Card type="inner" title="日志记录">
          <Table
            size="small"
            dataSource={this.state.data.withdrawLogs}
            pagination={false}
            rowKey="id"
            columns={[
              {
                title: "操作时间",
                dataIndex: "modifiedTime",
              },
              {
                title: "操作内容",
                dataIndex: "operateContent",
              },
              {
                title: "操作人",
                dataIndex: "operatorName",
              },
            ]}
          />
        </Card>

        <Modal
          title="提现审核"
          centered
          visible={this.state.auditVisible}
          footer={null}
          width={400}
          destroyOnClose
          onCancel={() => {
            this.setState({ auditVisible: false });
          }}
          okText="保存"
          cancelText="返回"
        >
          <Space>
            <Form
              onValuesChange={(e) => {
                console.log(e);
                if (e.hasOwnProperty("whetherPass")) {
                  this.setState({ isAuditPass: e.whetherPass });
                }
              }}
              onFinish={(e) => {
                this.doAuditSubmit(e);
                // console.log('submit')
              }}
            >
              <Form.Item
                name="whetherPass"
                rules={[{ required: true, message: "请选择是否通过" }]}
              >
                <Radio.Group>
                  <Radio value={true}>审核通过</Radio>
                  <Radio value={false}>审核拒绝</Radio>
                </Radio.Group>
              </Form.Item>
              {this.state.isAuditPass == false && (
                <Form.Item
                  name="rejectReason"
                  rules={[{ required: true, message: "请填写拒绝原因" }]}
                >
                  <Input placeholder="请填写拒绝原因"></Input>
                </Form.Item>
              )}
              <Button htmlType="submit" type="primary">
                提交
              </Button>
            </Form>
          </Space>
        </Modal>

        <Modal
          title="确认打款"
          centered
          visible={this.state.whetherPayVisible}
          footer={null}
          width={400}
          destroyOnClose
          onCancel={() => {
            this.setState({ auditVisible: false });
          }}
          okText="保存"
          cancelText="返回"
        >
          <Space>
            <Form
              onValuesChange={(e) => {
                console.log(e);
                if (e.hasOwnProperty("whetherSuccess")) {
                  this.setState({ isWhetherPayPass: e.whetherSuccess });
                }
              }}
              onFinish={(e) => {
                this.doWetherPayAuditSubmit(e);
                // console.log('submit')
              }}
            >
              <Form.Item
                name="whetherSuccess"
                rules={[{ required: true, message: "请选择是否通过" }]}
              >
                <Radio.Group>
                  <Radio value={true}>打款通过</Radio>
                  <Radio value={false}>打款拒绝</Radio>
                </Radio.Group>
              </Form.Item>
              {this.state.isWhetherPayPass == false && (
                <Form.Item
                  name="failedReason"
                  rules={[{ required: true, message: "请填写拒绝原因" }]}
                >
                  <Input placeholder="请填写拒绝原因"></Input>
                </Form.Item>
              )}
              <Button htmlType="submit" type="primary">
                提交
              </Button>
            </Form>
          </Space>
        </Modal>

        {/*/!*提现审核*!/*/}
        {/*<Modal title="提现审核" centered visible={this.state.showAudit} width={400} onCancel={this.closeAudit} onOk={this.doPass} okText="保存" cancelText="返回">*/}
        {/*    <Space>*/}
        {/*        <Form>*/}
        {/*            <Form.Item>*/}
        {/*                <Radio.Group onChange={this.onCheckChange} value={this.state.checkedForm.whetherPass}>*/}
        {/*                    <Radio value={true}>审核通过</Radio>*/}
        {/*                    <Radio value={false}>审核拒绝</Radio>*/}
        {/*                </Radio.Group>*/}
        {/*            </Form.Item>*/}
        {/*            <Form.Item>*/}
        {/*                <Input placeholder="请填写拒绝原因" disabled={this.state.checkedForm.whetherPass}*/}
        {/*                       value={this.state.checkedForm.rejectReason}*/}
        {/*                       onChange={this.onChangeRejectReason}></Input>*/}
        {/*            </Form.Item>*/}
        {/*        </Form>*/}
        {/*    </Space>*/}
        {/*</Modal>*/}

        {/*<Modal title="打款确认" centered visible={this.state.visible} width={400} onCancel={this.closeModal} onOk={this.doPass} okText="保存" cancelText="返回">*/}
        {/*    <Space>*/}
        {/*        <Form>*/}
        {/*            <Form.Item>*/}
        {/*                <Radio.Group onChange={this.onCheckChange} value={this.state.checkedForm.whetherPass}>*/}
        {/*                    <Radio value={true}>已确认打款</Radio>*/}
        {/*                    <Radio value={false}>打款失败</Radio>*/}
        {/*                </Radio.Group>*/}
        {/*            </Form.Item>*/}
        {/*            <Form.Item>*/}
        {/*                <Input placeholder="请填写失败原因" disabled={this.state.checkedForm.whetherPass}*/}
        {/*                       value={this.state.checkedForm.rejectReason}*/}
        {/*                       onChange={this.onChangeRejectReason}></Input>*/}
        {/*            </Form.Item>*/}
        {/*        </Form>*/}
        {/*    </Space>*/}
        {/*</Modal>*/}
      </div>
    );
  }
}
