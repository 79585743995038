import React, { useState, useEffect } from 'react';
import { Button, Divider, Modal, Table,Form,Popconfirm } from "antd";
import Request from "../../../utils/Request";
import {log} from "util";

const EquityMgr=()=>{
    const columns:any = [
        {
            title: '权益卡名称',
            key: 'cardModuleName',
            align:'center',
            render: (item) => (
                <div>{item.cardModuleName} {item.isForPromotion===1&&<span style={{color:"#ff4433"}}>【推广】</span>}</div>
            ),
        },
        {
            title: '折扣比例',
            key: 'discountRatio',
            align:'center',
            render: (item) => (
                <div>{item.discountRatio/10}折</div>
            ),
        },
        {
            title: '权益卡背景',
            key: 'cardPictPath',
            align:'center',
            render: (item) => (
                <img src={`${item.prefix}${item.cardPictPath}`} alt="权益卡背景" width="200" height="100" style={{borderRadius:"10px"}}/>
            ),
        },
        {
            title: '更新时间',
            dataIndex: 'modifiedTime',
            key: 'modifiedTime',
            align:'center'
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            render: (item,record) => (
                <div>
                   <Button type="link" href={"#/seller/equity/mgr/edit/" + item.id} size={'small'}>编辑</Button>
                   {item.isForPromotion === 0&&<Popconfirm title="确认设为推广卡?" onConfirm={(item) => doConfiguration(record)}>
                       <a>设为推广卡</a>
                   </Popconfirm>}
                </div>
            ),
        }
    ];
    const [dataSource,setDataSource]=useState<any>([]);

    useEffect(()=>{
        initTable();
    },[]);

    //获取列表信息
    const initTable=async ()=>{
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/listEquityCardModules");
        if(res.code==0){
            setDataSource(res.data);
        }
        // else{
        //     Modal.error({ content: res.message });
        //     return;
        // }
    };
    //设为推广卡
    const doConfiguration=async (item)=>{
        console.log(item)
        let params={
            cardId:item.id,
            enable:true
        };
        let res = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + `/seller/equity/enablePromote`,params);
        if(res.code==0){
            Modal.success({ content: res.data });
            initTable();
        }
        // else{
        //     Modal.error({ content: res.message });
        //     return;
        // }
    };
    return (
        <div>
            <h1>权益卡配置</h1>
            <Divider />
            <Button type="primary" href={"#/seller/equity/mgr/add/"} size={'large'} style={{margin:"0px 0 20px"}}>添加权益卡</Button>
            <Table size={"middle"} dataSource={dataSource} columns={columns} pagination={false} scroll={{ y: 600 }} />
        </div>
    );
}
export default EquityMgr;
