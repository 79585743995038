import * as React from 'react';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import 'tinymce-i18n/langs5/zh_CN';
import './AddItem.css';
import AddEditItem from './AddEditItem';

export default class AddItem extends React.Component<{match:{params:any}, history:any},{}> {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        let type = parseInt(this.props.match.params.type);

        return (
            <div>
                <h1>添加{type===0?'套餐':''}{type===1?'代金券':''}  </h1>
                <AddEditItem data={{itemType:type}} history={this.props.history} />
            </div>
        );
    }
}
