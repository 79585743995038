import {LoadingOutlined } from '@ant-design/icons';
import * as React from 'react';
import Request from '../../utils/Request';
import './MiniprogramManagement.css';
import not_auth from '../../assets/images/not_auth.png';
import authed from '../../assets/images/authed.png';
import { Button, Modal } from 'antd';

export default class MiniprogramManagement extends React.Component<{},{data?:string, info:any}> {
    constructor(props){
        super(props);
        this.state= {data:'', info:''};
    }

    componentDidMount(){
        this.fetchData();
    }

    refresh = e => {
        this.setState({data:''});
        this.fetchData();
    };

    async fetchData(){
        let info = await Request.get( process.env.REACT_APP_REMOTE_URL+"/seller/info");
        let appId = info.data?.wxAppid;

        if( appId ){
            let data = await Request.get(process.env.REACT_APP_REMOTE_URL+"/weixin/open/preAuthUrl?mobile=true&appId="+appId);
            this.setState({info:info, data:data});
        }
    }

    public render() {

        return (
           <div>
                <h2>小程序授权</h2>
                <div className="qr">
                    <div>
                    {this.state.data&&<img src={this.state.data}  alt="none"/>}
                    {!this.state.data&&<LoadingOutlined style={{fontSize: '24px'}} />}
                    </div>
                    <Button onClick={this.refresh} type="link">刷新</Button>
                    <p className="desc">请使用申请小程序的微信账号进行小程序授权</p>
                </div>
                <div>
                  未绑定 <img className="icon" src={not_auth}  alt="none"/> <br />
                  已绑定 <img className="icon" src={authed}  alt="none"/>
                </div>
           </div>
        );
    }
}
