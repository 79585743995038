import { Button, Form, Input, Modal, Select, Space, Table } from "antd";
import React, {useRef} from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Request from "../../../../utils/Request";


interface IProps{
    detail?:any,
    roleLevelArr?:any
}

export const UnConfirmTable = (props:IProps)=>{

    let smallWidth = {style: {width: '150px'}};
    const UnconfirmColumn:any=[
        {
            title: '用户ID',
            dataIndex: 'userId',
            align:"center",
        },
        {
            title: '用户昵称',
            render: (record:any) => (
                <span>{record?.userInfo?.userNick}</span>
            ),
            align:"center"
        },
        {
            title: '股东姓名',
            dataIndex: 'shareholderName',
            align:"center"
        },
        {
            title: '手机号码',
            dataIndex: 'shareholderPhone',
            align:"center"
        },
        {
            title: '申请时间',
            dataIndex: 'createdTime',
            align:"center"
        },
        {
            title: '操作',
            key: 'action',
            align:"center",
            width: 300,
            render: (text:any, record:any) => (
                <Space size="middle">
                    <Link to={"/seller/shareholder/settingShareholder/" + record.userId}>确认</Link>
                </Space>
            )
        },
    ];


    // 获取table数据
    const getTableData = async (currentPage:number)=>{
        let formData = searchForm.current;
        searchForm.current.pageNum = currentPage;
        if (currentPage) {
            formData.pageNum = currentPage;
        }

        let data = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/shareholder/listShareholderInCondition",formData);
        // if (data.code !== 0) {
        //     Modal.error({content: data.message});
        //     return;
        // };
        setDataSource(data.data.records)
        setTotal(data.data.total)
    }

    useEffect(()=>{
        getTableData(1);
    },[])


    const searchForm = useRef({
        userId: '',
        phone: '',
        status: 1,
        pageNum: 1,
        pageSize: 1,
    });

    const [balanceVisible,setBalanceVisible] = useState(false);
    const [confirmCardVisible,setConfirmCardVisible] = useState(false);
    const [dataSource,setDataSource] = useState([]);
    const [total,setTotal] = useState(0);
    const [currentTableItem,setCurrentTableItem]  =   useState();

    const submit = (values?:any)=>{
        searchForm.current = {...searchForm.current, ...values};
        getTableData(1)
    }

     return (
        <>
            <Form onFinish={(e:any)=>{ submit(e) }}>
                <Space>
                    <Form.Item label="用户ID" name="userId">
                        <Input {...smallWidth} />
                    </Form.Item>
                    <Form.Item label="手机号码" name="phone">
                        <Input {...smallWidth} />
                    </Form.Item>
                    <Form.Item style={{paddingLeft: '110px'}}>
                        <Button type="primary" htmlType="submit">查询</Button>
                    </Form.Item>
                </Space>
            </Form>
            <Table
                    size="small"
                    dataSource={dataSource}
                    pagination={ {onChange:(page:number)=>{ getTableData(page)   },pageSize:searchForm.current.pageSize,total:total } }
                    rowKey="id"
                    columns={ UnconfirmColumn } />
           </>)
}