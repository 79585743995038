import {LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Switch} from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import Request from '../../../utils/Request';
import './ViewItem.css';

export default class ViewItem extends React.Component<{match:{params:any}},{data:any}> {
    constructor(props){
        super(props);
        this.state= {data:''};
    }

    componentDidMount(){
        this.fetchData();
    }


    async fetchData(filter?:any){
        let data = await Request.get( process.env.REACT_APP_REMOTE_URL+"/seller/item/getItemInfoByItemId?itemId=" + this.props.match.params.itemId );
        this.setState({data:data.data});
    }

    public render() {
        let data = this.state.data;
        if( !data ){
            return <LoadingOutlined />
        }
        let type=data.itemType;

        return (
           <div>
                <h1>查看商品 <Link to="/seller/item/list"><Button type="link">返回</Button></Link></h1>
                <Form {...settings.FormLayout}>
                    <Row>
                        <Col span={12}>
                    <Form.Item label="商品名称">
                        {data.itemName}
                    </Form.Item>
                    {type===1&&<Form.Item label="代金券金额">
                        {data.couponFee}
                    </Form.Item>}

                    {type===0&&<><Form.Item label="商品相册">
                        <Row>
                        {data.itemPic.map( (url) => <Col><img src={data.prefix+url} style={{maxWidth: '200px'}} alt="none"/></Col> )}
                        </Row>
                    </Form.Item>
                    <Form.Item label="套餐商品">
                        {data.menuInfo.map((menu)=><div>
                            <h3>{menu.categoryName}</h3>
                            <table>

                            {menu.items.map((menuitem)=>
                                <tr>
                                <td>{menuitem.itemName}</td>
                                <td>{menuitem.num}</td>
                                <td>{menuitem.price}</td>
                            </tr>)}
                            </table>
                        </div>)}
                    </Form.Item>
                    </>}

                    <Form.Item label="商品介绍">
                        <div className="item-introduction" dangerouslySetInnerHTML={{__html:data.itemIntroduction}} />
                    </Form.Item>
                    <Form.Item label="购买须知">
                        {data.notice}
                    </Form.Item>
                    <Form.Item label="销售价格">
                        {data.presentPrice}
                    </Form.Item>
                    <Form.Item label="划线价">
                        {data.originalPrice}
                    </Form.Item>
                    <Form.Item label="套餐库存">
                        {data.stock}
                    </Form.Item>
                    </Col>
                        <Col span={12}>

                    <Form.Item label="每人限购">
                        {data.custLimit}
                    </Form.Item>
                    <Form.Item label="使用有效期">
                        {data.validateType===1&&<div>
                                固定时间 <br /> {data.validityFrom} ~ {data.validityTo}
                        </div>}
                        {data.validateType===2&&<div>购买后{data?.validityDate} 天内可用</div>}

                        <div className="tip">{data.timeRemarks}</div>
                    </Form.Item>
                    <Form.Item label="使用须知">
                        {data.useNotice}
                    </Form.Item>
                    <Form.Item label="服务标签">
                        <Space direction="vertical">
                            <div><Input type="checkbox" readOnly={true} checked={[1,3].includes(data.serviceTag)} />
                              &nbsp;随时退 （有效期内的订单用户可以随时发起退款申请）</div>
                            <div><Input type="checkbox" readOnly={true} checked={[2,3].includes(data.serviceTag)} />
                              &nbsp;过期退 （券码过期后用户依然可以申请退款）</div>
                        </Space>
                    </Form.Item>
                    <Form.Item label="提前预约">
                        <Switch checked={data.reserve} />
                    </Form.Item>
                    <Form.Item label="是否立即上架">
                        <Switch checked={data.fastShelves} />
                    </Form.Item>

                    <Form.Item label="顾客分享佣金">
                        {data.custCommissionRatio}%, &nbsp;&nbsp; 佣金金额: {data.presentPrice*data.custCommissionRatio/100}
                    </Form.Item>

                    {/*<Form.Item label="最大佣金金额">
                        {data.custCommissionMax}
                    </Form.Item>

                     <Form.Item label="是否加入共享商圈">
                        <Switch checked={data.joinAlliance?true:false} />
                        {data.joinAlliance===1&&<div>
                            异店推广佣金: {data.shopCommissionRatio}%,
                            佣金金额: {data.shopCommissionMax},
                            联盟盟主佣金: {data.leagueLeaderCommissionRatio}%,
                            佣金金额: {data.leagueLeaderCommissionMax}
                        </div>}
                    </Form.Item> */}

                    <Form.Item label="商品排序序号">
                        {data.orderNo}
                    </Form.Item>
                    </Col>
                    </Row>
                </Form>
           </div>
        );
    }
}
