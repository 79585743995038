import {LoadingOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Pagination, Row, Space, Table } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './UserList.css';
import Request from '../../utils/Request';

export default class UserList extends React.Component<{},{data:any, registerTimeFrom: any, total:number, registerTimeTo:any}> {
    constructor(props){
        super(props);
        this.state= {data:'', registerTimeFrom:'', total:0, registerTimeTo:''};
    }

    pageSize = 15;

    componentDidMount(){
        this.fetchData();
    }


    async fetchData(filter?:any, page?:number){
        this.setState({data:''});
        let formdata  = new FormData();
        formdata.set('pageNum', (page?page+'':'1'));
        formdata.set('pageSize', this.pageSize+'');
        if( filter ){
            for( let key of Object.keys( filter ) ){
                formdata.set( key, filter[key] );
            }
        }
        let data = await Request.post( process.env.REACT_APP_REMOTE_URL+"/seller/searchUserInCondition", formdata );
        this.setState({data:data.data?.records, total:data.data?.total});
    }

    changePage = (page)=>{
        this.fetchData(null, page);
    }

    onFinish = (values:any)=>{

        let filter={};
        for( let key of Object.keys( values ) ){
            if( values[key] ){
                filter[key] = values[key];
            }
        }
        if( this.state.registerTimeFrom ){
            filter['registerTimeFrom'] = this.state.registerTimeFrom.format('YYYY-MM-DD');
        }
        if( this.state.registerTimeTo ){
            filter['registerTimeTo'] = this.state.registerTimeTo.format('YYYY-MM-DD');
        }
        this.fetchData( filter );
    }


    public render() {
        return (
           <div>
                <h1>用户列表</h1>
                <div>
                    <Form onFinish={this.onFinish} onReset={this.onFinish}>
                    <Row>
                    <Col span={4}>
                        <Form.Item name="userId" label="用户ID"><Input /></Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={5}>
                         <Form.Item name="userPhoneNo" label="手机号码"><Input /></Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={10}>
                    <span>注册时间: </span>
                    <DatePicker value={this.state.registerTimeFrom} placeholder="开始时间" onChange={(value)=>this.setState({registerTimeFrom: value})} />
                    <span>&nbsp;&nbsp;~&nbsp;&nbsp;</span>
                        <DatePicker  value={this.state.registerTimeTo} placeholder="结束时间"  onChange={(value)=>this.setState({registerTimeTo: value})} />
                    </Col>

                    </Row>
                    <Button htmlType="submit" type="primary">搜索</Button>
                    <Button htmlType="reset" onClick={()=>this.setState({registerTimeFrom:'', registerTimeTo:''})} type="link">清空</Button>

                    </Form>
                    <br /><br />
                </div>

                {!this.state.data&&<LoadingOutlined style={{fontSize: '20px'}} />}
                {this.state.data&&<Table dataSource={this.state.data} size="middle" pagination={false} columns={[
                    {
                        title:'用户ID',
                        render:(record)=><Link to={"/seller/user/view/"+record.userId}>{record.userId}</Link>
                    },
                    {
                        title: '用户昵称',
                        dataIndex: 'userNick'
                    },
                    {
                        title: '手机号码',
                        dataIndex: 'userPhoneNo'
                    },
                    {
                        title: '注册时间',
                        dataIndex: 'createdTime'
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                          <Space size="middle">
                            <Link to={"/seller/user/view/"+record.userId}>查看</Link>
                          </Space>
                        ),
                      },
                    ]} />}

            {this.state.total>0&&<div style={{textAlign: 'right'}}><br />
                                <Pagination  showTotal={(total)=><span>共有{total}条记录</span>} pageSize={this.pageSize} onChange={this.changePage} defaultCurrent={1} total={this.state.total} />
                        </div>}
           </div>
        );
    }
}
