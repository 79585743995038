import React from "react";
import { Route } from "react-router";
import BalanceList from "../pages/modules/finance/BalanceList";
import ViewBalance from "../pages/modules/finance/ViewBalance";
import ViewMerchant from "../pages/modules/finance/ViewMerchant";
import WithdrawApproval from "../pages/modules/finance/WithdrawApproval";
import WithdrawSetting from "../pages/modules/finance/WithdrawSetting";
import CommissionDetail from "../pages/modules/commission/detail";
import SharedList from "../pages/modules/shareholder/list";
import RetailList from "../pages/modules/retail/list";
import AddItem from "../pages/modules/item/AddItem";
import EditItem from "../pages/modules/item/EditItem";
import ItemList from "../pages/modules/item/ItemList";
import ViewItem from "../pages/modules/item/ViewItem";                 
import MiniprogramManagement from "../pages/modules/MiniprogramManagement";
import OrderList from "../pages/modules/order/OrderList";
import ViewOrder from "../pages/modules/order/ViewOrder";
import UserList from "../pages/modules/UserList";
import UserView from "../pages/modules/UserView";        
import ViewWithdraw from "../pages/modules/finance/ViewWithdraw";
import RetailDetail from "../pages/modules/retail/detail";
import SharedAdd from "../pages/modules/shareholder/addShareholder";
import SharedEdit from "../pages/modules/shareholder/editShareholder";
import settingShareholder from "../pages/modules/shareholder/settingShareholder";
import EquityMgr from "../pages/modules/equity/mgr";
import EquityRecord from "../pages/modules/equity/record";
import MgrEdit from "../pages/modules/equity/edit";
import MgrAdd from "../pages/modules/equity/add";
import EmployeeLevel from "../pages/modules/employee/employeeLevel";
import EditEmployeeLevel from "../pages/modules/employee/editEmployeeLevel";
import EmployeeInfoList from "../pages/modules/employee/employeeInfoList";
import AddEmployee from "../pages/modules/employee/addEmployee";
import EditEmployee from "../pages/modules/employee/editEmployee";
import SettingEmployee from "../pages/modules/employee/settingEmployee";
import SettingPay from "../pages/modules/system/settingPay";
import SettingPayInfo from "../pages/modules/system/settingPayInfo";
import MyCBD from "../pages/modules/shareCBD/myCBD";
import settingMyCBDInfo from "../pages/modules/shareCBD/settingMyCBDInfo";
import MerchantManagement from "../pages/modules/shareCBD/merchantManagement";
import CBDMerchantItemsManagement from "../pages/modules/shareCBD/CBDMerchantItemsManagement";
import MyJoinedCBDMerchantItemsManagement from "../pages/modules/shareCBD/MyJoinedCBDMerchantItemsManagement";
import CBDMerchantDetails from "../pages/modules/shareCBD/CBDMerchantDetails";
import Home from "../pages/modules/home/home";
import ShareConfig from "../pages/modules/shareConfig/shareConfig";


export default class MainFrameRoute extends React.Component<{}, {}> {

    render() {
        return (
            <>
                <Route exact path="/seller/mini-program" component={MiniprogramManagement}/>
                <Route exact path="/seller/item/list" component={ItemList}/>
                <Route exact path="/seller/item/view/:itemId" component={ViewItem}/>
                <Route exact path="/seller/item/add/:type" component={AddItem}/>
                <Route exact path="/seller/item/edit/:itemId" component={EditItem}/>
                <Route exact path="/seller/user/view/:id" component={UserView}/>
                <Route exact path="/seller/user/list" component={UserList}/>
                <Route exact path="/seller/order/list" component={OrderList}/>
                <Route exact path="/seller/order/view/:id" component={ViewOrder}/>
                <Route exact path="/seller/finance/balance" component={BalanceList}/>
                <Route exact path="/seller/finance/view-balance/:id" component={ViewBalance}/>
                <Route exact path="/seller/finance/viewMerchant/:sellerId" component={ViewMerchant}/>
                <Route exact path="/seller/finance/withdraw-approval" component={WithdrawApproval}/>
                <Route exact path="/seller/finance/view/:flowId" component={ViewWithdraw}/>
                <Route exact path="/seller/finance/withdraw-setting" component={WithdrawSetting}/>
                <Route exact path="/seller/commission/list" component={CommissionDetail}/>
                <Route exact path="/seller/shareholder/list" component={SharedList}/>
                <Route exact path="/seller/shareholder/add" component={SharedAdd}/>
                <Route exact path="/seller/shareholder/settingShareholder/:shareholderUserId" component={settingShareholder}/>
                <Route exact path="/seller/shareholder/editShareholder/:shareholderUserId" component={SharedEdit}/>
                <Route exact path="/seller/retail/list" component={RetailList}/>
                <Route exact path="/seller/retail/detail/:configId" component={RetailDetail}/>
                <Route exact path="/seller/equity/mgr" component={EquityMgr}/>
                <Route exact path="/seller/equity/mgr/add" component={MgrAdd}/>
                <Route exact path="/seller/equity/mgr/edit/:id" component={MgrEdit}/>
                <Route exact path="/seller/equity/record" component={EquityRecord}/>
                <Route exact path="/seller/employee/employeeLevel" component={EmployeeLevel} />
                <Route exact path="/seller/employee/editEmployeeLevel/:configId" component={EditEmployeeLevel} />
                <Route exact path="/seller/employee/employeeInfoList" component={EmployeeInfoList} />
                <Route exact path="/seller/employee/addEmployee" component={AddEmployee} />
                <Route exact path="/seller/employee/editEmployee/:employeeId" component={EditEmployee} />
                <Route exact path="/seller/employee/settingEmployee/:employeeId" component={SettingEmployee} />
                <Route exact path="/seller/system/settingPay" component={SettingPay} />
                <Route exact path="/seller/system/settingPayInfo" component={SettingPayInfo} />
                <Route exact path="/seller/shareCBD/myCBD" component={MyCBD} />
                <Route exact path="/seller/shareCBD/settingMyCBDInfo" component={settingMyCBDInfo} />
                <Route exact path="/seller/shareCBD/merchantManagement" component={MerchantManagement} />
                <Route exact path="/seller/shareCBD/CBDMerchantItemsManagement/:allianceMasterId" component={CBDMerchantItemsManagement} />
                <Route exact path="/seller/shareCBD/MyJoinedCBDMerchantItemsManagement/:allianceMasterId" component={MyJoinedCBDMerchantItemsManagement} />
                <Route exact path="/seller/shareCBD/CBDMerchantDetails/:sellerId" component={CBDMerchantDetails} />
                <Route exact path="/seller/home/home" component={Home} />
                <Route exact path="/seller/shareConfig/shareConfig" component={ShareConfig} />
            </>)
    }
}
