import {Button, Col, Descriptions, Divider, Form, message, Modal, Popconfirm, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import Request from '../../../utils/Request';

interface Props extends RouteComponentProps {}

const CBDMerchantItemsManagement =(props:any)=>{
    const [id,setId]=useState<number>(props?.match?.params?.allianceMasterId);
    const [dataSource,setDataSource]=useState<any>([]);
    const [allianceName,setAllianceName]=useState<any>('');
    const [name,setName]=useState<any>('');
    const [defaultBonusRatio,setDefaultBonusRatio]=useState<number>(0);
    const itemTypeMap:any = {
        0: '团购套餐',
        1: '代金券',
        2: '门店付款'
    };
    const categoryMap:any=[
        { name: '所有', value: -1 },
        { name: '餐饮美食', value: 1 },
        { name: '休闲娱乐', value: 2 },
        { name: '美容美发', value: 3 },
        { name: '教育培训', value: 4 },
        { name: '酒店公寓', value: 5 },
        { name: '体育健身', value: 6 },
        { name: '景点乐园', value: 7 },
        { name: '洗浴足疗', value: 8 },
        { name: '生活服务', value: 9 },
        { name: '其他', value: 0 }
    ];//商户分类
    // 商品上架、下架、移除
    const onStatusChange=async (checked:any,itemId:any)=>{
        console.log(checked,itemId);
        // @ts-ignore
        let isEnabled=checked===true?1:0;
        let params=checked===2?{ delete:2 }:{  isEnabled: isEnabled };
        let res=await Request.jsonPost( process.env.REACT_APP_REMOTE_URL+`/seller/alliance/hasJoinedSellerStatusInfo/${itemId}`,params);
        if(isEnabled===1&&res.code===0){
            message.success('商品已上架!')
        }else {
            if(checked===2){
                message.success('商品已移除!')
            }else {
                message.success('商家已下架!')
            }
        };
        initTable();
    };
    const columns:any= [
        {
            title: '商品ID',
            dataIndex: 'itemId' ,
            key:'itemId' ,
            align:'center'
        },
        {
            title: '商品名称',
            dataIndex: 'itemName' ,
            key:'itemName' ,
            align:'center'
        },
        {
            title: '销售价',
            dataIndex: 'presentPrice' ,
            key:'presentPrice' ,
            align:'center'
        },
        {
            title: '分享佣金奖励比例',
            key:'custCommissionRatio' ,
            align:'center',
            render: (text,record,index) => (
                <div>{text.custCommissionRatio}%</div>
            )
        },
        {
            title: '实际销量',
            dataIndex: 'realitySalesVolume' ,
            key:'realitySalesVolume' ,
            align:'center'
        },
        {
            title: '核销销量',
            dataIndex: 'cancelSalesVolume' ,
            key:'cancelSalesVolume' ,
            align:'center'
        },
        {
            title: '剩余库存',
            dataIndex: 'stock' ,
            key:'stock' ,
            align:'center'
        },
        {
            title: '商品类型',
            key:'itemType' ,
            align:'center',
            render: (text,record,index) => (
                <div>{ itemTypeMap[record?.itemType*1] }</div>
            ),

        },
        {
            title: '上/下架状态',
            key: 'itemStatus',
            align:'center',
            render: (text,record,index) => (
                <>
                    <Switch defaultChecked={record.itemStatus===1} onChange={(checked,e)=>onStatusChange(checked,record.itemId)} />
                </>
            )
        },
        {
            title: '添加时间',
            dataIndex: 'createdTime' ,
            key:'createdTime' ,
            align:'center'
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            width: 300,
            render: (text,record,index) => (
                <>
                    <Popconfirm
                        title="确认移除该商品吗?"
                        onConfirm={(record:any)=>{onStatusChange(2,text.itemId);}}
                        okText="确认"
                        cancelText="取消">
                        <Button type={'link'} href="#" >移除</Button>
                    </Popconfirm>
                </>
            ),
        }
    ];

    const initTable=async()=>{
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/hasJoinedSeller/${id}`);
        setDataSource( res.data?.allianceItemList);
        setName(res?.data?.sellerName);
        setAllianceName(res?.data?.allianceName);
        setDefaultBonusRatio(res?.data?.defaultBonusRatio);
    };
    useEffect(()=>{
        initTable();
    },[]);

    return(
        <div>
            <h1>共商商圈-商品管理 <Button type="link"><Link to="/seller/shareCBD/myCBD">返回</Link></Button></h1>
            <Divider />

            <Descriptions>
                <Descriptions.Item label="商圈名称">{allianceName}<span style={{color:"#ff1e1e",marginLeft:'10px'}}>( 已加入 )</span></Descriptions.Item>
                <Descriptions.Item label="商家名称">{name}</Descriptions.Item>
                <Descriptions.Item label="盟主佣金比例">{defaultBonusRatio/100}%</Descriptions.Item>
            </Descriptions>

            {dataSource.length<0&&<div style={{margin:"20px",color:"#ff1e1e"}}>暂无商圈商品，请在商品管理中勾选【加入联盟商圈】</div>}

            <br />

            <Table size={'middle'} rowKey={'id'} columns={columns} dataSource={dataSource} pagination={false} />
        </div>
    );
};
export default CBDMerchantItemsManagement;

