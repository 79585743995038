import React, {useEffect, useRef, useState} from "react";
import { Divider, Form, Input, Space, Button, Modal, Upload, Radio, Select,Row,Col, message,Cascader } from "antd";
import Request from '../../../utils/Request';
import { PlusOutlined } from '@ant-design/icons';
import address from '../../../assets/js/area.json';


const formRef:any= React.createRef();
const { TextArea } = Input;
const MgrEdit=(props:any)=>{
    const [id,setId]=useState<string>('');
    const [currentEquity,setCurrentEquity]=useState<string>('');
    const [isDefault,setIsDefault]=useState<boolean>(false);
    const [fileList,setFileList]=useState<any>([]);
    const prefix = useRef();

    useEffect(()=>{
        initData();
    },[]);

    const initData = async ()=>{
        try{
            const defaultRes = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/alliance/getBusinessData"); //获取商圈信息
            console.log(defaultRes)
            if(defaultRes.code===0) {
                setId(defaultRes?.data?.id);
                formRef.current.setFieldsValue({...defaultRes.data,area:[defaultRes?.data?.province,defaultRes?.data?.city,defaultRes?.data?.county]});//表单返填数据
            }
        }catch(e){
            console.error(e);
        }
    }
    const token:string=sessionStorage.getItem("token");

    //省市区
    const onChange=(value)=>{
        console.log(value);
    };

    //商圈图片
    const uploadProps:any = {
        name: 'file',
        action: `${process.env.REACT_APP_REMOTE_URL}/seller/alliance/uploadAlliancePic`,
        headers: {
            authorization: token
        },
        accept:"image/*",
        multiple:true,
        onChange(info) {
            console.log(info);
            setFileList(info?.fileList.map(item=>{ return {...item,url:`${prefix.current}${item.url}`} }));
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`上传成功！`);
                setFileList([
                    {
                        uid: '-1',
                        status: 'done',
                        url:`${prefix.current}${info.file?.response?.data}`
                    },
                ])
                setIsDefault(false);
                setCurrentEquity('');
            } else if (info.file.status === 'error') {
                message.error(`上传失败！`);
            }
        },
    };

    //提交
    const onFinish=async (values)=>{
        console.log(values);
        values.id=id;
        values.pictUrlList=fileList.toString();
        if(values?.area?.length>0) {
            values.province = values.area[0];
            values.city = values.area[1];
            values.county = values.area[2];
            delete values?.area
        };
        let result = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/alliance/updateBusinessData", values);
        if (result.code === 0) {
            Modal.success({
                content: "修改成功",
                onOk:()=>{
                    window.location.href='#/seller/shareCBD/myCBD';
                }}
            );
        }
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );

    return (
        <div>
            <h1>商圈资料</h1>
            <Divider  />

            <Form
                name="basic"
                ref={formRef}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 6 }}
                onFinish={onFinish}
                autoComplete="on"
                onValuesChange={(current,all)=>{
                    console.log(all);
                }}>
                <Form.Item
                    label="商圈名称"
                    name="allianceName"
                    rules={[{ required: true, message: '请输入商圈名称!' }]}>
                    <Input maxLength={6} />
                </Form.Item>
                <Form.Item
                    label="省/市/区"
                    name="area"
                    rules={[{ required: true, message: '请选择省市区!' }]}>
                    <Cascader options={address} onChange={onChange} placeholder="请选择省市区" />
                </Form.Item>
                <Form.Item
                    label="详细地址"
                    name="address"
                    rules={[{ required: true, message: '请选择省市区!' }]}>
                    <Input maxLength={6} />
                </Form.Item>
                <Form.Item
                    label="商圈介绍"
                    name="allianceIntro"
                    rules={[{ required: false, message: '请输入商圈介绍!' }]}>
                    <TextArea rows={6} placeholder="请输入商圈介绍" />
                </Form.Item>
                <Form.Item
                    label="商圈图片"
                    name="cardModuleName"
                    rules={[{ required:  false, message: '请输入商圈介绍!' }]}>
                    {/*<Space>*/}
                    {/*    {defaultEquity?.map((item:any)=><Radio value={item} key={item}>*/}
                    {/*        <img src={item} alt="权益卡默认背景图" width={300} height={120} style={{borderRadius:"10px"}} />*/}
                    {/*    </Radio>)}*/}
                    {/*</Space>*/}
                    <Upload {...uploadProps} listType="picture-card" fileList={fileList} >
                        {/*<Button icon={<UploadOutlined />} style={{margin:"0px 0"}}>上传商圈图片</Button>*/}
                        {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="默认盟主佣金比例"
                    name="defaultBonusRatio"
                    rules={[{ required: true, message:'请输入默认盟主佣金比例' }]}>
                    <Input suffix={'%'} />
                </Form.Item>
                <Row>
                    <Col span={6} offset={6} style={{color:"#999"}}>盟主分红=订单金额*佣金比例</Col>
                </Row>

                <Form.Item wrapperCol={{ offset: 6, span: 6 }} style={{margin:"100px"}}>
                    <Button type="default"  href= {'#/seller/shareCBD/myCBD' } >取消</Button>
                    <Button type="primary" htmlType="submit" style={{margin:"20px"}}>保存</Button>
                </Form.Item>

            </Form>
        </div>
    );
};
export default MgrEdit;