import React from "react";
import { Radio, Image, Divider, Empty, Modal, Table,} from "antd";
import Request from "../../../utils/Request";
import {Link} from "react-router-dom";
import {log} from "util";

interface page {
    data: any,
}

export default class RetailList extends React.Component<{}, page> {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.payParameterConfig();
    }

    async payParameterConfig() {
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/pay/payParameterConfig");
        // if (res.code !== 0) {
        //     Modal.error({content: res.error});
        //     return;
        // }
        this.setState({data: res.data});
    }


    render() {
        return <div>
            <h1>微信支付配置</h1>
            <Divider/>
            <div>
                <TableList data={this.state.data}/>
            </div>
            <br/>
        </div>;
    }
}

function TableList(props: { data: any }) {

    if (props.data?.length === 0) {
        return <Empty/>
    }

    return <Table
                dataSource={props.data}
                rowKey="id"
                pagination={ false }
                columns={[
                    {
                        title: '支付参数',
                        dataIndex: 'key',
                        align:"center"
                    },
                    {
                        title: '参数配置',
                        dataIndex: 'value',
                        align:"center",
                        render: (text,record:any) => (
                            <span>{record?.value===true?'配置':'未配置'}</span>
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align:"center",
                        render: (text, record) => (

                            <Link to={"/seller/system/settingPayInfo"}>配置信息</Link>
                        )
                    }
                ]}
            />
}
