import React, { useState,useEffect } from "react";
import {Divider, Form, Input, Space, Button, Modal, Select,Row} from "antd";
import settings from "../../../settings";
import Request from '../../../utils/Request';
import {LoadingOutlined} from "@ant-design/icons";

const { Option } = Select;
const AddEmployee=()=>{
    const [phoneInfo,setPhoneInfo]=useState([]);
    const [roleList,setRoleList]=useState([]);
    const [role,setRole]=useState<any>('');

    useEffect(()=>{
        getRoleInfo();
    },[]);

    //获取员工等级
    const getRoleInfo =async () => {
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/distribution/listEmployeeDistributionIdentity");
        if(res.code==0){
            setRoleList(res.data);
        }
    };
    //查找手机号
    const handlePhoneSearch= async (value:any)=> {
        let res = await Request.post(process.env.REACT_APP_REMOTE_URL + "/seller/shareholder/searchShareholderByPhone?phone=" + value, null);
        if(res.code==0) {
            setPhoneInfo(res.data);
        }
    };
    //提交
    const onFinish=async (values)=>{
        let result = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/employee/addEmployee", values);
        if (result.code === 0) {
            Modal.success({
                content: "新增成功",
                onOk:()=>{
                    window.location.href='#/seller/shareholder/list';
                }});
        }
    };

    return (
        <div>
            <h1>手动添加员工</h1>
            <Divider  />

            <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 6 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="on"
                onValuesChange={(current,all)=>{
                    console.log(current);
                    console.log(all);
                    if(all.roleLevel){
                        setRole(roleList.find(item=>item.roleLevel==all.roleLevel));
                    }
                }}>

                <Form.Item
                    label="员工姓名"
                    name="employeeName"
                    rules={[{ required: true, message: '请输入员工姓名!' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="手机号码"
                    name="employeePhone"
                    rules={[{ required: true, message: '请输入员工手机号' }]}>
                    <Select
                        showSearch={true}
                        placeholder='请输入员工手机号'
                        defaultActiveFirstOption={false}
                        showArrow={true}
                        filterOption={false}
                        onSearch={(value)=>handlePhoneSearch(value)}
                        notFoundContent={null}>
                        {phoneInfo.map((item:any) => (
                            <Option key={item.sellerId} value={item.userPhone}>{item.userPhone}(昵称：{item.userName})</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="入股充值金额"
                    name="rechargeAmount"
                    rules={[{ required: false, message: '请输入入股充值金额!' }]}>
                    <Input suffix="元" />
                </Form.Item>

                <Form.Item
                    label="分配拓客等级"
                    name="roleLevel"
                    rules={[{ required: true, message: '请分配拓客等级!' }]}>
                    <Select
                        showSearch={false}
                        placeholder='请分配拓客等级'
                        defaultActiveFirstOption={false}
                        showArrow={true}
                        filterOption={false}
                        notFoundContent={null}>
                        {roleList.map((item:any) => (
                            <Option key={item.id} value={item.roleLevel}>{item.roleName}</Option>
                        ))}
                    </Select>
                </Form.Item>

                {role&&<Form.Item  label={`${role.roleName}可享权益`}>
                    <Space direction={"vertical"}>
                        <div>
                            折扣卡：{role.cardModuleConf?.cardModuleName} ({role?.cardModuleConf?.discountRatio/10}折)
                        </div>
                        <div>
                            员工分红：实付金额 {role?.bonusRatio}%
                        </div>
                    </Space>
                </Form.Item>}

                <Form.Item
                    label="分配推广卡数"
                    name="cardNum"
                    rules={[{ required: true, message: '请设置分配推广卡数!' }]}>
                    <Input suffix="张" />
                </Form.Item>

                {roleList&&<Form.Item label='当前推广卡'>
                    {roleList[0]?.cardModuleConf?.cardModuleName} ({roleList[0]?.cardModuleConf?.discountRatio/10}折)
                </Form.Item>}

                <Form.Item wrapperCol={{ offset: 6, span: 6 }} style={{margin:"100px"}}>
                    <Button type="default"  onClick={()=>{ window.location.href= '#/seller/employee/employeeInfoList' }} >
                        取消
                    </Button>
                    <Button type="primary" htmlType="submit" style={{margin:"20px"}}>
                        确认添加
                    </Button>
                </Form.Item>

            </Form>
        </div>
    );
};
export default AddEmployee;