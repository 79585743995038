
export default function uploadImage(blobInfo, success, failure, progress, url){
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', sessionStorage.getItem('token'));

    xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100);
    };

    xhr.onload = function() {
        var json;

        if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, { remove: true });
        return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
        }

        json = JSON.parse(xhr.responseText);

        if (!json || typeof json.data !== 'string') {
        failure('错误: ' + xhr.responseText);
        return;
        }

        success(json.data);
    };

    xhr.onerror = function () {
        failure('Error: ' + xhr.status);
    };

    formData = new FormData();
    formData.append('fullAddress', 'true');
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
}
