import React, { useState, useEffect, useRef } from 'react';
import {Button, Row, Col, Statistic, Card, Descriptions} from 'antd';
import Request from "../../../utils/Request";

const Home=()=>{
    const [toVerifyOrderNo,setToVerifyOrderNo]=useState<number>(0);
    const [toVerifyOrderAmount,setToVerifyOrderAmount]=useState<number>(0);
    const [orderNo,setOrderNo]=useState<number>(0);
    const [orderAmount,setOrderAmount]=useState<number>(0);
    const [toGiveBonusAmount,setToGiveBonusAmount]=useState<number>(0);
    const [haveGivenBonusAmount,setHaveGivenBonusAmount]=useState<number>(0);
    const [shareholdersNo,setShareholdersNo]=useState<number>(0);
    const [customerNo,setCustomerNo]=useState<number>(0);
    const [allianceMemberNo,setAllianceMemberNo]=useState<number>(0);
    const [incomeOfWatingVerifying,setIncomeOfWatingVerifying]=useState<number>(0);
    const [totalIncome,setSellerOrderTotalFee]=useState<number>(0);

    const [resultMap,setResultMap]=useState<any>({
        perfectStoreData:0,
        perfectWithdrawConfig:0,
        perfectAppletConfig:0,
        perfectEquityCardConfig:0,
        perfectEmployeeConfig:0,
        perfectShareHolderConfig:0,
        perfectItemConfig:0
    });
    const [configNo,setConfigNo]=useState<number>(0);
    const [accomplishConfigNo,setAccomplishConfigNo]=useState<number>(0);
    const [official,setOfficial]=useState<number>(1);
    const [wxaCodeUnlimitBytes,setWxaCodeUnlimitBytes]=useState<string>('');

    //数据
    const initData=async()=>{
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/getDataStatics`);
        setToVerifyOrderNo( res.data?.toVerifyOrderNo);
        setToVerifyOrderAmount(res?.data?.toVerifyOrderAmount);
        setOrderNo(res?.data?.orderNo);
        setOrderAmount(res?.data?.orderAmount);
        setToGiveBonusAmount(res?.data?.toGiveBonusAmount);
        setHaveGivenBonusAmount(res?.data?.haveGivenBonusAmount);
        setShareholdersNo(res?.data?.shareholdersNo);
        setCustomerNo(res?.data?.cumstomerNo);
        setAllianceMemberNo(res?.data?.allianceMemberNo);
        setIncomeOfWatingVerifying(res?.data?.incomeOfWatingVerifying);
        setSellerOrderTotalFee(res?.data?.totalIcncome);
    };

    //小程序配置
    const doAllConfig=async()=>{
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/appletConfigFlowPath`);
        setResultMap(res?.data?.resultMap);
        setConfigNo(res?.data?.configNo);
        setAccomplishConfigNo(res?.data?.accomplishConfigNo);
        setOfficial(res?.data?.official);
        setWxaCodeUnlimitBytes(res?.data?.wxaCodeUnlimitBytes);
    };

    useEffect(()=>{
        initData();
        doAllConfig();
    },[]);

    return (
      <>
          <h1>店铺经营数据</h1>
          <Row gutter={[16, 16]} justify={'center'} align={'middle'}>
              <Col span={6}>
                  <Card>
                    <Statistic title="待核销订单数" value={toVerifyOrderNo}  />
                  </Card>
              </Col>
              <Col span={6}>
                  <Card>
                    <Statistic title="待核销销售额" value={toVerifyOrderAmount/100}  />
                  </Card>
              </Col>
              <Col span={6}>
                  <Card>
                    <Statistic title="待支出佣金" value={toGiveBonusAmount/100}  />
                  </Card>
              </Col>
              <Col span={6}>
                  <Card>
                    <Statistic title="待核销预计收入" value={incomeOfWatingVerifying/100}  />
                  </Card>
              </Col>

              <Col span={6}>
                  <Card>
                      <Statistic title="累计订单数" value={orderNo}  />
                  </Card>
              </Col>
              <Col span={6}>
                  <Card>
                      <Statistic title="累计销售额" value={orderAmount/100}  />
                  </Card>
              </Col>
              <Col span={6}>
                  <Card>
                      <Statistic title="佣金支出总额" value={haveGivenBonusAmount/100} />
                  </Card>
              </Col>
              <Col span={6}>
                  <Card>
                      <Statistic title="累计收入" value={totalIncome/100} />
                  </Card>
              </Col>


              <Col span={6}>
                  <Card>
                      <Statistic title="累计股东数" value={shareholdersNo} />
                  </Card>
              </Col>
              <Col span={6}>
                  <Card>
                      <Statistic title="总客源数" value={customerNo}  />
                  </Card>
              </Col>
              <Col span={6}>
                  <Card>
                      <Statistic title="共享商家数" value={allianceMemberNo} />
                  </Card>
              </Col>
              <Col span={6}>

              </Col>

          </Row>
          <div style={{margin:"20px 0",display:"flex"}}>
              <h1 >小程序配置流程</h1>
              <h1 >完成度： {accomplishConfigNo}/{configNo}</h1>
          </div>
          <Card>
              <Row gutter={[16, 16]} justify={'space-between'} align={'middle'}>
                  <Col span={8}>
                      <Descriptions column={1}>
                          {resultMap?.perfectStoreData&&<Descriptions.Item label="店铺资料" labelStyle={{width:"200px"}}>{resultMap?.perfectStoreData===0?<Button danger >未完善</Button>:<Button style={{color:"#26BF4D",marginLeft:"100px"}}>已完成</Button>}</Descriptions.Item>}
                          {resultMap?.perfectAppletConfig&&<Descriptions.Item label="小程序配置" labelStyle={{width:"200px"}}>{resultMap?.perfectAppletConfig===0?<Button danger >未完善</Button>:<Button style={{color:"#26BF4D",marginLeft:"100px"}}>已完成</Button>}</Descriptions.Item>}
                          {resultMap?.perfectEquityCardConfig&&<Descriptions.Item label="权益卡配置" labelStyle={{width:"200px"}}>{resultMap?.perfectEquityCardConfig===0?<Button danger href="#">未完善</Button>:<Button style={{color:"#26BF4D",marginLeft:"100px"}}>已完成</Button>}</Descriptions.Item>}
                          {resultMap?.perfectShareHolderConfig&&<Descriptions.Item label="股东身份配置" labelStyle={{width:"200px"}}>{resultMap?.perfectShareHolderConfig===0?<Button danger href="#">未完善</Button>:<Button style={{color:"#26BF4D",marginLeft:"100px"}}>已完成</Button>}</Descriptions.Item>}
                          {resultMap?.perfectEmployeeConfig&&<Descriptions.Item label="员工拓客配置" labelStyle={{width:"200px"}}>{resultMap?.perfectEmployeeConfig===0?<Button danger href="#">未完善</Button>:<Button style={{color:"#26BF4D",marginLeft:"100px"}}>已完成</Button>}</Descriptions.Item>}
                          {resultMap?.perfectItemConfig&&<Descriptions.Item label="店铺商品配置" labelStyle={{width:"200px"}}>{resultMap?.perfectItemConfig===0?<Button danger href="#">未完善</Button>:<Button style={{color:"#26BF4D",marginLeft:"100px"}}>已完成</Button>}</Descriptions.Item>}
                          {resultMap?.perfectWithdrawConfig&&<Descriptions.Item label={`提现配置`} labelStyle={{width:"200px"}}>{resultMap?.perfectWithdrawConfig===0?<Button danger href="#">未完善</Button>:<Button style={{color:"#26BF4D",marginLeft:"100px"}}>已完成</Button>}</Descriptions.Item>}
                      </Descriptions>
                  </Col>
                  <Col span={8}>
                      {official===1&&<div style={{color:"#ff4433"}}>请先完成小程序配置 即可生成小程序二维码</div>}
                      {official===2&&<div style={{color:"#ff4433"}}>商家小程序还未发布</div>}
                      {wxaCodeUnlimitBytes&&<img src={wxaCodeUnlimitBytes} alt="请先完成小程序配置 即可生成小程序二维码" width={200} height={200} />}
                  </Col>
                  <Col span={8}></Col>
              </Row>
          </Card>
      </>
    );
};
export default Home;