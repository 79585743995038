import React, {useEffect, useRef, useState} from "react";
import { Divider, Form, Input, Space, Button, Modal, Upload, Radio, Select,Row,Col, message, } from "antd";
import Request from '../../../utils/Request';
import { UploadOutlined } from '@ant-design/icons';

const formRef:any= React.createRef();
const MgrEdit=(props:any)=>{
    const [id,setId]=useState<number>(props?.match?.params?.id);
    const [defaultEquity,setDefaultEquity]=useState<any>([]);
    const [currentEquity,setCurrentEquity]=useState<string>('');
    const [isDefault,setIsDefault]=useState<boolean>(false);
    const [fileList,setFileList]=useState<any>([]);
    const prefix = useRef();

    useEffect(()=>{
        initData();
    },[]);

    const initData = async ()=>{
        try{
            const defaultCardListRes = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/getDefaultModulePic"); //所有权益卡模板
            const CardInfoRes = await Request.get(`${process.env.REACT_APP_REMOTE_URL}/seller/equity/getModuleCardInfo?cardId=${id}`); //当前权益卡信息
            prefix.current = CardInfoRes.data.prefix;
            if(defaultCardListRes.code===0) {
                setDefaultEquity(defaultCardListRes.data?.templeList);
            }
            if(CardInfoRes.code===0) {
                let result=defaultCardListRes.data?.templeList.includes(CardInfoRes.data?.cardPictPath);
                setIsDefault(result);
                if(result){
                    setCurrentEquity(CardInfoRes.data.cardPictPath);//默认背景
                }else {
                    setFileList([
                        {
                            uid: '-1',
                            status: 'done',
                            url:`${CardInfoRes.data.prefix}${CardInfoRes.data.cardPictPath}`
                        },
                    ])
                }
                formRef.current.setFieldsValue({...CardInfoRes.data,discountRatio:CardInfoRes.data.discountRatio,isDefault:result,defaultEquity:defaultEquity});//表单返填数据
            }
        }catch(e){
            console.error(e);
        }
    }
    const token:string=sessionStorage.getItem("token");

    //自定义权益卡背景
    const uploadProps:any = {
        name: 'file',
        action: `${process.env.REACT_APP_REMOTE_URL }/seller/equity/uploadCardPic`,
        headers: {
            authorization: token
        },
        accept:"image/*",
        multiple:false,
        onChange(info) {
            console.log(info)
            setFileList(info.fileList.map(item=>{ return {...item,url:`${prefix.current}${item.url}`} }))
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`权益卡背景上传成功！`);
                setFileList([
                    {
                        uid: '-1',
                        status: 'done',
                        url:`${prefix.current}${info.file?.response?.data}`
                    },
                ])
                setIsDefault(false);
                setCurrentEquity('');
            } else if (info.file.status === 'error') {
                message.error(`权益卡背景上传失败！`);
            }
        },
    };

    //提交
    const onFinish=async (values)=>{
        console.log(values);
        values.id=id;
        values.cardPictPath=isDefault===true?currentEquity:fileList[0].url;
        let result = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/equity/saveOrUpdateCardModule", values);
        if (result.code === 0) {
            Modal.success({
                content: "编辑成功",
                onOk:()=>{
                    window.location.href='#/seller/equity/mgr';
                }});
        }
        // else {
        //     Modal.error({ content: result.message });
        //     return;
        // }
    };

    return (
        <div>
            <h1>编辑权益卡</h1>
            <Divider  />

            <Form
                name="basic"
                ref={formRef}
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 4 }}
                onFinish={onFinish}
                autoComplete="on"
                onValuesChange={(current,all)=>{
                    console.log(all);
                }}>
                <h3>权益卡信息</h3>
                <Form.Item
                    label="权益卡名称"
                    name="cardModuleName"
                    rules={[{ required: true, message: '请输入权益卡名称!' }]}>
                    <Input maxLength={6} />
                </Form.Item>

                <Form.Item label="权益卡背景"  rules={[{ required: true, message: '请设置权益卡背景' }]} >
                    <Radio.Group value={isDefault} onChange={(e:any)=>{ setIsDefault(e.target.value);if(!e.target.value){setCurrentEquity('')}}}>
                        <Space direction="vertical">
                            <Radio value={true}>
                                <Space direction="vertical">
                                    默认背景
                                    <Radio.Group value={currentEquity} onChange={(e:any)=>{ setCurrentEquity(e.target.value); }}>
                                        <Space>
                                            {defaultEquity?.map((item:any)=><Radio value={item} key={item}>
                                                <img src={item} alt="权益卡默认背景图" width={300} height={120} style={{borderRadius:"10px"}} />
                                            </Radio>)}
                                        </Space>
                                    </Radio.Group>
                                </Space>
                            </Radio>
                            <Radio value={false}>
                                <Space direction="vertical">
                                    自定义背景
                                    <Upload {...uploadProps}  listType="picture" fileList={fileList} >
                                        <Button icon={<UploadOutlined />} style={{margin:"10px 0"}}>自定义权益卡背景</Button>
                                    </Upload>
                                </Space>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <h3>权益卡福利</h3>
                <Form.Item
                    label="折扣比例"
                    name="discountRatio"
                    rules={[{ required: true, message: '请输入折扣比例' }]}>
                        <Input suffix={'%'} />
                </Form.Item>
                <Row>
                    <Col span={6} offset={2} >该身份顾客在店内直接在线付款，可直接享受的优惠比例</Col>
                </Row>

                <Form.Item wrapperCol={{ offset: 6, span: 6 }} style={{margin:"100px"}}>
                    <Button type="default"  onClick={()=>{ window.location.href= '#/seller/shareholder/list' }} >
                        取消
                    </Button>
                    <Button type="primary" htmlType="submit" style={{margin:"20px"}}>
                        保存
                    </Button>
                </Form.Item>

            </Form>
        </div>
    );
};
export default MgrEdit;