import React, { useState, useEffect, useRef } from 'react';
import {Button, Form, Input, Select, Space, Table, Tabs, Row, Col, DatePicker, Modal, Tag, Popover, Spin, Popconfirm, message, Divider,InputNumber,Switch,} from 'antd';
import Request from "../../../utils/Request";
import address from '../../../assets/js/area.json';
import { EditOutlined } from "@ant-design/icons";


// @ts-ignore
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface ITabProps{
    status?:any
}

interface IModal{
    text:string,
    children?:any
}

const ModalWrap = (props:IModal)=>{
    const [visible,setVisible] = useState(false)
    return (
        <>
            <Button type='link' onClick={()=>{ setVisible(true) }}>{props.text}</Button>
            <Modal visible={visible} onCancel={()=>{ setVisible(false) }} title={props.text} width={1000} footer={null}>
                {props.children}
            </Modal>
        </>
    )
};

const BaseTabItem = (props:ITabProps)=>{
    const [form] = Form.useForm();
    const { status } = props;
    const [pageNum,setPageNum] = useState<any>(1);
    const [pageSize,setPageSize] = useState<any>(10);
    const [total,setTotal]=useState<number>(0);
    const [notApproveReason,setNotApproveReason]=useState<string>('');
    const [auditStatus,setAuditStatus]=useState<number>(0);
    const [auditVisible,setAuditVisible]=useState<any>(false);//驳回审核
    const categoryMap:any=[
        { name: '所有', value: -1 },
        { name: '餐饮美食', value: 1 },
        { name: '休闲娱乐', value: 2 },
        { name: '美容美发', value: 3 },
        { name: '教育培训', value: 4 },
        { name: '酒店公寓', value: 5 },
        { name: '体育健身', value: 6 },
        { name: '景点乐园', value: 7 },
        { name: '洗浴足疗', value: 8 },
        { name: '生活服务', value: 9 },
        { name: '其他', value: 0 }
    ];//商户分类
    const merchantStatusMap:any=[
        { name: '不可加入', value: -1 },
        { name: '待申请', value: 0 },
        { name: '待平台审核', value: 1 },
        { name: '待盟主审核', value: 2 },
        { name: '审核通过', value: 3 },
        { name: '平台驳回审核', value: 4 },
        { name: '盟主驳回审核', value: 5 },
    ];//商户状态
    const [isEdit, setIsEdit]=useState<boolean>(false);//排序
    const [isInvite, setIsInvite]=useState<boolean>(false);//邀请
    const [qrCode, setQrCode]=useState<string>('');//邀请二维码

    // 已合作商家启用、停用
    const onStatusChange=async (checked:boolean,id:any)=>{
        console.log(checked,id);
        let isEnabled=checked?1:0;
        let res=await Request.jsonPost( process.env.REACT_APP_REMOTE_URL+`/seller/alliance/cooperationSellerIsEnabled/${id}?isEnabled=${isEnabled}`,{});
        if(isEnabled===1&&res.code===0){
            message.success(' 商家已启用!')
        }else {
            message.success('商家已停用!')
        }
    }

    //已合作商家排序
    const onOrderChange=(value)=>{
        console.log('changed', value);
    }
    const tab0 = [
        {
            title: '商家名称',
            dataIndex: 'sellerName',
            key: 'sellerName',
            align:'center',
        },
        {
            title: '商家分类',
            key: 'categoryId',
            align:'center',
            render: (text,record,index) => (
                <div>{ categoryMap.find(item=>record?.categoryId*1===item.value)?.name }</div>
            ),
        },
        {
            title: '商圈商品数',
            dataIndex: 'allianceItemDate',
            key: 'allianceItemDate',
            align:'center'
        },
        {
            title: '启用状态',
            key: 'isEnabled',
            align:'center',
            render: (text,record,index) => (
                <>
                    <Switch defaultChecked={record.isEnabled===1} onChange={(checked,e)=>onStatusChange(checked,record.sellerId)} />
                </>
            )
        },
        {
            title: '商圈商家排序',
            key: 'orderNo',
            align:'center',
            render: (text,record,index) => (
                <>
                    {isEdit===false&&<div><span>{record?.orderNo}</span><Button type="link" size={"small"} icon={<EditOutlined />} onClick={()=>{ setIsEdit(true) }}></Button></div>}
                    {isEdit===true&&<InputNumber defaultValue={record?.orderNo} onChange={(value:any)=>onOrderChange(value)} />}
                </>
            )
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            render: (text,record,index) => (
                <div>
                    <Button type="link" size={"small"}  href={`#/seller/shareCBD/CBDMerchantDetails/${text.sellerId}`} >详情</Button>
                </div>
            )
        }
    ];
    const tab1=  [
        {
            title: '商家名称',
            dataIndex: 'sellerName',
            key: 'sellerName',
            align:'center'
        },
        {
            title: '商家分类',
            key: 'categoryId',
            align:'center',
            render: (text,record,index) => (
                <div>{ categoryMap.find(item=>item.value===record?.categoryId*1)?.name }</div>
            ),
        },
        {
            title: '商圈商品数',
            key: 'allianceItemDate',
            dataIndex: 'allianceItemDate',
            align: 'center',
        },
        {
            title: '审核状态',
            key: 'approveStatus',
            align:'center',
            render: (text,record,index) => (
                <>
                    <div>{ merchantStatusMap.find(item=>item.value===record?.approveStatus*1)?.name }</div>
                </>
            )
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            render: (text,record,index) => (
                <>
                    {text.status==1&&text?.status==2&&<div>~</div>}

                    {text.status!==3&&<Popconfirm
                        title="确认通过该商家审核吗?"
                        onConfirm={(record:any)=>{onAudit(text);setAuditStatus(1)}}
                        okText="确认"
                        cancelText="取消">
                        <a href="#">审核通过</a>
                    </Popconfirm>}

                    {text.status!==3&&<Button type={'link'} size={'small'} onClick={()=>{setAuditVisible(true);setAuditStatus(3)}}>驳回申请</Button>}

                    <Modal title='驳回申请' width={600} visible={auditVisible} onCancel={()=>{setAuditVisible(false)}} footer={null}>
                        <Form
                            name="basic"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            onFinish={(record:any)=>onAuditFinish(text)}
                            onValuesChange={(current,all)=>{
                                console.log(all)
                                if(all.notApproveReason){
                                    setNotApproveReason(all.notApproveReason)
                                }
                            }} >
                            <Form.Item label="申请驳回原因" rules={[{ required: true, message: '请输入驳回原因' }]} name="notApproveReason">
                                <TextArea placeholder="请输入驳回原因" rows={2}  />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 12, span: 8 }} style={{margin:"100px 0 40px"}}>
                                <Button type="default"  onClick={()=>{ setAuditVisible(false) }} >
                                    取消
                                </Button>
                                <Button type="primary" htmlType="submit" style={{margin:"20px"}}>
                                    保存
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>

                    {text?.status==3&&<Button type={'link'} href={`#/seller/shareCBD/MyJoinedCBDMerchantItemsManagement/${text?.allianceMasterId}`} disabled={text?.status===-1}>商品列表</Button>}
                </>
            ),
        }
    ];
    const column:any = status==='0'?tab0:tab1;
    const [dataSource,setDataSource]=useState<any>([]);
    const [loading,setLoading] = useState(false)
    //审核驳回
    const onAuditFinish=(e)=>{
        setNotApproveReason(e.notApproveReason);
        onAudit(e);
    };
    //审核
    const onAudit=async(e)=>{
        const params=auditStatus===3?{ approveStatus:1,notApprovedReason:notApproveReason }:{approveStatus:0};
        const res=await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/businessSellerAudit/${e.sellerId}`,params);
        if(auditStatus===3&&res.code===0){
            message.success('驳回申请!');
            initTable(1);
        }else {
            message.success('审核通过!');
            initTable(1);
        }
        setAuditVisible(false);
    };

    useEffect(()=>{
        initTable(1);
    },[]);


    //获取列表信息
    const initTable=async (e:any)=>{
        setLoading(true);
        setDataSource([]);
        if(status === '0'){
            let collaboratedRes:any = await Request.get( `${process.env.REACT_APP_REMOTE_URL}/seller/alliance/cooperationSeller/${pageNum}/${pageSize}`);//已合作商家
            if (collaboratedRes.code ===0) {
                setDataSource(collaboratedRes?.data.result);
            };
        }else {
            let res = await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/applyAllianceSeller/${pageNum}/${pageSize}`);
            if (res.code === 0) {
                setDataSource(res?.data?.result);
            }
        }
        setLoading(false);
    };

    //邀请商家二维码
    const getInviteSellerCode=async()=>{
        let res:any = await Request.get( `${process.env.REACT_APP_REMOTE_URL}/seller/alliance/getInviteSellerCode`);//已合作商家
        setQrCode(res);
    };

    return (
        <Spin spinning={loading}>
            <Button type={'primary'} style={{ margin:"20px 0" }} onClick={()=>{ setIsInvite(true);getInviteSellerCode(); }}>邀请共享商圈商家</Button>
            <Modal title="邀请商家" visible={isInvite} onCancel={()=>setIsInvite(false)} width={600} footer={null}  style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img src={qrCode} width={200} height={200} alt="共享商圈邀请码" />
                <div style={{margin:"20px"}}>请把该图片分享给合作商家</div>
            </Modal>

            <div className="businessSettingWrap">
                <Table
                    size={"middle"}
                    dataSource={ dataSource }
                    columns={ column }
                    rowKey={'id'}
                    scroll={{ y: 1500 }}
                    pagination={{
                        pageSize:pageSize,
                        showQuickJumper:true,
                        total:total,
                        onChange:(page:number)=>{ initTable(page) }
                    }} />
            </div>
        </Spin>
    )
}


const MerchantManagement=()=>{
    const [status,setStatus]=useState<string>('0');//tabs
    const onTabs=(key:any)=>{
        setStatus(key) //tabs
    };
    return (
        <div style={{background:"#fff",padding:"20px"}}>
            <h1>共享商圈管理</h1>
            <Divider />

            <Tabs type="card" defaultActiveKey={status}	onChange={(e)=>onTabs(e)} tabBarGutter={0} tabBarStyle={{"color":"#ff443"}}>
                <TabPane tab="已合作商家" key="0">
                    <BaseTabItem status={status} key={1}/>
                </TabPane>
                <TabPane tab="申请商圈商家" key="1">
                    <BaseTabItem status={status}  key={2}/>
                </TabPane>
            </Tabs>
        </div>
    );
}
export default MerchantManagement;