import {Button, Col, Descriptions, Divider, Form, message, Modal, Popconfirm, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import Request from '../../../utils/Request';

interface Props extends RouteComponentProps {}

const CBDMerchantItemsManagement =(props:any)=>{
    const [id,setId]=useState<number>(props?.match?.params?.sellerId);
    const [dataSource,setDataSource]=useState<any>([]);
    const [name,setName]=useState<any>('');
    const [phone,setPhone]=useState<any>('');
    const [address,setAddress]=useState<any>('');
    const [realName,setRealName]=useState<any>('');
    const [sellerPayOrderNo,setSellerPayOrderNo]=useState<number>(0);
    const [sellerVerifiedOrderNo,setSellerVerifiedOrderNo]=useState<number>(0);
    const [sellerVerifyOrderNo,setSellerVerifyOrderNo]=useState<number>(0);
    const [alliancePredictFee,setAlliancePredictFee]=useState<number>(0);
    const [sellerOrderTotalFee,setSellerOrderTotalFee]=useState<number>(0);

    const itemTypeMap:any = {
        0: '团购套餐',
        1: '代金券',
        2: '门店付款'
    };

    // 商品上架、下架、移除
    const onStatusChange=async (checked:any,id:any)=>{
        console.log(checked,id);
        // @ts-ignore
        let isEnabled=checked===true?1:0;
        let params={  isEnabled:isEnabled };
        let res=await Request.jsonPost( process.env.REACT_APP_REMOTE_URL+`/seller/alliance/hasJoinedSellerStatusInfo/${id}`,params);
        if(isEnabled===1&&res.code===0){
            message.success('商品已上架!')
        }else {
            message.success('商家已下架!')
        }
    };

    const columns:any= [
        {
            title: '商品ID',
            dataIndex: 'itemId' ,
            key:'itemId' ,
            align:'center'
        },
        {
            title: '商品名称',
            dataIndex: 'itemName' ,
            key:'itemName' ,
            align:'center'
        },
        {
            title: '销售价',
            key:'presentPrice' ,
            align:'center',
            render: (text,record,index) => (
                <div>{ record?.presentPrice/100 }</div>
            ),
        },
        {
            title: '支付订单数',
            dataIndex: 'itemPayOrderNO' ,
            key:'itemPayOrderNO' ,
            align:'center'
        },
        {
            title: '已核销订单数',
            dataIndex: 'itemCancelOrderNo' ,
            key:'itemCancelOrderNo' ,
            align:'center'
        },
        {
            title: '成交金额',
            key:'itemOrderTotalFee' ,
            align:'center',
            render: (text,record,index) => (
                <div>{ record?.itemOrderTotalFee/100 }</div>
            ),
        },
        {
            title: '盟主预计分红',
            key:'alliancePredictFee' ,
            align:'center',
            render: (text,record,index) => (
                <div>{ record?.alliancePredictFee/100 }</div>
            ),
        },
        {
            title: '剩余库存',
            dataIndex: 'stock' ,
            key:'stock' ,
            align:'center'
        },
        {
            title: '商品类型',
            key:'itemType' ,
            align:'center',
            render: (text,record,index) => (
                <div>{ itemTypeMap[record?.itemType*1] }</div>
            ),

        },
        {
            title: '上/下架状态',
            key: 'isEnabled',
            align:'center',
            render: (text,record,index) => (
                <>
                    <Switch defaultChecked={record.isEnabled===1} onChange={(checked,e)=>onStatusChange(checked,record.itemId)} />
                </>
            )
        },
        {
            title: '添加时间',
            dataIndex: 'createdTime' ,
            key:'createdTime' ,
            align:'center'
        },
        {
            title: '排序',
            dataIndex: 'orderNo' ,
            key:'orderNo' ,
            align:'center'
        }
    ];

    const initTable=async()=>{
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/cooperationSellerDetails/${id}`);
        setDataSource( res.data?.itemInfoList);
        setName(res?.data?.sellerName);
        setPhone(res?.data?.phone);
        setAddress(res?.data?.address);
        setRealName(res?.data?.realName);
        setSellerPayOrderNo(res?.data?.sellerPayOrderNo);
        setSellerVerifiedOrderNo(res?.data?.sellerVerifiedOrderNo);
        setSellerVerifyOrderNo(res?.data?.sellerVerifyOrderNo);
        setAlliancePredictFee(res?.data?.alliancePredictFee);
        setSellerOrderTotalFee(res?.data?.sellerOrderTotalFee);
    };
    useEffect(()=>{
        initTable();
    },[]);

    return(
        <div>
            <h1>共商商圈-商品管理 <Button type="link"><Link to="/seller/shareCBD/myCBD">返回</Link></Button></h1>
            <Divider />

            <Descriptions column={5}>
                <Descriptions.Item label="商家名称">{name}</Descriptions.Item>
                <Descriptions.Item label="负责人">{realName}({phone})</Descriptions.Item>
                <Descriptions.Item label="地址">{address}</Descriptions.Item>
            </Descriptions>

            <Descriptions  column={5}>
                <Descriptions.Item label="支付订单数">{sellerPayOrderNo}</Descriptions.Item>
                <Descriptions.Item label="已核销订单">{sellerVerifiedOrderNo}</Descriptions.Item>
                <Descriptions.Item label="待核销订单">{sellerVerifyOrderNo}</Descriptions.Item>
                <Descriptions.Item label="成交金额(元)">{sellerOrderTotalFee}</Descriptions.Item>
                <Descriptions.Item label="盟主预计分红(元)">{alliancePredictFee}</Descriptions.Item>
            </Descriptions>

            <br />

            <Table size={'middle'} rowKey={'id'} columns={columns} dataSource={dataSource} pagination={false} />
        </div>
    );
};
export default CBDMerchantItemsManagement;

