import React, { useState, useEffect, useRef } from 'react';
import {Button, Form, Input, Select, Space, Table, Tabs, Row, Col, DatePicker, Modal, Tag, Popover, Spin, Popconfirm, message, Divider} from 'antd';
import Request from "../../../utils/Request";
import address from '../../../assets/js/area.json';

// @ts-ignore
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface ITabProps{
    status?:any
}

interface IModal{
    text:string,
    children?:any
}
const ModalWrap = (props:IModal)=>{
    const [visible,setVisible] = useState(false)
    return (
        <>
            <Button type='link' onClick={()=>{ setVisible(true) }}>{props.text}</Button>
            <Modal visible={visible} onCancel={()=>{ setVisible(false) }} title={props.text} width={1000} footer={null}>
                {props.children}
            </Modal>
        </>
    )
};

const BaseTabItem = (props:ITabProps)=>{
    const { status } = props;

    const [searchForm,setSearchForm]=useState({
        province:'',
        agentName: '',
        categoryId: undefined,
        date: [],
        name: '',
        parentId: undefined,
        phone: ''
    });

    const [form] = Form.useForm();
    const [notApproveReason,setNotApproveReason]=useState<string>('');
    const [auditStatus,setAuditStatus]=useState<number>(0);
    //搜索
    const onFinish=(e)=>{
        console.log(e);
        let params={...searchForm, ...e};
        setSearchForm({...params});
    };
    const merchantStatusMap=[
        { name: '不可加入', value: -1 },
        { name: '待申请', value: 0 },
        { name: '待平台审核', value: 1 },
        { name: '待盟主审核', value: 2 },
        { name: '已加入', value: 3 },
        { name: '平台驳回审核', value: 4 },
        { name: '盟主驳回审核', value: 5 },
    ];//商户状态
    const [auditVisible,setAuditVisible]=useState<any>(false);//驳回审核
    const tab0 = [
        {
            title: '商圈名称',
            dataIndex: 'allianceName',
            key: 'allianceName',
            align:'center',
        },
        {
            title: '商家数',
            dataIndex: 'sellerCount',
            key: 'sellerCount',
            align:'center'
        },
        {
            title: '商圈累计订单数',
            dataIndex: 'allianceOrderNo',
            key: 'allianceOrderNo',
            align:'center'
        },
        {
            title: '商圈销售额(元)',
            key: 'allianceOrderTotalFee',
            align:'center',
            render: (text,record,index) => (
                <div>{ text.allianceOrderTotalFee/100 }</div>
            ),
        },
        {
            title: '盟主分红奖励(元)',
            key: 'allianceMasterBonusTotalFee',
            align:'center',
            render: (text,record,index) => (
                <div>{text?.allianceMasterBonusTotalFee}</div>
            ),
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            render: (text,record,index) => (
                <div>
                    <Button type="link" size={"small"}  href={"#/seller/shareCBD/settingMyCBDInfo"} >商圈资料修改</Button>
                    <Button type="link" size={"small"}  href={"#/seller/shareCBD/merchantManagement"} >商家管理</Button>
                </div>
            ),
        }
    ];
    const tab1=  [
        {
            title: '商圈名称',
            dataIndex: 'allianceName',
            key: 'allianceName',
            align:'center'
        },
        {
            title: '盟主商家',
            dataIndex: 'sellerName',
            key: 'sellerName',
            align:'center'
        },
        {
            title: '地址',
            key: 'region',
            dataIndex: 'address',
            align: 'center',
            width: '240px',
            render: (text, record, index) => {
                let province, city, county;
                address.map(e => {
                    if (e.value === record.province) {
                        province = e.label
                        e.children?.map(e => {
                            if (e.value === record.city) {
                                city = e.label
                                e.children?.map(e => {
                                    if (e.value === record.county) {
                                        county = e.label
                                    }
                                    return true
                                })
                            }
                            return true
                        })
                    }
                    return true
                })
                return (
                    <span>{province}{city}{county}{record.address}</span>
                )
            }
        },
        {
            title: '商圈商家总数',
            key: 'allianceSellerSum',
            dataIndex: 'allianceSellerSum',
            align:'center'
        },
        {
            title: '盟主佣金比例',
            key: 'defaultBonusRatio',
            align:'center',
            render: (text,record,index) => (
                <div>{text?.defaultBonusRatio} %</div>
            ),
        },
        {
            title: '状态',
            key: 'approveStatus',
            align:'center',
            render: (text,record,index) => (
                <>
                    {text?.approveStatus<=3&&<div>{merchantStatusMap.find(item=>item.value===text.approveStatus*1)?.name}</div>}
                    {text?.approveStatus>3&&<Popover placement="topLeft" title="驳回原因" content={text?.notApproveReason} arrowPointAtCenter>
                        <div style={{color:"#ff1e1e"}}>{merchantStatusMap.find(item=>item.value===text?.approveStatus*1)?.name}</div>
                    </Popover>}
                </>
            )
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            width: 300,
            render: (text,record,index) => (
                <>
                    {text?.approveStatus==-1&&<div>
                        <Button type={'link'} href={`#`} disabled >申请加入</Button>
                        <div style={{color:"#ff1e1e"}}>仅可加入你门店所在城市商圈</div>
                    </div>}

                    {text?.approveStatus==0&&<div>
                        <Button type={'link'} href={`#`} onClick={(record)=>{onApplication(text)}}>申请加入</Button>
                    </div>}

                    {/*{text.approveStatus==1&&text?.approveStatus==2&&<div>~</div>}*/}

                    {text.approveStatus==1&&text?.approveStatus==2&&<Popconfirm
                        title="确认通过该商家审核吗?"
                        onConfirm={(record:any)=>{onAudit(text);setAuditStatus(0)}}
                        okText="确认"
                        cancelText="取消">
                        <Button type={'link'} href="#" disabled={text?.approveStatus===-1}>审核通过</Button>
                    </Popconfirm>}

                    {text.approveStatus==1&&text?.approveStatus==2&&<Button type={'link'} size={'small'} disabled={text.approveStatus===-1} onClick={()=>{setAuditVisible(true);setAuditStatus(1)}}>驳回申请</Button>}

                    {auditVisible===true&&<Modal title='驳回申请' width={600} visible={auditVisible} onCancel={()=>{setAuditVisible(false)}} footer={null}>
                        <Form
                            name="basic"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            onFinish={(record:any)=>onAuditFinish(text)}
                            onValuesChange={(current,all)=>{
                                console.log(all)
                                if(all.notApproveReason){
                                    setNotApproveReason(all.notApproveReason)
                                }
                            }} >
                            <Form.Item label="申请驳回原因" rules={[{ required: true, message: '请输入驳回原因' }]} name="notApproveReason">
                                <TextArea placeholder="请输入驳回原因" rows={2}  />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 12, span: 8 }} style={{margin:"100px 0 40px"}}>
                                <Button type="default"  onClick={()=>{ setAuditVisible(false) }} >
                                    取消
                                </Button>
                                <Button type="primary" htmlType="submit" style={{margin:"20px"}}>
                                    保存
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>}

                    {text?.approveStatus==3&&<Button type={'link'} href={`#/seller/shareCBD/MyJoinedCBDMerchantItemsManagement/${text?.allianceMasterId}`} disabled={text?.approveStatus===-1}>商品列表</Button>}

                    {text?.approveStatus==4||text?.approveStatus==5&&<div>
                        <Button type={'link'} href={`#`} onClick={(record)=>{onRepeatAudit(text)}}>重新申请</Button>
                    </div>}
                </>
            ),
        }
    ];

    //审核驳回
    const onAuditFinish=(e)=>{
        setNotApproveReason(e.notApproveReason);
        onAudit(e);
    };
    //审核
    const onAudit=async(e)=>{
        const params=auditStatus===3?{ approveStatus:1,notApprovedReason:notApproveReason }:{approveStatus:0};//0通过 1驳回
        const res=await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/businessSellerAudit/${e.allianceMasterId}`,params);
        if(auditStatus===3&&res.code===0){
            message.success('驳回申请!')
            setAuditVisible(false)
            initTable(1);
        }else {
            message.success('审核通过!')
            initTable(1)
        }
    };
    //申请加入
    const onApplication=async(e)=>{
        const params=auditStatus===3?{ approveStatus:1,notApprovedReason:notApproveReason }:{approveStatus:0};//0通过 1驳回
        const res=await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/applyJoinAlliance/${e.allianceMasterId}`,params);
        if(auditStatus===3&&res.code===0){
            message.success('驳回申请!')
            setAuditVisible(false)
            initTable(1);
        }else {
            message.success('审核通过!')
            initTable(1)
        }
    };
    //重新审核
    const onRepeatAudit=async(e)=>{
        const params=auditStatus===3?{ approveStatus:1,notApprovedReason:notApproveReason }:{approveStatus:0};//0通过 1驳回
        const res=await Request.get(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/repeatApplyJoinAlliance/${e.allianceMasterId}`,params);
        if(auditStatus===3&&res.code===0){
            message.success('驳回申请!')
            setAuditVisible(false)
            initTable(1);
        }else {
            message.success('审核通过!')
            initTable(1)
        }
    };

    useEffect(()=>{
        initTable(1);
    },[]);

    const column:any = status==='0'?tab0:tab1;
    const [dataSource,setDataSource]=useState<any>([]);
    const [loading,setLoading] = useState(false);

    //获取列表信息
    const initTable=async (e:number,formInput?:any)=>{
        setLoading(true);
        setDataSource([]);
        if(status === '0'){
            let createdRes = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/alliance/getMyAllianceInfo");//我创建的商圈
            if (createdRes.code == 0) {
                setDataSource(createdRes?.data?.result);
            }
        }else {
            let res = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + `/seller/alliance/myJoinAlliance`, {
                province: formInput?.province,
                city:formInput?.city
            });
            if (res.code == 0) {
                setDataSource(res.data?.result);
            }
        }
        setLoading(false);
    };

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={(value)=>onFinish(value)}
                autoComplete="on"
                style={{padding:"16px 0"}}>
                {status==='1'&&<Row gutter={24}>
                    <Col span={4} >
                        <Form.Item label="所在地区" name="province">
                            <Select allowClear placeholder='请选择所在地区'>
                                {address.map(e => {
                                    return (
                                        <Option key={e.value} value={e.value}>{e.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={1} >
                        <Button onClick={() => { form.resetFields();}}>重置</Button>
                    </Col>
                    <Col span={1} >
                        <Button type="primary" htmlType="submit">搜索</Button>
                    </Col>
                </Row>}
            </Form>
            <div className="businessSettingWrap">
                <Table
                    size={"middle"}
                    dataSource={ dataSource }
                    columns={ column }
                    rowKey={'id'}
                    scroll={{ y: 1500 }}
                    pagination={false} />
            </div>
        </Spin>
    )
}

const MyCBD=()=>{
    const [status,setStatus]=useState<string>('0');//tabs
    const onTabs=(key:any)=>{
        setStatus(key) //tabs
    };
    return (
        <div style={{background:"#fff",padding:"20px"}}>
            <h1>我的共享商圈</h1>
            <Divider />

            <Tabs type="card" defaultActiveKey={status}	onChange={(e)=>onTabs(e)} tabBarGutter={0} tabBarStyle={{"color":"#ff443"}}>
                <TabPane tab="我创建的商圈" key="0">
                    <BaseTabItem status={status} key={1}/>
                </TabPane>
                <TabPane tab="我加入的商圈" key="1">
                    <BaseTabItem status={status}  key={2}/>
                </TabPane>
            </Tabs>
        </div>
    );
}
export default MyCBD;


