import React from "react";

import {Divider, Form, Input, Space, Button, Modal, Upload, Select, Radio, message} from "antd";
import settings from "../../../settings";
import Request from '../../../utils/Request';
import {LoadingOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

interface page {
    data: any,
    id: number,
    roleName: string,
    cardModuleId: number,
    bunusRatio: number,
    imgList: any,
    cardList: any,

}

export default class RetailDetail extends React.Component<{ match: { params: any }, history: any }, page> {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            id: null,
            roleName: null,
            cardModuleId: null,
            bunusRatio: null,
            imgList: [],
            cardList: [],
        };
    }

    componentDidMount() {
        this.getCardList();
        this.fetchSettingData();
    }


    async fetchSettingData() {
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/distribution/getDistributionIdentityDetail?identityConfigId=" + this.props.match.params.configId);
        // if (res.code !== 0) {
        //     Modal.error({content: "获取分销身份详情异常"});
        //     return;
        // }

        let tempData = res.data;

        this.setState({
            data: tempData,
            id: tempData.id,
            roleName: tempData.roleName,
            imgList: [{
                url: tempData.prefix + tempData.rolePictPath
            }],
            cardModuleId: tempData.cardModuleId,
            bunusRatio: tempData.bonusRatio,
        });
    }

    async getCardList() {
        let res = await Request.get(process.env.REACT_APP_REMOTE_URL + "/seller/equity/listEquityCardModules");
        // if (res.code !== 0) {
        //     Modal.error({content: "获取折扣卡信息异常"});
        //     return;
        // }

        let tempArr = [{
            label: '无',
            value: -1,
            prefix: null,
        }];

        res.data.forEach(t => {
            tempArr.push({
                label: `${t.cardModuleName}（${t.discountRatio % 10 === 0 ? t.discountRatio / 10 : t.discountRatio}）折`,
                value: t.id,
                prefix: t.prefix,
            });
        });

        this.setState({cardList: tempArr});
    }

    submit = async (values) => {
        let tempImgList = this.state?.imgList;
        let imgSrc = '';
        if(tempImgList && tempImgList.length === 1){
            imgSrc = tempImgList[0]?.response?.data;
        }

        let data = {
            id: this.state.data.id,
            rolePictPath: imgSrc,
            distributerType:1
        };

        Object.assign(data, values);

        let result = await Request.jsonPost(process.env.REACT_APP_REMOTE_URL + "/seller/equity/distribution/updateDistributionIdentity", data);

        if (result.code === 0) {
            Modal.info({
                content: result.data, onOk: () => {
                    this.props.history.push('/seller/retail/list');
                }
            });
        }
        // else {
        //     if (!result.success) {
        //         Modal.error({content: result.message});
        //         return;
        //     }
        // }
    };

    onChange = (e) => {

    };

    onUploadChange = ({fileList}) => {
        this.setState({imgList: fileList});
    };

    deleteExistingPic = () => {
        this.setState({imgList: []});
    };








    render() {
        let smallWidth = {style: {width: '150px'}};
        let styleDesc = {style: {paddingLeft: '10px', color: '#AAAAAA'}};

        let data = this.state.data;

        if (Object.keys(data).length === 0) {
            return <div><LoadingOutlined/></div>;
        }

        return <div>
            <h1>编辑身份</h1>
            <Divider/>
            <Form {...settings.FormLayout} initialValues={data} onFinish={this.submit}>
                <Form.Item label="身份名称" name="roleName">
                    <Input {...smallWidth}/>
                </Form.Item>
                <Form.Item label="身份图标" name="roleName">
                    <Upload
                        headers={{Authorization: sessionStorage.getItem('token')}} data={{fullAddress: 'false'}}
                        action={process.env.REACT_APP_REMOTE_URL + '/seller/equity/distribution/uploadIdentityPic'}
                        listType="picture-card"
                        fileList={this.state.imgList}
                        onChange={this.onUploadChange}
                        onRemove={this.deleteExistingPic}
                    >
                        {this.state.imgList.length < 1 && '+ Upload'}
                    </Upload>
                    < div className="tip">图片尺寸：128*128</div>
                </Form.Item>
                <Form.Item label="关联折扣卡" name="cardModuleId">
                    <Select options={this.state.cardList} {...smallWidth}/>
                </Form.Item>
                <Form.Item label="分销奖励配置">
                    <Space>
                        <Form.Item>
                            <span {...styleDesc}>实付金额</span>
                        </Form.Item>
                        <Form.Item name="bonusRatio">
                            <Input {...smallWidth}/>
                        </Form.Item>
                        <Form.Item>
                            <span {...styleDesc}>%</span>
                        </Form.Item>
                    </Space>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 4}}>
                    <br/>
                    <Space>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Form.Item>
                        <Form.Item>
                            <Link to="/seller/retail/list"><Button>取消</Button></Link>
                        </Form.Item>
                    </Space>
                </Form.Item>
            </Form>



        </div>;
    }
}
